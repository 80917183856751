import {
    SANCTION_GET_LIST,
    SANCTION_GET_LIST_SUCCESS,
    SANCTION_GET_LIST_ERROR,
    
    SANCTION_GET_ONE,
    SANCTION_GET_ONE_SUCCESS,
    SANCTION_GET_ONE_ERROR,
    
    SANCTIONS_GET_BY_FILTER,
    SANCTIONS_GET_BY_FILTER_SUCCESS,
    SANCTIONS_GET_BY_FILTER_ERROR,

    SANCTION_ADD_ONE,
    SANCTION_ADD_ONE_SUCCESS,
    SANCTION_ADD_ONE_ERROR,

    SANCTION_EDIT_ONE,
    SANCTION_EDIT_ONE_SUCCESS,
    SANCTION_EDIT_ONE_ERROR,
 
    SANCTION_DELETE_ONE,
    SANCTION_DELETE_ONE_SUCCESS,
    SANCTION_DELETE_ONE_ERROR,



} from '../actions';

import { NotificationManager } from '../../components/common/react-notifications';
import  notificationMessages  from '../../lang/locales/notificationMsg';

const INIT_STATE = {

  count: 0,
  sanctions:[],
  error: '',
  sanction:null,
};


export default (state = INIT_STATE, action) => {
  const messages = notificationMessages()
	switch (action.type) {

    case SANCTION_GET_LIST:
        return{...state,loading:true, error: '', 
     count: 0
    };
    case SANCTION_GET_LIST_SUCCESS:
       return {...state,loading: false,
        sanctions: action.payload.sanctions || [],
        count: action.payload.count || 0
    };
    case SANCTION_GET_LIST_ERROR:
       return{...state,loading:false,error: action.payload.message
    };


    //GET ONE
    case SANCTION_GET_ONE:
        return{...state,loading:true, error: '', 
     count: 0
    };
    case SANCTION_GET_ONE_SUCCESS:
        return {...state,loading: false,
        sanction: action.payload.sanction,
       
    };
    case SANCTION_GET_ONE_ERROR:
       return{...state,loading:false,error: action.payload.message
    };

    


     // GET BY FILTER
    case SANCTIONS_GET_BY_FILTER:
            
    return { ...state, loading: true, error: ''};
    case SANCTIONS_GET_BY_FILTER_SUCCESS:
        
    return { ...state, loading: false, error: '',
    sanctions: action.payload.sanctions || [],
    count: action.payload.count || 0
      };
    case SANCTIONS_GET_BY_FILTER_ERROR:
        
    return { ...state, loading: false, error: action.payload.message };

    //ADD ONE

    case SANCTION_ADD_ONE:
       return { ...state, loading: true, error: ''};
    case SANCTION_ADD_ONE_SUCCESS:    
      NotificationManager.success(messages["Added"], messages["Success"],3000, null, null, '');
       return { ...state, loading: false, 
        sanctions: [action.payload.sanction, ...state.sanctions]
    };
    case SANCTION_ADD_ONE_ERROR:
      NotificationManager.warning(action.payload.message ||  messages["Unkown Error"], messages["Error"], 3000, null, null, '');
    return { ...state, loading: false, error: action.payload.message };


     // EDIT ONE
    case SANCTION_EDIT_ONE:
       return { ...state, loading: true,edit_sucess:false, error: ''};
    case SANCTION_EDIT_ONE_SUCCESS:    
    NotificationManager.success(messages["Edited"], messages["Success"], 3000, null, null, '');
     return { ...state, loading: false, edit_sucess:true,error: '',
     sanction: action.payload.sanction
     };
    case SANCTION_EDIT_ONE_ERROR:
      NotificationManager.warning(action.payload.message ||  messages["Unkown Error"], messages["Error"], 3000, null, null, '');
     return { ...state, loading: false,edit_sucess:false, error: action.payload.message };



    
      // DELETE ONE
    case SANCTION_DELETE_ONE:
      return { ...state, loading: true, error: ''};
    case SANCTION_DELETE_ONE_SUCCESS:  
      const sanctions = state.sanctions 
      if(action.payload.index!=null && action.payload.index!=undefined)
      sanctions.splice(action.payload.index, 1)

      return { ...state, loading: false, error: '',
        sanctions
      };
    case SANCTION_DELETE_ONE_ERROR:
      NotificationManager.warning(action.payload.message ||  messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };

    
		default: return { ...state };













    }





}