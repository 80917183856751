import {
    EXAMS_GET_LIST,
    EXAMS_GET_LIST_SUCCESS,
    EXAMS_GET_LIST_ERROR,

    EXAM_GET_ONE,
    EXAM_GET_ONE_SUCCESS,
    EXAM_GET_ONE_ERROR,

    EXAMS_GET_BY_FILTER,
    EXAMS_GET_BY_FILTER_SUCCESS,
    EXAMS_GET_BY_FILTER_ERROR,

    EXAM_ADD_ONE,
    EXAM_ADD_ONE_SUCCESS,
    EXAM_ADD_ONE_ERROR,

    EXAM_EDIT_ONE,
    EXAM_EDIT_ONE_SUCCESS,
    EXAM_EDIT_ONE_ERROR,

    EXAM_DELETE_ONE,
    EXAM_DELETE_ONE_SUCCESS,
    EXAM_DELETE_ONE_ERROR,

    EXAMS_DELETE_MANY,
    EXAMS_DELETE_MANY_SUCCESS,
    EXAMS_DELETE_MANY_ERROR,
  } from '../actions';
// GET LIST
export const getExamsList = (payload) => ({
    type: EXAMS_GET_LIST,
    payload
  });
export const getExamsListSuccess = (payload) => ({
    type: EXAMS_GET_LIST_SUCCESS,
    payload
  });
export const getExamsListError = (payload) => ({
    type: EXAMS_GET_LIST_ERROR,
    payload
  });


 // GET One
 export const getOneExam = (payload) => ({
    type: EXAM_GET_ONE,
    payload
  });
export const getOneExamSuccess = (payload) => ({
    type: EXAM_GET_ONE_SUCCESS,
    payload
  });
export const getOneExamError = (payload) => ({
    type: EXAM_GET_ONE_ERROR,
    payload
  });


// GET EXAMS BY FILTER

export const getExamsByFilter = (payload) => ({
    type: EXAMS_GET_BY_FILTER,
    payload
  });
  export const getExamsByFilterSuccess = (payload) => ({
    type: EXAMS_GET_BY_FILTER_SUCCESS,
    payload
  });
  export const getExamsByFilterError = (payload) => ({
    type: EXAMS_GET_BY_FILTER_ERROR,
    payload
  });

 // ADD ONE
 export const addExam = (payload) => ({
    type: EXAM_ADD_ONE,
    payload
  });
export const addExamSuccess = (payload) => ({
    type: EXAM_ADD_ONE_SUCCESS,
    payload
  });
export const addExamError = (payload) => ({
    type: EXAM_ADD_ONE_ERROR,
    payload
  });

  // EDIT ONE
  export const editOneExam = (payload) => ({
    type: EXAM_EDIT_ONE,
    payload
  });
export const editOneExamSuccess = (payload) => ({
    type: EXAM_EDIT_ONE_SUCCESS,
    payload
  });
export const editOneExamError = (payload) => ({
    type: EXAM_EDIT_ONE_ERROR,
    payload
  });
  

  // DELETE ONE
export const deleteOneExam = (payload) => ({
    type: EXAM_DELETE_ONE,
    payload
  });
export const deleteOneExamSuccess = (payload) => ({
    type: EXAM_DELETE_ONE_SUCCESS,
    payload
  });
export const deleteOneExamError = (payload) => ({
    type: EXAM_DELETE_ONE_ERROR,
    payload
  });



// DELETE MANY
export const deleteManyExams = (payload) => ({
  type: EXAMS_DELETE_MANY,
  payload
});
export const deleteManyExamsSuccess = (payload) => ({
  type: EXAMS_DELETE_MANY_SUCCESS,
  payload
});
export const deleteManyExamsError = (payload) => ({
  type: EXAMS_DELETE_MANY_ERROR,
  payload
});