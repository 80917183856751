import {
	CLASS_GET_LIST,
	CLASS_GET_LIST_SUCCESS,
  CLASS_GET_LIST_ERROR,

  CLASS_GET_ONE,
	CLASS_GET_ONE_SUCCESS,
  CLASS_GET_ONE_ERROR,
  
  CLASS_GET_BY_FILTER,
  CLASS_GET_BY_FILTER_SUCCESS,
  CLASS_GET_BY_FILTER_ERROR,

  CLASS_ADD_ONE,
  CLASS_ADD_ONE_SUCCESS,
  CLASS_ADD_ONE_ERROR,

  CLASS_EDIT_ONE,
  CLASS_EDIT_ONE_SUCCESS,
  CLASS_EDIT_ONE_ERROR,

  CLASS_DELETE_ONE,
  CLASS_DELETE_ONE_SUCCESS,
  CLASS_DELETE_ONE_ERROR,

  CLASS_GET_BY_TEACHER,

  CLASSES_DELETE_MANY,
  CLASSES_DELETE_MANY_SUCCESS,
  CLASSES_DELETE_MANY_ERROR
} from '../actions';

import { NotificationManager } from '../../components/common/react-notifications';
import  notificationMessages  from '../../lang/locales/notificationMsg'

const INIT_STATE = {
	classes: [],
  count: 0,
  class: null,
  error: '',
  loading: false
};

export default (state = INIT_STATE, action) => {
  const messages = notificationMessages()
	switch (action.type) {
		case CLASS_GET_LIST:
    case CLASS_GET_BY_TEACHER:
      return { ...state, loading: true, error: '', count: 0 };
    case CLASS_GET_LIST_SUCCESS:
      return { ...state, loading: false, 
      classes: action.payload.classes || [],
      count: action.payload.count || 0
      };
		case CLASS_GET_LIST_ERROR:
      return { ...state, loading: false, error: action.payload.message };



      // GET ONE
    case CLASS_GET_ONE:
      return { ...state, loading: true, error: '', class: null};
    case CLASS_GET_ONE_SUCCESS:
      return { ...state, loading: false, error: '',
        class: action.payload.class
      };
    case CLASS_GET_ONE_ERROR:
      return { ...state, loading: false, error: action.payload.message };



     // GET BY FILTER
     case CLASS_GET_BY_FILTER:
      //console.log("classes : ",state.classes)
      return { ...state, loading: true, error: ''};
    case CLASS_GET_BY_FILTER_SUCCESS:
       
      return { ...state, loading: false, error: '',
      classes: action.payload.classes || [],
      count: action.payload.count || 0
      };
    case CLASS_GET_BY_FILTER_ERROR:
       
      return { ...state, loading: false, error: action.payload.message };





      // ADD ONE
    case CLASS_ADD_ONE:
      return { ...state, loading: true, error: ''};
    case CLASS_ADD_ONE_SUCCESS:    
      NotificationManager.success(messages["Added"], messages["Success"],3000, null, null, '');
      return { ...state, loading: false, 
        classes: [action.payload.class, ...state.classes]
      };
    case CLASS_ADD_ONE_ERROR:
      NotificationManager.warning(action.payload.message ||  messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };





    
      // EDIT ONE
    case CLASS_EDIT_ONE:
      return { ...state, loading: true, error: ''};
    case CLASS_EDIT_ONE_SUCCESS:    
      NotificationManager.success(messages["Edited"], messages["Success"], 3000, null, null, '');
      return { ...state, loading: false, error: '',
      class: action.payload.class
      };
    case CLASS_EDIT_ONE_ERROR:
      NotificationManager.warning(action.payload.message ||  messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };

    




      // DELETE ONE
    case CLASS_DELETE_ONE:
      return { ...state, loading: true, error: ''};
    case CLASS_DELETE_ONE_SUCCESS:  
      const classes = state.classes 
      if(action.payload.index!=null && action.payload.index!=undefined)
      classes.splice(action.payload.index, 1)

      return { ...state, loading: false, error: '',
        classes
      };
    case CLASS_DELETE_ONE_ERROR:
      NotificationManager.warning(action.payload.message ||  messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };

    

      // DELETE MANY
        case CLASSES_DELETE_MANY:
          return { ...state, loading: true, error: '' };
        case CLASSES_DELETE_MANY_SUCCESS:

          const classes_list = state.classes
         
    
          if (action.payload.data.ids.length > 0) {
       
            for (let i = 0; i < action.payload.data.ids.length; i++) {
    
              let index_class = -1
              for (let j = 0; j < classes_list.length; j++) {
                if (classes_list[j]._id === action.payload.data.ids[i]) {
                  index_class = j; break;
                }
              }
              if (index_class > -1) classes_list.splice(index_class, 1)
            }
          }
          return {
            ...state, loading: false, error: '',
            classes: classes_list
          };
        case CLASSES_DELETE_MANY_ERROR:
          NotificationManager.warning(action.payload.message || messages["Unkown Error"], messages["Error"], 3000, null, null, '');
          return { ...state, loading: false, error: action.payload.message };

		default: return { ...state };
	}
}
