import {
  FEE_GET_LIST,
	FEE_GET_LIST_SUCCESS,
  FEE_GET_LIST_ERROR,

  FEE_GET_LIST_BY_FILTER,
  FEE_GET_LIST_BY_FILTER_SUCCESS,
  FEE_GET_LIST_BY_FILTER_ERROR,

  FEE_GET_ONE,
	FEE_GET_ONE_SUCCESS,
  FEE_GET_ONE_ERROR,


  FEE_ADD_ONE,
  FEE_ADD_ONE_SUCCESS,
  FEE_ADD_ONE_ERROR,

  FEE_EDIT_ONE,
  FEE_EDIT_ONE_SUCCESS,
  FEE_EDIT_ONE_ERROR,

  FEE_DELETE_ONE,
  FEE_DELETE_ONE_SUCCESS,
  FEE_DELETE_ONE_ERROR,

  FEES_DELETE_MANY,
  FEES_DELETE_MANY_SUCCESS,
  FEES_DELETE_MANY_ERROR,
} from '../../actions';

  // GET LIST
export const getFeesList = (payload) => ({
  type: FEE_GET_LIST,
  payload
});
export const getFeesListSuccess = (payload) => ({
  type: FEE_GET_LIST_SUCCESS,
  payload
});
export const getFeesListError = (payload) => ({
  type: FEE_GET_LIST_ERROR,
  payload
});


// GET bY Filter
export const getFeesByFilter = (payload) => ({
  type: FEE_GET_LIST_BY_FILTER,
  payload
});
export const getFeesByFilterSuccess = (payload) => ({
  type: FEE_GET_LIST_BY_FILTER_SUCCESS,
  payload
});
export const getFeesByFilterError = (payload) => ({
  type: FEE_GET_LIST_BY_FILTER_ERROR,
  payload
});


  // GET ONE
export const getOneFee = (payload) => ({
  type: FEE_GET_ONE,
  payload
});
export const getOneFeeSuccess = (payload) => ({
  type: FEE_GET_ONE_SUCCESS,
  payload
});
export const getOneFeeError = (payload) => ({
  type: FEE_GET_ONE_ERROR,
  payload
});



  // ADD ONE
export const addFee = (payload) => ({
  type: FEE_ADD_ONE,
  payload
});
export const addFeeSuccess = (payload) => ({
  type: FEE_ADD_ONE_SUCCESS,
  payload
});
export const addFeeError = (payload) => ({
  type: FEE_ADD_ONE_ERROR,
  payload
});




  // EDIT ONE
export const editFee = (payload) => ({
  type: FEE_EDIT_ONE,
  payload
});
export const editFeeSuccess = (payload) => ({
  type: FEE_EDIT_ONE_SUCCESS,
  payload
});
export const editFeeError = (payload) => ({
  type: FEE_EDIT_ONE_ERROR,
  payload
});




  // DELETE ONE
export const deleteOneFee = (payload) => ({
  type: FEE_DELETE_ONE,
  payload
});
export const deleteOneFeeSuccess = (payload) => ({
  type: FEE_DELETE_ONE_SUCCESS,
  payload
});
export const deleteOneFeeError = (payload) => ({
  type: FEE_DELETE_ONE_ERROR,
  payload
});


// DELETE MANY
export const deleteManyFees = (payload) => ({
  type:   FEES_DELETE_MANY,
  payload
});
export const deleteManyFeesSuccess = (payload) => ({
  type:FEES_DELETE_MANY_SUCCESS,
  payload
});
export const deleteManyFeesError = (payload) => ({
  type: FEES_DELETE_MANY_ERROR,
  payload
});