import { all, call, fork, put, takeEvery } from "redux-saga/effects";
// import { getDateWithFormat } from "../../helpers/Utils";
import { api } from "../../constants/defaultValues";
import axios from "axios";

import { ROOM_GET_LIST, ROOM_ADD_ONE, ROOM_GET_ONE, ROOM_EDIT_ONE, ROOM_DELETE_ONE,ROOMS_DELETE_MANY
} from "../actions";

import {
  getRoomsListSuccess,
  getRoomsListError,

  getOneRoomSuccess,
  getOneRoomError,

  addRoomSuccess,
  addRoomError,

  editRoomSuccess,
  editRoomError,
  deleteOneRoomSuccess,
  deleteOneRoomError,

  deleteManyRoomsSuccess,
  deleteManyRoomsError
} from "./actions";


  //GET LIST
const getRoomsListRequest = async (payload) => {
  return await axios.get(api.rooms+`/list/${payload.inst_id}/${payload.offset}/${payload.limit}`, { headers: {Authorization: payload.token} })
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* getRoomsList({payload}) {
  try {
    const ret = yield call(getRoomsListRequest, payload);
    
    if (ret && ret.status==200)
    yield put(getRoomsListSuccess({rooms: ret.data.rooms || [], count: ret.data.count || 0}));
    
    else yield put(getRoomsListError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
    yield put(getRoomsListError({error, message: (error) ? error.message : ''}));
  }
}



//GET ONE
const getOneRoomsRequest = async (payload) => {
  return await axios.get(api.rooms+`/get-one/${payload._id}`, { headers: {Authorization: payload.token} })
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* getOneRoom({payload}) {  
  try {
    const ret = yield call(getOneRoomsRequest, payload);

    if (ret && ret.status==200)
    yield put(getOneRoomSuccess({room: ret.data.room}));
    
    else yield put(getOneRoomError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
    yield put(getOneRoomError({error, message: (error) ? error.message : ''}));
  }
}




  //ADD ONE
const addRoomRequest = async (payload) => {
  return await axios.post(api.rooms, payload.data, { headers: {Authorization: payload.token} })
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* addRoom({payload}) {  
  try {
    const ret = yield call(addRoomRequest, payload);
    
    if (ret && ret.status==201){
      const { history } = payload;

      if(history) history.push('/app/my-institutions/rooms')
      yield put(addRoomSuccess({room: ret.data.room}));
    }
    else yield put(addRoomError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
    console.log('saga err', error);
    yield put(addRoomError({error, message: (error) ? error.message : ''}));
  }
}





  //EDIT ONE
const editOneRoomRequest = async (payload) => {
  return await axios.patch(`${api.rooms}/edit-one/${payload._id}`, payload.data, { headers: {Authorization: payload.token} })
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* editOneRoom({payload}) {  
  try {
    const ret = yield call(editOneRoomRequest, payload);
    
    if (ret && ret.status==200){
      const { history } = payload;

      if(history) history.push('/app/my-institutions/rooms')
      yield put(editRoomSuccess({room: ret.data.room}));
    }
    else yield put(editRoomError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
    console.log('saga err', error);
    yield put(editRoomError({error, message: (error) ? error.message : ''}));
  }
}


  // DELETE ONE
const deleteOneRoomRequest = async (payload) => {
  return await axios.delete(`${api.rooms}/delete-one/${payload._id}`, { headers: {Authorization: payload.token} })
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* deleteOneRoom({payload}) {  
  try {
    const ret = yield call(deleteOneRoomRequest, payload);
    
    if (ret && ret.status==202){
      const { history, index } = payload;

      if(history) history.push('/app/my-institutions/rooms')
      yield put(deleteOneRoomSuccess({index}));
    }
    else yield put(deleteOneRoomError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
    console.log('saga err', error);
    yield put(deleteOneRoomError({error, message: (error) ? error.message : ''}));
  }
}

//DELETE MANY

const deleteManyRoomsRequest = async (payload) => {
  return await axios.delete(`${api.rooms}/delete-many`, { headers: { Authorization: payload.token }, data: payload.data })
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* deleteManyRooms({ payload }) {
  try {
    const ret = yield call(deleteManyRoomsRequest, payload);

    if (ret && ret.status == 202) {
      const { history, data } = payload;

      if (history) history.push('/app/my-institutions/rooms')
      yield put(deleteManyRoomsSuccess({ data }));
    }
    else yield put(deleteManyRoomsError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    console.log('saga err', error);
    yield put(deleteManyRoomsError({ error, message: (error) ? error.message : '' }));
  }
}
//////// WATCHERS
export function* watchGetList() {
  yield takeEvery(ROOM_GET_LIST, getRoomsList);
}

export function* watchGetOne() {
  yield takeEvery(ROOM_GET_ONE, getOneRoom);
}

export function* watchEditOne() {
  yield takeEvery(ROOM_EDIT_ONE, editOneRoom);
}

export function* watchAddOne() {
  yield takeEvery(ROOM_ADD_ONE, addRoom);
}

export function* watchDeleteOne() {
  yield takeEvery(ROOM_DELETE_ONE, deleteOneRoom);
}
export function* watchDeleteMany() {
  yield takeEvery(ROOMS_DELETE_MANY,deleteManyRooms);
}
export default function* rootSaga() {
  yield all([
    fork(watchGetList),
    fork(watchGetOne),
    fork(watchEditOne),
    fork(watchAddOne),
    fork(watchDeleteOne),
    fork(watchDeleteMany)

  ]);
}
