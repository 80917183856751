import { all, call, fork, put, takeEvery, takeLatest } from "redux-saga/effects";
// import { getDateWithFormat } from "../../helpers/Utils";
import { api } from "../../constants/defaultValues";
import axios from "axios";

import { PARENT_GET_LIST, PARENT_ADD_ONE, PARENT_GET_ONE, PARENT_EDIT_ONE, PARENT_DELETE_ONE , PARENT_GET_LIST_BY_FILTER,
  PARENTS_DELETE_MANY
} from "../actions";

import {
  getParentsListSuccess,
  getParentsListError,

  getParentsByFilterSuccess,
  getParentsByFilterError,

  getOneParentSuccess,
  getOneParentError,

  addParentSuccess,
  addParentError,

  editParentSuccess,
  editParentError,
  deleteOneParentSuccess,
  deleteOneParentError,


  deleteManyParentsSuccess,
  deleteManyParentsError
} from "./actions";


  //GET LIST
const getParentsListRequest = async (payload) => {
  return await axios.get(api.parents+`/list/${payload.inst_id}/${payload.offset}/${payload.limit}`, { headers: {Authorization: payload.token} })
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* getParentsList({payload}) {
  try {
    const ret = yield call(getParentsListRequest, payload);
    
    if (ret && ret.status==200)
    yield put(getParentsListSuccess({parents: ret.data.parents || [], count: ret.data.count || 0}));
    
    else yield put(getParentsListError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
    yield put(getParentsListError({error, message: (error) ? error.message : ''}));
  }
}


//GET LIST BY FILTER
 const getParentsListByFilterRequest = async (payload) => {
  //console.log("getParentsListByFilterRequest => ",payload); 
  return await axios.post(api.parents+`/filters/${payload.inst_id}/${payload.offset}/${payload.limit}`, payload.filter, { headers: {Authorization: payload.token} })
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* getParentsListByFilter({payload}) {
  try {
    const ret = yield call(getParentsListByFilterRequest, payload);

    
    if (ret && ret.status==200){
      yield put(getParentsByFilterSuccess({parents: ret.data.parents || [], count: ret.data.count || 0}));
    } 
    
    else yield put(getParentsByFilterError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
    console.log('errr',error);
    
    yield put(getParentsByFilterError({error, message: (error) ? error.message : ''}));
  }
}

//GET ONE
const getOneParentsRequest = async (payload) => {
  return await axios.get(api.parents+`/get-one/${payload._id}`, { headers: {Authorization: payload.token} })
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* getOneParent({payload}) {  
  try {
    const ret = yield call(getOneParentsRequest, payload);

    if (ret && ret.status==200)
    yield put(getOneParentSuccess({parent: ret.data.parent}));
    
    else yield put(getOneParentError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
    yield put(getOneParentError({error, message: (error) ? error.message : ''}));
  }
}




  //ADD ONE
const addParentRequest = async (payload) => {
  return await axios.post(api.parents, payload.data, { headers: {Authorization: payload.token} })
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* addParent({payload}) {  
  try {
    const ret = yield call(addParentRequest, payload);
    
    if (ret && ret.status==201){
      const { history } = payload;

      if(history) history.push('/app/schooling/parents')
      yield put(addParentSuccess({parent: ret.data.parent}));
    }
    else yield put(addParentError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
    console.log('saga err', error);
    yield put(addParentError({error, message: (error) ? error.message : ''}));
  }
}





  //EDIT ONE
const editOneParentRequest = async (payload) => {
  return await axios.patch(`${api.parents}/edit-one/${payload._id}`, payload.data, { headers: {Authorization: payload.token} })
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* editOneParent({payload}) {  
  try {
    const ret = yield call(editOneParentRequest, payload);
    
    if (ret && ret.status==200){
      const { history } = payload;

      if(history) history.push('/app/schooling/parents')
      yield put(editParentSuccess({parent: ret.data.parent}));
    }
    else yield put(editParentError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
    console.log('saga err', error);
    yield put(editParentError({error, message: (error) ? error.message : ''}));
  }
}


  // DELETE ONE
const deleteOneParentRequest = async (payload) => {
  return await axios.delete(`${api.parents}/delete-one/${payload._id}`, { headers: {Authorization: payload.token} })
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* deleteOneParent({payload}) {  
  try {
    const ret = yield call(deleteOneParentRequest, payload);
    
    if (ret && ret.status==202){
      const { history, index } = payload;

      if(history) history.push('/app/schooling/parents')
      yield put(deleteOneParentSuccess({index}));
    }
    else yield put(deleteOneParentError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
    console.log('saga err', error);
    yield put(deleteOneParentError({error, message: (error) ? error.message : ''}));
  }
}

const deleteManyParentsRequest = async (payload) => {
  return await axios.delete(`${api.parents}/delete-many`, { headers: { Authorization: payload.token }, data: payload.data })
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* deleteManyParents({ payload }) {
  try {
    const ret = yield call(deleteManyParentsRequest, payload);

    if (ret && ret.status == 202) {
      const { history, data } = payload;

      if (history) history.push('/app/schooling/parents')
      yield put(deleteManyParentsSuccess({ data }));
    }
    else yield put(deleteManyParentsError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    console.log('saga err', error);
    yield put(deleteManyParentsError({ error, message: (error) ? error.message : '' }));
  }
}




//////// WATCHERS
export function* watchGetList() {
  yield takeEvery(PARENT_GET_LIST, getParentsList);
}

export function* watchGetListByFilter() {
  yield takeLatest(PARENT_GET_LIST_BY_FILTER, getParentsListByFilter);
}

export function* watchGetOne() {
  yield takeEvery(PARENT_GET_ONE, getOneParent);
}

export function* watchEditOne() {
  yield takeEvery(PARENT_EDIT_ONE, editOneParent);
}

export function* watchAddOne() {
  yield takeEvery(PARENT_ADD_ONE, addParent);
}

export function* watchDeleteOne() {
  yield takeEvery(PARENT_DELETE_ONE, deleteOneParent);
}
export function* watchDeleteMany() {
  yield takeEvery(PARENTS_DELETE_MANY,deleteManyParents);
}
export default function* rootSaga() {
  yield all([
    fork(watchGetList),
    fork(watchGetListByFilter),
    fork(watchGetOne),
    fork(watchEditOne),
    fork(watchAddOne),
    fork(watchDeleteOne),
    fork(watchDeleteMany)
  ]);
}
