import {
    CONTACT_MSG_GET_LIST,
    CONTACT_MSG_GET_LIST_SUCCESS,
    CONTACT_MSG_GET_LIST_ERROR,

    CONTACT_MSG_GET_LIST_BY_FILTER,
    CONTACT_MSG_GET_LIST_BY_FILTER_SUCCESS,
    CONTACT_MSG_GET_LIST_BY_FILTER_ERROR,
  

    CONTACT_MSG_GET_ONE,
    CONTACT_MSG_GET_ONE_SUCCESS,
    CONTACT_MSG_GET_ONE_ERROR,
    
    CONTACT_MSG_ADD_ONE,
    CONTACT_MSG_ADD_ONE_SUCCESS,
    CONTACT_MSG_ADD_ONE_ERROR,
  
    CONTACT_MSG_EDIT_ONE,
    CONTACT_MSG_EDIT_ONE_SUCCESS,
    CONTACT_MSG_EDIT_ONE_ERROR,
  
    CONTACT_MSG_DELETE_ONE,
    CONTACT_MSG_DELETE_ONE_SUCCESS,
    CONTACT_MSG_DELETE_ONE_ERROR,


    CONTACT_MSGS_DELETE_MANY,
    CONTACT_MSGS_DELETE_MANY_SUCCESS,
    CONTACT_MSGS_DELETE_MANY_ERROR
  } from '../actions';

import { NotificationManager } from '../../components/common/react-notifications';
import  notificationMessages  from '../../lang/locales/notificationMsg';


const INIT_STATE = {
    contact_msgs: [],
    count: 0,
    contactMsg: null,
    error: '',
    loading:false
  };
export default (state = INIT_STATE, action) => {
  const messages = notificationMessages()
	switch (action.type) {

        //GET LIST
	case CONTACT_MSG_GET_LIST:
      return { ...state, loading: true, error: '', 
      count: 0
    };
    case CONTACT_MSG_GET_LIST_SUCCESS:
      return { ...state, loading: false, 
        contact_msgs: action.payload.contact_msgs || [],
        count: action.payload.count || 0
      };
	case CONTACT_MSG_GET_LIST_ERROR:
      return { ...state, loading: false, error: action.payload.message };

     //GET LIST BY FILTER
    case CONTACT_MSG_GET_LIST_BY_FILTER:
      return { ...state, loading: true, error: '', 
      count: 0
    };
    case CONTACT_MSG_GET_LIST_BY_FILTER_SUCCESS:
      return { ...state, loading: false, 
        contact_msgs: action.payload.contact_msgs || [],
        count: action.payload.count || 0
      };
    case CONTACT_MSG_GET_LIST_BY_FILTER_ERROR:
      return { ...state, loading: false, error: action.payload.message };



     // GET ONE
     case CONTACT_MSG_GET_ONE:
     return { ...state, loading: true, error: '', class: null};
    case CONTACT_MSG_GET_ONE_SUCCESS:
     return { ...state, loading: false, error: '',
     contactMsg: action.payload.contactMsg
     };
    case CONTACT_MSG_GET_ONE_ERROR:
     return { ...state, loading: false, error: action.payload.message };

   
        // ADD ONE
    case CONTACT_MSG_ADD_ONE:
      return { ...state, loading: true, error: ''};
    case CONTACT_MSG_ADD_ONE_SUCCESS:    
      NotificationManager.success(messages["Added"], messages["Success"],3000, null, null, '');
      return { ...state, loading: false, 
        contact_msgs:[action.payload.contactMsg, ...state.contact_msgs]
      };
    case CONTACT_MSG_ADD_ONE_ERROR:
      NotificationManager.warning(action.payload.message ||  messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };


     // EDIT ONE
    case CONTACT_MSG_EDIT_ONE:
      return { ...state, loading: true, error: ''};
    case CONTACT_MSG_EDIT_ONE_SUCCESS:    
      NotificationManager.success(messages["Edited"], messages["Success"], 3000, null, null, '');
      return { ...state, loading: false, error: '',
      contactMsg: action.payload.contactMsg
     };
    case CONTACT_MSG_EDIT_ONE_ERROR:
      NotificationManager.warning(action.payload.message ||  messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };



      // DELETE ONE
    case CONTACT_MSG_DELETE_ONE:
      return { ...state, loading: true, error: ''};
    case CONTACT_MSG_DELETE_ONE_SUCCESS:  
      const contact_msgs = state.contact_msgs 
      if(action.payload.index!=null && action.payload.index!=undefined)
      contact_msgs.splice(action.payload.index, 1)

      return { ...state, loading: false, error: '',
      contact_msgs
      };
    case CONTACT_MSG_DELETE_ONE_ERROR:
      NotificationManager.warning(action.payload.message ||  messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };

     
         // DELETE MANY
    case CONTACT_MSGS_DELETE_MANY:
      return { ...state, loading: true, error: '' };
    case CONTACT_MSGS_DELETE_MANY_SUCCESS:
      const contact_msgs_list = state.contact_msgs
     

      if (action.payload.data.ids.length > 0) {
   
        for (let i = 0; i < action.payload.data.ids.length; i++) {

          let index_contact_msg = -1
          for (let j = 0; j < contact_msgs_list.length; j++) {
            if (contact_msgs_list[j]._id === action.payload.data.ids[i]) {
              index_contact_msg = j; break;
            }
          }
          if (index_contact_msg > -1) contact_msgs_list.splice(index_contact_msg, 1)
        }
      }
      return {
        ...state, loading: false, error: '',
        contact_msgs: contact_msgs_list
      };
    case CONTACT_MSGS_DELETE_MANY_ERROR:
      NotificationManager.warning(action.payload.message || messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };




       
	default: return { ...state };
    }
}