import {
	INSTITUTION_GET_LIST,
	INSTITUTION_GET_LIST_SUCCESS,
  INSTITUTION_GET_LIST_ERROR,

  INSTITUTION_GET_MY_LIST,
	INSTITUTION_GET_MY_LIST_SUCCESS,
  INSTITUTION_GET_MY_LIST_ERROR,
  
  INSTITUTION_GET_ITEM,
	INSTITUTION_GET_ITEM_SUCCESS,
  INSTITUTION_GET_ITEM_ERROR,

  INSTITUTION_SELECT_MY_INST,
  POST_INSTITUTION_SELECT_MY_INST,

  INSTITUTION_ACTIVATE_ITEM,
	INSTITUTION_ACTIVATE_ITEM_SUCCESS,
  INSTITUTION_ACTIVATE_ITEM_ERROR,

	INSTITUTION_GET_LIST_WITH_FILTER,
	INSTITUTION_GET_LIST_WITH_ORDER,
  INSTITUTION_GET_LIST_SEARCH,
  
	INSTITUTION_ADD_ITEM,
	INSTITUTION_ADD_ITEM_SUCCESS,
  INSTITUTION_ADD_ITEM_ERROR,
  
  INSTITUTION_SELECTED_ITEMS_CHANGE,
  INSTITUTION_CHANGE_SECTION,

  INSTITUTION_DELETE_ONE,
  INSTITUTION_DELETE_ONE_SUCCESS,
  INSTITUTION_DELETE_ONE_ERROR,


  INSTITUTION_EDIT_ONE,
  INSTITUTION_EDIT_ONE_SUCCESS,
  INSTITUTION_EDIT_ONE_ERROR ,

} from '../actions';
import { NotificationManager } from '../../components/common/react-notifications';
import  notificationMessages  from '../../lang/locales/notificationMsg';

let my_inst = null;
let post_inst = null;
try {
  my_inst = JSON.parse(localStorage.getItem('my_inst'))
  post_inst = JSON.parse(localStorage.getItem('my_inst'))
} catch (error) {
  my_inst = null;
}

const INIT_STATE = {
	institutions: [],
  count: 0,
  inst: null,
  error: '',
  success_add: '',

  my_institutions: [],
  my_inst,

	filter: null,
	searchKeyword: '',
	orderColumn: null,
	loading: false,
	labels: [
		{ label: "EDUCATION", color: "secondary" },
		{ label: "NEW FRAMEWORK", color: "primary" },
		{ label: "PERSONAL", color: "info" }
	],
	orderColumns: [
		{ column: "title", label: "Title" },
		{ column: "category", label: "Category" },
		{ column: "status", label: "Status" },
		{ column: "label", label: "Label" },
	],
	categories: ["Flexbox", "Sass", "React"],
  selectedItems: [],
  
  section: {name: ''}
};

export default (state = INIT_STATE, action) => {
  const messages = notificationMessages()
	switch (action.type) {
		case INSTITUTION_GET_LIST:
      return { ...state, loading: true, success_add: '', 
      error: ''
    };
    case INSTITUTION_GET_LIST_SUCCESS:
      return { ...state, 
        loading: false, 
        institutions: action.payload.institutions,
        count: action.payload.count
      };
		case INSTITUTION_GET_LIST_ERROR:
      return { ...state, loading: false, error: action.payload };

    
    //  GET MY INSTITUTIONS ( institutions that i can manage )
    case INSTITUTION_GET_MY_LIST:
      return { ...state, loading: true,
      error: ''
    };
    case INSTITUTION_GET_MY_LIST_SUCCESS:
      return { ...state, 
        loading: false, 
        my_institutions: action.payload.institutions
      };
		case INSTITUTION_GET_MY_LIST_ERROR:
      return { ...state, loading: false, error: action.payload };
      



    //GET ONE
    case INSTITUTION_GET_ITEM:
			return { ...state, inst: null, loading: true, error: '' };

    case INSTITUTION_GET_ITEM_SUCCESS:
      return { ...state, loading: false, inst: action.payload.institution };

		case INSTITUTION_GET_ITEM_ERROR:
      return { ...state, loading: false, inst: null, error: action.payload.message };


    //EDIT ONE
    case INSTITUTION_EDIT_ONE:
			return { ...state, inst: null, loading: true, error: '' };

    case INSTITUTION_EDIT_ONE_SUCCESS:
      NotificationManager.success(messages["Edited"], messages["Success"], 3000, null, null, '');
      return { ...state, loading: false, inst: action.payload.institution };

		case INSTITUTION_EDIT_ONE_ERROR:
      NotificationManager.warning(action.payload.message ||  messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, inst: null, error: action.payload.message };




    //SELECT MY INST (to be global var)
    case INSTITUTION_SELECT_MY_INST:
      if(!action.payload) localStorage.removeItem('my_inst')
      else localStorage.setItem("my_inst", JSON.stringify(action.payload))

      return { ...state, my_inst: action.payload };

    //SELECT MY INST FOR POSTS(to be global var)
    case POST_INSTITUTION_SELECT_MY_INST:
      if(!action.payload) localStorage.removeItem('post_inst')
      else localStorage.setItem("post_inst", JSON.stringify(action.payload))

      return { ...state, post_inst: action.payload };
      
    //CHANGE INST SECTION (LIKE EVENTS)
    case INSTITUTION_CHANGE_SECTION:
      if(!action.payload) return { ...state, section: {name: ''} }; 
      return { ...state, section: action.payload };

    
    //ACTIVATE ONE
    case INSTITUTION_ACTIVATE_ITEM:
			return { ...state, loading: true, error: '' };

    case INSTITUTION_ACTIVATE_ITEM_SUCCESS:
      return { ...state, loading: false, inst: {...state.inst, active: action.payload.active} };

		case INSTITUTION_ACTIVATE_ITEM_ERROR:
      return { ...state, loading: false, error: action.payload.msg }; 




    //ADD ONE
    case INSTITUTION_ADD_ITEM:
      return { ...state, error: '', success_add: '', 
        loading: true 
      };
    case INSTITUTION_ADD_ITEM_SUCCESS:
      NotificationManager.success(messages["Added"], messages["Success"],3000, null, null, '');
      return { ...state, error: '', success_add: 'Request sent', loading: false, 
        institutions: [action.payload.institution, ...action.payload.institutions]
      };
    case INSTITUTION_ADD_ITEM_ERROR:
      NotificationManager.warning(action.payload.message ||  messages["Unkown Error"], messages["Error"], 3000, null, null, '');
			return { ...state, loading: false, success_add: '', error: (action.payload.error) ? action.payload.error.message : 'unknown error' };

      



		case INSTITUTION_GET_LIST_WITH_FILTER:
			if (action.payload.column === '' || action.payload.value === '') {
				return { ...state, loading: true, institutions: state.institutions, filter: null };
			} else {
				const filteredItems = state.institutions.filter((item) =>
					item[action.payload.column] === action.payload.value);
				return {
					...state, loading: true, institutions: filteredItems, filter: {
						column: action.payload.column,
						value: action.payload.value
					}
				}
			}

		case INSTITUTION_GET_LIST_WITH_ORDER:
			if (action.payload === '') {
				return { ...state, loading: true, institutions: state.institutions, orderColumn: null };
			} else {
				const sortedItems = state.institutions.sort((a, b) => {
					if (
						a[action.payload] <
						b[action.payload]
					)
						return -1;
					else if (
						a[action.payload] >
						b[action.payload]
					)
						return 1;
					return 0;
				})
				return { ...state, loading: true, institutions: sortedItems, orderColumn: state.orderColumns.find(x => x.column === action.payload) }
			}

		case INSTITUTION_GET_LIST_SEARCH:
			if (action.payload === '') {
				return { ...state, institutions: state.institutions };
			} else {
				const keyword = action.payload.toLowerCase();
				const searchItems = state.institutions.filter((item) =>
					item.title.toLowerCase().indexOf(keyword) > -1 || item.detail.toLowerCase().indexOf(keyword) > -1 || item.status.toLowerCase().indexOf(keyword) > -1 || item.category.toLowerCase().indexOf(keyword) > -1 || item.label.toLowerCase().indexOf(keyword) > -1);
				return { ...state, loading: true, institutions: searchItems, searchKeyword: action.payload }
			}

		case INSTITUTION_SELECTED_ITEMS_CHANGE:
      return { ...state, loading: true, selectedItems: action.payload};
      



    // DELETE ONE
    case INSTITUTION_DELETE_ONE:
      return { ...state, loading: true, error: ''};
    case INSTITUTION_DELETE_ONE_SUCCESS:  
      const institutions = state.institutions 
      if(action.payload.index!=null && action.payload.index!=undefined)
      institutions.splice(action.payload.index, 1)

      return { ...state, loading: false, error: '',
        institutions
      };
    case INSTITUTION_DELETE_ONE_ERROR:
      NotificationManager.warning(action.payload.message ||  messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };
		default: return { ...state };
	}
}
