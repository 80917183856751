import {
	SPECIALITY_GET_LIST,
	SPECIALITY_GET_LIST_SUCCESS,
  SPECIALITY_GET_LIST_ERROR,

  SPECIALITY_GET_ONE,
	SPECIALITY_GET_ONE_SUCCESS,
  SPECIALITY_GET_ONE_ERROR,

  SPECIALITY_ADD_ONE,
  SPECIALITY_ADD_ONE_SUCCESS,
  SPECIALITY_ADD_ONE_ERROR,

  SPECIALITY_EDIT_ONE,
  SPECIALITY_EDIT_ONE_SUCCESS,
  SPECIALITY_EDIT_ONE_ERROR,

  SPECIALITY_DELETE_ONE,
  SPECIALITY_DELETE_ONE_SUCCESS,
  SPECIALITY_DELETE_ONE_ERROR,


  SPECIALITIES_DELETE_MANY,
  SPECIALITIES_DELETE_MANY_SUCCESS,
  SPECIALITIES_DELETE_MANY_ERROR
} from '../actions';
import { NotificationManager } from '../../components/common/react-notifications';
import  notificationMessages  from '../../lang/locales/notificationMsg'

const INIT_STATE = {
	specialities: [],
  count: 0,
  speciality: null, loading: false,
  error: '',
};

export default (state = INIT_STATE, action) => {
  const messages = notificationMessages()
	switch (action.type) {
    case SPECIALITY_GET_LIST:
      return { ...state, loading: true, error: '', 
      count: 0
    };
    case SPECIALITY_GET_LIST_SUCCESS:
      return { ...state, loading: false, 
        specialities: action.payload.specialities || [],
        count: action.payload.count || 0
      };
    case SPECIALITY_GET_LIST_ERROR:
      return { ...state, loading: false, error: action.payload.message };






      // GET ONE
    case SPECIALITY_GET_ONE:
      return { ...state, loading: true, error: ''};
    case SPECIALITY_GET_ONE_SUCCESS:
      return { ...state, loading: false, error: '',
        speciality: action.payload.speciality
      };
    case SPECIALITY_GET_ONE_ERROR:
      return { ...state, loading: false, error: action.payload.message };









      // ADD ONE
    case SPECIALITY_ADD_ONE:
      return { ...state, loading: true, error: ''};
    case SPECIALITY_ADD_ONE_SUCCESS:    
      NotificationManager.success(messages["Added"], messages["Success"],3000, null, null, '');
      return { ...state, loading: false, 
        specialities: [action.payload.speciality, ...state.specialities]
      };
    case SPECIALITY_ADD_ONE_ERROR:
      NotificationManager.warning(action.payload.message ||  messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };





    
      // EDIT ONE
    case SPECIALITY_EDIT_ONE:
      return { ...state, loading: true, error: ''};
    case SPECIALITY_EDIT_ONE_SUCCESS:    
      NotificationManager.success(messages["Edited"], messages["Success"], 3000, null, null, '');
      return { ...state, loading: false, error: '',
      speciality: action.payload.speciality
      };
    case SPECIALITY_EDIT_ONE_ERROR:
      NotificationManager.warning(action.payload.message ||  messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };

    




      // DELETE ONE
    case SPECIALITY_DELETE_ONE:
      return { ...state, loading: true, error: ''};
    case SPECIALITY_DELETE_ONE_SUCCESS:  
      const specialities = state.specialities 
      if(action.payload.index!=null && action.payload.index!=undefined)
      specialities.splice(action.payload.index, 1)

      return { ...state, loading: false, error: '',
        specialities
      };
    case SPECIALITY_DELETE_ONE_ERROR:
      NotificationManager.warning(action.payload.message ||  messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };

    // DELETE MANY
    case SPECIALITIES_DELETE_MANY:
      return { ...state, loading: true, error: '' };
    case SPECIALITIES_DELETE_MANY_SUCCESS:
      const specialities_list = state.specialities
     

      if (action.payload.data.ids.length > 0) {
   
        for (let i = 0; i < action.payload.data.ids.length; i++) {

          let index_speciality = -1
          for (let j = 0; j < specialities_list.length; j++) {
            if (specialities_list[j]._id === action.payload.data.ids[i]) {
              index_speciality = j; break;
            }
          }
          if (index_speciality > -1) specialities_list.splice(index_speciality, 1)
        }
      }
      return {
        ...state, loading: false, error: '',
        specialities: specialities_list
      };
    case SPECIALITIES_DELETE_MANY_ERROR:
      NotificationManager.warning(action.payload.message || messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };






		default: return { ...state };
	}
}
