import { all, call, fork, put, takeEvery } from "redux-saga/effects";
// import { getDateWithFormat } from "../../helpers/Utils";
import { api } from "../../constants/defaultValues";
import axios from "axios";

import {
  CONTACT_MSG_GET_LIST, CONTACT_MSG_GET_ONE, CONTACT_MSG_ADD_ONE, CONTACT_MSG_EDIT_ONE, CONTACT_MSG_DELETE_ONE, CONTACT_MSG_GET_LIST_BY_FILTER,
  CONTACT_MSGS_DELETE_MANY
} from "../actions";

import {
  getContactMsgListSuccess,
  getContactMsgListError,

  getContactMsgByFilterSuccess,
  getContactMsgByFilterError,

  getOneContactMsgSuccess,
  getOneContactMsgError,

  addContactMsgSuccess,
  addContactMsgError,

  editOneContactMsgSuccess,
  editOneContactMsgError,

  deleteOneContactMsgSuccess,
  deleteOneContactMsgError,

  deleteManyContactMsgsSuccess,
  deleteManyContactMsgsError

} from "./actions";

//GET LIST
const getContactMsgListRequest = async (payload) => {
  return await axios.get(api.contact_msg + `/list/${payload.inst_id}/${payload.offset}/${payload.limit}`, { headers: { Authorization: payload.token } })
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* getContactMsgList({ payload }) {
  try {
    const ret = yield call(getContactMsgListRequest, payload);
    //console.log("////// getContactMsgList  :",ret);

    if (ret && ret.status == 200)
      yield put(getContactMsgListSuccess({ contact_msgs: ret.data.contact_msgs || [], count: ret.data.count || 0 }));

    else yield put(getContactMsgListError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    yield put(getContactMsgListError({ error, message: (error) ? error.message : '' }));
  }
}

//GET LIST By FILTER
const getContactMsgByFilterRequest = async (payload) => {
  return await axios.post(`${api.contact_msg}/filters/${payload.offset || 0}/${payload.limit || 200}`, payload.filter, { headers: { Authorization: payload.token } })
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* getContactMsgByFilter({ payload }) {
  try {
    const ret = yield call(getContactMsgByFilterRequest, payload);
    //console.log("////// getContactMsgByFilter  :",ret);

    if (ret && ret.status == 200)
      yield put(getContactMsgByFilterSuccess({ contact_msgs: ret.data.contact_msgs || [], count: ret.data.count || 0 }));

    else yield put(getContactMsgByFilterError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    yield put(getContactMsgByFilterError({ error, message: (error) ? error.message : '' }));
  }
}

//GET ONE
const getOneContactMsgRequest = async (payload) => {
  return await axios.get(api.contact_msg + `/get-one/${payload._id}`, { headers: { Authorization: payload.token } })
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* getOneContactMsg({ payload }) {
  try {
    const ret = yield call(getOneContactMsgRequest, payload);

    if (ret && ret.status == 200)
      yield put(getOneContactMsgSuccess({ contactMsg: ret.data.contact_msg }));
    else yield put(getOneContactMsgError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    console.log('saga err', error);
    yield put(getOneContactMsgError({ error, message: (error) ? error.message : '' }));
  }
}


//ADD ONE
const addContactMsgRequest = async (payload) => {
  return await axios.post(api.contact_msg, payload.data, { headers: { Authorization: payload.token } })
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* addContactMsg({ payload }) {
  try {
    const ret = yield call(addContactMsgRequest, payload);

    if (ret && ret.status == 201) {
      const { history } = payload;

      if (history) history.push('/app/community/parents-school')
      yield put(addContactMsgSuccess({ contactMsg: ret.data.contact_msg }));
    }
    else yield put(addContactMsgError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    console.log('saga err', error);
    yield put(addContactMsgError({ error, message: (error) ? error.message : '' }));
  }
}

//EDIT ONE
const editOneContactMsgRequest = async (payload) => {
  return await axios.patch(`${api.contact_msg}/edit-one/${payload._id}`, payload.data, { headers: { Authorization: payload.token } })
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* editOneContactMsg({ payload }) {
  try {
    const ret = yield call(editOneContactMsgRequest, payload);

    if (ret && ret.status == 200) {
      const { history } = payload;

      if (history) history.push('/app/community/parents-school')
      yield put(editOneContactMsgSuccess({ contactMsg: ret.data.contact_msg }));
    }
    else yield put(editOneContactMsgError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    console.log('saga err', error);
    yield put(editOneContactMsgError({ error, message: (error) ? error.message : '' }));
  }
}

// DELETE ONE
const deleteOneContactMsgRequest = async (payload) => {
  return await axios.delete(`${api.contact_msg}/delete-one/${payload._id}`, { headers: { Authorization: payload.token } })
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* deleteOneContactMsg({ payload }) {
  try {
    const ret = yield call(deleteOneContactMsgRequest, payload);

    if (ret && ret.status == 202) {
      const { history, index } = payload;

      if (history) history.push('/app/community/parents-school')
      yield put(deleteOneContactMsgSuccess({ index }));
    }
    else yield put(deleteOneContactMsgError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    console.log('saga err', error);
    yield put(deleteOneContactMsgError({ error, message: (error) ? error.message : '' }));
  }
}

//DELETE MANY

const deleteManyContactMsgsRequest = async (payload) => {
  return await axios.delete(`${api.contact_msg}/delete-many`, { headers: { Authorization: payload.token }, data: payload.data })
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* deleteManyContactMsgs({ payload }) {
  try {
    const ret = yield call(deleteManyContactMsgsRequest, payload);

    if (ret && ret.status == 202) {
      const { history, data } = payload;

      if (history) history.push('/app/community/parents-school')
      yield put(deleteManyContactMsgsSuccess({ data }));
    }
    else yield put(deleteManyContactMsgsError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    console.log('saga err', error);
    yield put(deleteManyContactMsgsError({ error, message: (error) ? error.message : '' }));
  }
}

//////// WATCHERS
export function* watchGetList() {
  yield takeEvery(CONTACT_MSG_GET_LIST, getContactMsgList);
}

export function* watchGetListByFilter() {
  yield takeEvery(CONTACT_MSG_GET_LIST_BY_FILTER, getContactMsgByFilter);
}

export function* watchGetOne() {
  yield takeEvery(CONTACT_MSG_GET_ONE, getOneContactMsg);
}

export function* watchEditOne() {
  yield takeEvery(CONTACT_MSG_EDIT_ONE, editOneContactMsg);
}

export function* watchAddOne() {
  yield takeEvery(CONTACT_MSG_ADD_ONE, addContactMsg);
}

export function* watchDeleteOne() {
  yield takeEvery(CONTACT_MSG_DELETE_ONE, deleteOneContactMsg);
}
export function* watchDeleteMany() {
  yield takeEvery(CONTACT_MSGS_DELETE_MANY,deleteManyContactMsgs);
} 
export default function* rootSaga() {
  yield all([
    fork(watchGetList),
    fork(watchGetListByFilter),
    fork(watchGetOne),
    fork(watchEditOne),
    fork(watchAddOne),
    fork(watchDeleteOne),
    fork(watchDeleteMany)

  ]);
}