import { all, call, fork, put, takeEvery } from "redux-saga/effects";
// import { getDateWithFormat } from "../../helpers/Utils";
import { api } from "../../../constants/defaultValues";
import axios from "axios";

import { TAX_GET_LIST, TAX_ADD_ONE, TAX_GET_ONE, TAX_EDIT_ONE, TAX_DELETE_ONE,TAXES_DELETE_MANY,
} from "../../actions";

import {
  getTaxesListSuccess,
  getTaxesListError,

  getOneTaxSuccess,
  getOneTaxError,

  addTaxSuccess,
  addTaxError,

  editTaxSuccess,
  editTaxError,
  
  deleteOneTaxSuccess,
  deleteOneTaxError,

  deleteManyTaxesSuccess,
  deleteManyTaxesError,
} from "./actions";


  //GET LIST
const getTaxesListRequest = async (payload) => {
  return await axios.get(api.taxes+`/list/${payload.inst_id}/${payload.offset}/${payload.limit}`, { headers: {Authorization: payload.token} })
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};

function* getTaxesList({payload}) {
  try {
    const ret = yield call(getTaxesListRequest, payload);
    
    if (ret && ret.status==200)
    yield put(getTaxesListSuccess({taxes: ret.data.taxes || [], count: ret.data.count || 0}));
    
    else yield put(getTaxesListError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
    yield put(getTaxesListError({error, message: (error) ? error.message : ''}));
  }
}



//GET ONE
const getOneTaxRequest = async (payload) => {
  return await axios.get(api.taxes+`/get-one/${payload._id}`, { headers: {Authorization: payload.token} })
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* getOneTax({payload}) {
  try {
    const ret = yield call(getOneTaxRequest, payload);

    if (ret && ret.status==200) yield put(getOneTaxSuccess({tax: ret.data.tax}));
    else yield put(getOneTaxError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
    //console.log('saga err', error);
    yield put(getOneTaxError({error, message: (error) ? error.message : ''}));
  }
}


  //ADD ONE
const addTaxRequest = async (payload) => {
 
  return await axios.post(api.taxes, payload.data, { headers: {Authorization: payload.token} })
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* addTax({payload}) { 
  //console.log('saga add tax',payload);
   
  try {
    const ret = yield call(addTaxRequest, payload);
    
    if (ret && ret.status==201){
      const { history } = payload;

      if(history) history.push('/app/billing/taxes')
      yield put(addTaxSuccess({tax: ret.data.tax}));
    }
    else yield put(addTaxError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
    //console.log('saga err', error);
    yield put(addTaxError({error, message: (error) ? error.message : ''}));
  }
}





  //EDIT ONE
const editOneTaxRequest = async (payload) => {
  return await axios.patch(`${api.taxes}/edit-one/${payload._id}`, payload.data, { headers: {Authorization: payload.token} })
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* editOneTax({payload}) {  
  try {
    const ret = yield call(editOneTaxRequest, payload);
    
    if (ret && ret.status==200){
      const { history } = payload;

      if(history) history.push('/app/billing/taxes')
      yield put(editTaxSuccess({tax: ret.data.tax}));
    }
    else yield put(editTaxError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
    //console.log('saga err', error);
    yield put(editTaxError({error, message: (error) ? error.message : ''}));
  }
}


  // DELETE ONE
const deleteOneTaxRequest = async (payload) => {
  return await axios.delete(`${api.taxes}/delete-one/${payload._id}`, { headers: {Authorization: payload.token} })
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* deleteOneTax({payload}) {  
  //console.log('saga delete tax',payload);
  try {
    const ret = yield call(deleteOneTaxRequest, payload);
    
    if (ret && ret.status==202){
      const { history, index } = payload;

      if(history) history.push('/app/billing/taxes')
      yield put(deleteOneTaxSuccess({index}));
    }
    else yield put(deleteOneTaxError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
    //console.log('saga err', error);
    yield put(deleteOneTaxError({error, message: (error) ? error.message : ''}));
  }
}


//DELETE MANY

const deleteManyTaxesRequest = async (payload) => {
  return await axios.delete(`${api.taxes}/delete-many`, { headers: { Authorization: payload.token }, data: payload.data })
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* deleteManyTaxes({ payload }) {
  try {
    const ret = yield call(deleteManyTaxesRequest, payload);

    if (ret && ret.status == 202) {
      const { history, data } = payload;

      if (history) history.push('/app/billing/taxes')
      yield put(deleteManyTaxesSuccess({ data }));
    }
    else yield put(deleteManyTaxesError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    console.log('saga err', error);
    yield put(deleteManyTaxesError({ error, message: (error) ? error.message : '' }));
  }
}
//////// WATCHERS
export function* watchGetList() {
  yield takeEvery(TAX_GET_LIST, getTaxesList);
}

export function* watchGetOne() {
  yield takeEvery(TAX_GET_ONE, getOneTax);
}

export function* watchEditOne() {
  yield takeEvery(TAX_EDIT_ONE, editOneTax);
}

export function* watchAddOne() {
  yield takeEvery(TAX_ADD_ONE, addTax);
}

export function* watchDeleteOne() {
  yield takeEvery(TAX_DELETE_ONE, deleteOneTax);
}
export function* watchDeleteMany() {
  yield takeEvery(TAXES_DELETE_MANY,deleteManyTaxes);
}
export default function* rootSaga() {
  yield all([
    fork(watchGetList),
    fork(watchGetOne),
    fork(watchEditOne),
    fork(watchAddOne),
    fork(watchDeleteOne),
    fork(watchDeleteMany)
  ]);
}
