import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { api } from "../../constants/defaultValues";
import axios from "axios";
import { EXAMS_GET_LIST,EXAM_GET_ONE,EXAMS_GET_BY_FILTER,EXAM_ADD_ONE,EXAM_EDIT_ONE,EXAM_DELETE_ONE,
  EXAMS_DELETE_MANY
} from "../actions";
import {
    getExamsListSuccess,
    getExamsListError,

    getOneExamSuccess,
    getOneExamError,

    getExamsByFilterSuccess,
    getExamsByFilterError,

    addExamSuccess,
    addExamError,

    editOneExamSuccess,
    editOneExamError,

    deleteOneExamSuccess,
    deleteOneExamError,

    deleteManyExamsSuccess,
    deleteManyExamsError
  } from "./actions";

 //GET LIST
 const getExamsListRequest = async (payload) => {
    return await axios.get(api.exams +`/list/${payload.inst_id}/${payload.offset}/${payload.limit}`, { headers: {Authorization: payload.token} })
    .then(resp=>resp)
    .catch(error=>{return (error && error.response) ? error.response : error})
  };
function* getExamsList({payload}) {
    try {
      const ret = yield call(getExamsListRequest, payload);
      
      if (ret && ret.status==200)
    
      yield put(getExamsListSuccess({exams: ret.data.exams || [], count: ret.data.count || 0}));
 
    
      else yield put(getExamsListError({message: (ret.data) ? ret.data.msg : "Network Error"}))
  
    } catch (error) {
      yield put(getExamsListError({error, message: (error) ? error.message : ''}));
    }
  }


//GET ONE
const getOneExamRequest = async (payload) => {
    return await axios.get(api.exams+`/get-one/${payload._id}`, { headers: {Authorization: payload.token} })
    .then(resp=>resp)
    .catch(error=>{return (error && error.response) ? error.response : error})
  };
  function* getOneExam({payload}) {  
    try {
      const ret = yield call(getOneExamRequest, payload);
  
      if (ret && ret.status==200)
      yield put(getOneExamSuccess({exam: ret.data.exam}));
      
      else yield put(getOneExamError({message: (ret.data) ? ret.data.msg : "Network Error"}))
  
    } catch (error) {
      yield put(getOneExamError({error, message: (error) ? error.message : ''}));
    }
  }
  
//GET EXAmS BY FILTER
const getExamsByFilterRequest = async (payload) => {
    return await axios.post(api.exams+`/filters/${payload.offset}/${payload.limit}`,payload.filter, { headers: {Authorization: payload.token} })
    .then(resp=>resp)
    .catch(error=>{return (error && error.response) ? error.response : error})
  };
  function* getExamsByFilter({payload}) {
    try {
      const ret = yield call(getExamsByFilterRequest, payload);
      
  
      if (ret && ret.status==200)
      yield put(getExamsByFilterSuccess({exams: ret.data.exams}));
      
      else yield put(getExamsByFilterError({message: (ret.data) ? ret.data.msg : "Network Error"}))
  
    } catch (error) {
      yield put(getExamsByFilterError({error, message: (error) ? error.message : ''}));
    }
  }

//ADD ONE
const addExamRequest = async (payload) => {
 
    return await axios.post(api.exams,payload.data, { 
      headers: {Authorization: payload.token},
  
    })
    .then(resp=>resp)
    .catch(error=>{return (error && error.response) ? error.response : error})
  };
  function* addExam({payload}) {  
    try {
      const ret = yield call(addExamRequest, payload);
      
      if (ret && ret.status==201){
        const { history } = payload;
  
        if(history) history.push('/app/exams/exams')
        yield put(addExamSuccess({exam_type: ret.data.exam_type}));
      }
      else yield put(addExamError({message: (ret.data) ? ret.data.msg : "Network Error"}))
  
    } catch (error) {
      console.log('saga err', error);
      yield put(addExamError({error, message: (error) ? error.message : ''}));
    }
  }
  
  //EDIT ONE
  const editOneExamRequest = async (payload) => {
  
    return await axios.patch(`${api.exams}/edit-one/${payload._id}`, payload.data, { headers: {Authorization: payload.token}

   })
    .then(resp=>resp)
    .catch(error=>{return (error && error.response) ? error.response : error})
  };
function* editOneExam({payload}) {  
    try {
      const ret = yield call(editOneExamRequest, payload);
      
      if (ret && ret.status==200){
        const { history } = payload;
  
        if(history) history.push('/app/exams/exams')
        yield put(editOneExamSuccess({exam: ret.data.exam}));
      }
      else yield put(editOneExamError({message: (ret.data) ? ret.data.msg : "Network Error"}))
  
    } catch (error) {
      console.log('saga err', error);
      yield put(editOneExamError({error, message: (error) ? error.message : ''}));
    }
  }

// DELETE ONE
const ddeleteOneExamRequest = async (payload) => {
    return await axios.delete(`${api.exams}/delete-one/${payload._id}`, { headers: {Authorization: payload.token} })
    .then(resp=>resp)
    .catch(error=>{return (error && error.response) ? error.response : error})
  };
  function* deleteOneExam({payload}) {  
    try {
      const ret = yield call(ddeleteOneExamRequest, payload);
      
      if (ret && ret.status==202){
        const { history, index } = payload;
  
        if(history) history.push('/app/exams/exams')
        yield put(deleteOneExamSuccess({index}));
      }
      else yield put(deleteOneExamError({message: (ret.data) ? ret.data.msg : "Network Error"}))
  
    } catch (error) {
      console.log('saga err', error);
      yield put(deleteOneExamError({error, message: (error) ? error.message : ''}));
    }
  }




  const deleteManyExamsRequest = async (payload) => {
    return await axios.delete(`${api.exams}/delete-many`, { headers: { Authorization: payload.token }, data: payload.data })
      .then(resp => resp)
      .catch(error => { return (error && error.response) ? error.response : error })
  };
  function* deleteManyExams({ payload }) {
    try {
      const ret = yield call(deleteManyExamsRequest, payload);
  
      if (ret && ret.status == 202) {
        const { history, data } = payload;
  
        if (history) history.push('/app/exams/exams')
        yield put(deleteManyExamsSuccess({ data }));
      }
      else yield put(deleteManyExamsError({ message: (ret.data) ? ret.data.msg : "Network Error" }))
  
    } catch (error) {
      console.log('saga err', error);
      yield put(deleteManyExamsError({ error, message: (error) ? error.message : '' }));
    }
  }
  
//////// WATCHERS
export function* watchGetList() {
    yield takeEvery(EXAMS_GET_LIST, getExamsList);
  }
  
  export function* watchGetOne() {
    yield takeEvery(EXAM_GET_ONE, getOneExam);
  }
  
  export function* watchGetFilter() {
    yield takeEvery(EXAMS_GET_BY_FILTER, getExamsByFilter);
  }
  
  export function* watchEditOne() {
    yield takeEvery(EXAM_EDIT_ONE,editOneExam);
  }
  
  export function* watchAddOne() {
    yield takeEvery(EXAM_ADD_ONE, addExam);
  }
  
  export function* watchDeleteOne() {
    yield takeEvery(EXAM_DELETE_ONE, deleteOneExam);
  }
  export function* watchDeleteMany() {
    yield takeEvery(EXAMS_DELETE_MANY,deleteManyExams);
  }
  export default function* rootSaga() {
    yield all([
      fork(watchGetList),
      fork(watchGetOne),
      fork(watchGetFilter),
      fork(watchEditOne),
      fork(watchAddOne),
      fork(watchDeleteOne),
      fork(watchDeleteMany)
    ]);
  }