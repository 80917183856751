import {
  MODULE_GET_LIST,
	MODULE_GET_LIST_SUCCESS,
  MODULE_GET_LIST_ERROR,

  MODULE_GET_ONE,
	MODULE_GET_ONE_SUCCESS,
  MODULE_GET_ONE_ERROR,

  MODULE_GET_BY_FILTER,
  MODULE_GET_BY_FILTER_SUCCESS,
  MODULE_GET_BY_FILTER_ERROR,
  
  MODULE_ADD_ONE,
  MODULE_ADD_ONE_SUCCESS,
  MODULE_ADD_ONE_ERROR,

  MODULE_EDIT_ONE,
  MODULE_EDIT_ONE_SUCCESS,
  MODULE_EDIT_ONE_ERROR,

  MODULE_DELETE_ONE,
  MODULE_DELETE_ONE_SUCCESS,
  MODULE_DELETE_ONE_ERROR,

  MODULES_DELETE_MANY,
  MODULES_DELETE_MANY_SUCCESS,
  MODULES_DELETE_MANY_ERROR
} from '../actions';

  // GET LIST
export const getModulesList = (payload) => ({
  type: MODULE_GET_LIST,
  payload
});
export const getModulesListSuccess = (payload) => ({
  type: MODULE_GET_LIST_SUCCESS,
  payload
});
export const getModulesListError = (payload) => ({
  type: MODULE_GET_LIST_ERROR,
  payload
});







  // GET LIST
export const getOneModule = (payload) => ({
  type: MODULE_GET_ONE,
  payload
});
export const getOneModuleSuccess = (payload) => ({
  type: MODULE_GET_ONE_SUCCESS,
  payload
});
export const getOneModuleError = (payload) => ({
  type: MODULE_GET_ONE_ERROR,
  payload
});


// GET BY FILTER
export const getModulesByFilter = (payload) => ({
  type: MODULE_GET_BY_FILTER,
  payload
});
export const getModulesByFilterSuccess = (payload) => ({
  type: MODULE_GET_BY_FILTER_SUCCESS,
  payload
});
export const getModulesByFilterError = (payload) => ({
  type: MODULE_GET_BY_FILTER_ERROR,
  payload
});






  // ADD ONE
export const addModule = (payload) => ({
  type: MODULE_ADD_ONE,
  payload
});
export const addModuleSuccess = (payload) => ({
  type: MODULE_ADD_ONE_SUCCESS,
  payload
});
export const addModuleError = (payload) => ({
  type: MODULE_ADD_ONE_ERROR,
  payload
});




  // EDIT ONE
export const editModule = (payload) => ({
  type: MODULE_EDIT_ONE,
  payload
});
export const editModuleSuccess = (payload) => ({
  type: MODULE_EDIT_ONE_SUCCESS,
  payload
});
export const editModuleError = (payload) => ({
  type: MODULE_EDIT_ONE_ERROR,
  payload
});




  // DELETE ONE
export const deleteOneModule = (payload) => ({
  type: MODULE_DELETE_ONE,
  payload
});
export const deleteOneModuleSuccess = (payload) => ({
  type: MODULE_DELETE_ONE_SUCCESS,
  payload
});
export const deleteOneModuleError = (payload) => ({
  type: MODULE_DELETE_ONE_ERROR,
  payload
});


// DELETE MANY
export const deleteManyModules = (payload) => ({
  type:   MODULES_DELETE_MANY,
  payload
});
export const deleteManyModulesSuccess = (payload) => ({
  type: MODULES_DELETE_MANY_SUCCESS,
  payload
});
export const deleteManyModulesError = (payload) => ({
  type: MODULES_DELETE_MANY_ERROR,
  payload
});