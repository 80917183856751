import { all, call, fork, put, takeEvery } from "redux-saga/effects";
// import { getDateWithFormat } from "../../helpers/Utils";
import { api } from "../../constants/defaultValues";
import axios from "axios";

import { APPOINTEMENTS_GET_LIST,APPOINTEMENT_GET_ONE,APPOINTEMENTS_GET_BY_FILTER,APPOINTEMENT_ADD_ONE,APPOINTEMENT_EDIT_ONE,APPOINTEMENT_DELETE_ONE,
  APPOINTEMENTS_DELETE_MANY
} from "../actions";

import {
    getAppoitementsListSuccess,
    getAppoitementsListError,

    getOneAppointementSuccess,
    getOneAppointementError,

    getAppointementsByFilterSuccess,
    getAppointementsByFilterError,

    addAppointementSuccess,
    addAppointementError,

    editOneAppointementSuccess,
    editOneAppointementError,

    deleteOneAppointementSuccess,
    deleteOneAppointementError,

    deleteManyAppointementsSuccess,
    deleteManyAppointementsError
} from "./actions";

 //GET LIST
const getAppoitementsListRequest = async (payload) => {
    return await axios.get(api.appointements+`/list/${payload.inst_id}/${payload.offset}/${payload.limit}`, { headers: {Authorization: payload.token} })
    .then(resp=>resp)
    .catch(error=>{return (error && error.response) ? error.response : error})
  };
function* getAppoitementsList({payload}) {
    try {
      const ret = yield call(getAppoitementsListRequest, payload);
      
      if (ret && ret.status==200)
      yield put(getAppoitementsListSuccess({appointements: ret.data.appointments || [], count: ret.data.count || 0}));
      
      else yield put(getAppoitementsListError({message: (ret.data) ? ret.data.msg : "Network Error"}))
  
    } catch (error) {
      yield put(getAppoitementsListError({error, message: (error) ? error.message : ''}));
    }
}
  
//GET ONE
const getOneAppointementRequest = async (payload) => {
    return await axios.get(api.appointements+`/get-one/${payload._id}`, { headers: {Authorization: payload.token} })
    .then(resp=>resp)
    .catch(error=>{return (error && error.response) ? error.response : error})
  };
  function* getOneAppointement({payload}) {
    try {
      const ret = yield call(getOneAppointementRequest, payload);
  
      if (ret && ret.status==200)
      yield put(getOneAppointementSuccess({appointement: ret.data.appointment}));
      else yield put(getOneAppointementError({message: (ret.data) ? ret.data.msg : "Network Error"}))
  
    } catch (error) {
      console.log('saga err', error);
      yield put(getOneAppointementError({error, message: (error) ? error.message : ''}));
    }
}

//GET APPOINTEMENTS BY FILTER
const getAppointementsByFilterRequest = async (payload) => {
  return await axios.post(api.appointements+`/filters/${payload.offset}/${payload.limit}`,payload.filter, { headers: {Authorization: payload.token} })
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* getAppointementsByFilter({payload}) {
  try {
    const ret = yield call(getAppointementsByFilterRequest, payload);
    

    if (ret && ret.status==200)
    yield put(getAppointementsByFilterSuccess({appointements: ret.data.appointments , count: ret.data.count || 0}));
    
    else yield put(getAppointementsByFilterError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
    yield put(getAppointementsByFilterError({error, message: (error) ? error.message : ''}));
  }
}

 //ADD ONE
const addAppointementRequest = async (payload) => {
    return await axios.post(api.appointements, payload.data, { headers: {Authorization: payload.token} })
    .then(resp=>resp)
    .catch(error=>{return (error && error.response) ? error.response : error})
  };
function* addAppointement({payload}) {  
    try {
      const ret = yield call(addAppointementRequest, payload);
      
      if (ret && ret.status==201){
        const { history } = payload;
  
        if(history) history.push('/app/community/appointements')
        yield put(addAppointementSuccess({appointement: ret.data.appointment}));
      }
      else yield put(addAppointementError({message: (ret.data) ? ret.data.msg : "Network Error"}))
  
    } catch (error) {
      console.log('saga err', error);
      yield put(addAppointementError({error, message: (error) ? error.message : ''}));
    }
  }

  //EDIT ONE
const editOneAppointementRequest = async (payload) => {
    return await axios.patch(`${api.appointements}/edit-one/${payload._id}`, payload.data, { headers: {Authorization: payload.token} })
    .then(resp=>resp)
    .catch(error=>{return (error && error.response) ? error.response : error})
  };
function* editOneAppointement({payload}) {  
    try {
      const ret = yield call(editOneAppointementRequest, payload);
      
      if (ret && ret.status==200){
        const { history } = payload;
  
        if(history) history.push('/app/community/appointements')
        yield put(editOneAppointementSuccess({appointement: ret.data.appointment}));
      }
      else yield put(editOneAppointementError({message: (ret.data) ? ret.data.msg : "Network Error"}))
  
    } catch (error) {
      console.log('saga err', error);
      yield put(editOneAppointementError({error, message: (error) ? error.message : ''}));
    }
  }

  // DELETE ONE
const deleteOneAppointementRequest = async (payload) => {
    return await axios.delete(`${api.appointements}/delete-one/${payload._id}`, { headers: {Authorization: payload.token} })
    .then(resp=>resp)
    .catch(error=>{return (error && error.response) ? error.response : error})
  };
function* deleteOneAppointement({payload}) {  
    try {
      const ret = yield call(deleteOneAppointementRequest, payload);
      
      if (ret && ret.status==202){
        const { history, index } = payload;
  
        if(history) history.push('/app/community/appointements')
        yield put(deleteOneAppointementSuccess({index}));
      }
      else yield put(deleteOneAppointementError({message: (ret.data) ? ret.data.msg : "Network Error"}))
  
    } catch (error) {
      console.log('saga err', error);
      yield put(deleteOneAppointementError({error, message: (error) ? error.message : ''}));
    }
  }

//DELETE MANY

const deleteManyAppointementsRequest = async (payload) => {
  return await axios.delete(`${api.appointements}/delete-many`, { headers: { Authorization: payload.token }, data: payload.data })
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* deleteManyAppointements({ payload }) {
  try {
    const ret = yield call(deleteManyAppointementsRequest, payload);

    if (ret && ret.status == 202) {
      const { history, data } = payload;

      if (history) history.push('/app/community/appointements')
      yield put(deleteManyAppointementsSuccess({ data }));
    }
    else yield put(deleteManyAppointementsError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    console.log('saga err', error);
    yield put(deleteManyAppointementsError({ error, message: (error) ? error.message : '' }));
  }
}

//////// WATCHERS
export function* watchGetList() {
    yield takeEvery(APPOINTEMENTS_GET_LIST, getAppoitementsList);
  }
  
export function* watchGetOne() {
    yield takeEvery(APPOINTEMENT_GET_ONE, getOneAppointement);
  }

export function* watchGetFilter() {
    yield takeEvery(APPOINTEMENTS_GET_BY_FILTER, getAppointementsByFilter);
  }
export function* watchEditOne() {
    yield takeEvery(APPOINTEMENT_EDIT_ONE, editOneAppointement);
  }
  
export function* watchAddOne() {
    yield takeEvery(APPOINTEMENT_ADD_ONE, addAppointement);
  }
  
export function* watchDeleteOne() {
    yield takeEvery(APPOINTEMENT_DELETE_ONE, deleteOneAppointement);
  }
export function* watchDeleteMany() {
    yield takeEvery(APPOINTEMENTS_DELETE_MANY,deleteManyAppointements);
  } 
export default function* rootSaga() {
    yield all([
      fork(watchGetList),
      fork(watchGetOne),
      fork(watchGetFilter),
      fork(watchEditOne),
      fork(watchAddOne),
      fork(watchDeleteOne),
      fork(watchDeleteMany)

    ]);
  }