import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { getDateWithFormat } from "../../helpers/Utils";
import { api } from "../../constants/defaultValues";
import axios from "axios";

import { SCOOLYEAR_GET_LIST, SCOOLYEAR_ADD_ONE, SCOOLYEAR_GET_ONE, SCOOLYEAR_EDIT_ONE, SCHOOLYEAR_DELETE_ONE,SCHOOLYEARS_DELETE_MANY
} from "../actions";

import {
  getSchoolYearsListSuccess,
  getSchoolYearsListError,

  getOneSchoolYearSuccess,
  getOneSchoolYearError,

  addSchoolYearSuccess,
  addSchoolYearError,

  editSchoolYearSuccess,
  editSchoolYearError,
  deleteOneSchoolYearError,
  deleteOneSchoolYearSuccess,

  deleteManySchoolYearsSuccess,
  deleteManySchoolYearsError,
} from "./actions";


  //GET LIST
const getSchoolYearsListRequest = async (payload) => {
  return await axios.get(api.school_years+`/list/${payload.inst_id}/${payload.offset}/${payload.limit}`, { headers: {Authorization: payload.token} })
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* getSchoolYearsList({payload}) {
  try {
    const ret = yield call(getSchoolYearsListRequest, payload);
    
    if (ret && ret.status==200)
    yield put(getSchoolYearsListSuccess({school_years: ret.data.school_years || [], count: ret.data.count || 0}));
    
    else yield put(getSchoolYearsListError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
    yield put(getSchoolYearsListError({error, message: (error) ? error.message : ''}));
  }
}



//GET ONE
const getOneSchoolYearsRequest = async (payload) => {
  return await axios.get(api.school_years+`/get-one/${payload._id}`, { headers: {Authorization: payload.token} })
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* getOneSchoolYear({payload}) {  
  try {
    const ret = yield call(getOneSchoolYearsRequest, payload);

    if (ret && ret.status==200)
    yield put(getOneSchoolYearSuccess({school_year: ret.data.school_year}));
    
    else yield put(getOneSchoolYearError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
    yield put(getOneSchoolYearError({error, message: (error) ? error.message : ''}));
  }
}




  //ADD ONE
const addSchoolYearRequest = async (payload) => {
  return await axios.post(api.school_years, payload.data, { headers: {Authorization: payload.token} })
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* addSchoolYear({payload}) {  
  try {
    const ret = yield call(addSchoolYearRequest, payload);
    
    if (ret && ret.status==201){
      const { history } = payload;

      if(history) history.push('/app/my-institutions/school-years')
      yield put(addSchoolYearSuccess({school_year: ret.data.school_year}));
    }
    else yield put(addSchoolYearError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
    console.log('saga err', error);
    yield put(addSchoolYearError({error, message: (error) ? error.message : ''}));
  }
}





  //EDIT ONE
const editOneSchoolYearRequest = async (payload) => {
  console.log('payload',payload)
  return await axios.patch(`${api.school_years}/edit-one/${payload._id}`, payload.data, { headers: {Authorization: payload.token} })
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* editOneSchoolYear({payload}) {  
  try {
    const ret = yield call(editOneSchoolYearRequest, payload);
     console.log('respresprespresprespresp',ret.status,ret)
    if (ret && ret.status==200){
      const { history } = payload;

      if(history) history.push('/app/my-institutions/school-years')
      yield put(editSchoolYearSuccess({school_year: ret.data.school_year}));
    }
    else yield put(editSchoolYearError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
    console.log('saga err', error);
    yield put(editSchoolYearError({error, message: (error) ? error.message : ''}));
  }
}






  // DELETE ONE
  const deleteOneSchoolYearRequest = async (payload) => {
    return await axios.delete(`${api.school_years}/delete-one/${payload._id}`, { headers: {Authorization: payload.token} })
    .then(resp=>resp)
    .catch(error=>{return (error && error.response) ? error.response : error})
  };
  function* deleteOneSchoolYear({payload}) {  
    try {
      const ret = yield call(deleteOneSchoolYearRequest, payload);
      
      if (ret && ret.status==202){
        const { history, index } = payload;
  
        if(history) history.push('/app/my-institutions/school-years')
        yield put(deleteOneSchoolYearSuccess({index}));
      }
      else yield put(deleteOneSchoolYearError({message: (ret.data) ? ret.data.msg : "Network Error"}))
  
    } catch (error) {
      console.log('saga err', error);
      yield put(deleteOneSchoolYearError({error, message: (error) ? error.message : ''}));
    }
  }


//DELETE MANY

const deleteManySchoolYearsRequest = async (payload) => {
  return await axios.delete(`${api.school_years}/delete-many`, { headers: { Authorization: payload.token }, data: payload.data })
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* deleteManySchoolYears({ payload }) {
  try {
    const ret = yield call(deleteManySchoolYearsRequest, payload);

    if (ret && ret.status == 202) {
      const { history, data } = payload;

      if (history) history.push('/app/my-institutions/school-years')
      yield put(deleteManySchoolYearsSuccess({ data }));
    }
    else yield put(deleteManySchoolYearsError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    console.log('saga err', error);
    yield put(deleteManySchoolYearsError({ error, message: (error) ? error.message : '' }));
  }
}



//////// WATCHERS
export function* watchGetList() {
  yield takeEvery(SCOOLYEAR_GET_LIST, getSchoolYearsList);
}

export function* watchGetOne() {
  yield takeEvery(SCOOLYEAR_GET_ONE, getOneSchoolYear);
}

export function* watchEditOne() {
  yield takeEvery(SCOOLYEAR_EDIT_ONE, editOneSchoolYear);
}

export function* watchAddOne() {
  yield takeEvery(SCOOLYEAR_ADD_ONE, addSchoolYear);
}


export function* watchDeleteOne() {
  yield takeEvery(SCHOOLYEAR_DELETE_ONE, deleteOneSchoolYear);
}
export function* watchDeleteMany() {
  yield takeEvery(SCHOOLYEARS_DELETE_MANY,deleteManySchoolYears);
} 
export default function* rootSaga() {
  yield all([
    fork(watchGetList),
    fork(watchGetOne),
    fork(watchEditOne),
    fork(watchAddOne),
    fork(watchDeleteOne),
    fork(watchDeleteMany),

  ]);
}
