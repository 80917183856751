import {
    ELIMINATION_GET_LIST,
    ELIMINATION_GET_LIST_SUCCESS,
    ELIMINATION_GET_LIST_ERROR,


    ELIMINATION_GET_ONE,
    ELIMINATION_GET_ONE_SUCCESS,
    ELIMINATION_GET_ONE_ERROR,

    ELIMINATION_GET_BY_FILTER,
    ELIMINATION_GET_BY_FILTER_SUCCESS,
    ELIMINATION_GET_BY_FILTER_ERROR,

    ELIMINATION_ADD_ONE,
    ELIMINATION_ADD_ONE_SUCCESS,
    ELIMINATION_ADD_ONE_ERROR,
 
    ELIMINATION_EDIT_ONE,
    ELIMINATION_EDIT_ONE_SUCCESS,
    ELIMINATION_EDIT_ONE_ERROR,

    ELIMINATION_DELETE_ONE,
    ELIMINATION_DELETE_ONE_SUCCESS,
    ELIMINATION_DELETE_ONE_ERROR,

    ELIMINATIONS_DELETE_MANY,
    ELIMINATIONS_DELETE_MANY_SUCCESS,
    ELIMINATIONS_DELETE_MANY_ERROR,
    
    ELIMINATION_PRINT,
    ELIMINATION_PRINT_SUCCESS,
    ELIMINATION_PRINT_ERROR
  } from '../actions';

//GET LIST
export const getEliminationsList = (payload) => ({
    type: ELIMINATION_GET_LIST,
    payload
  });
export const getEliminationsListSuccess = (payload) => ({
    type: ELIMINATION_GET_LIST_SUCCESS,
    payload
  });
export const getEliminationsListError = (payload) => ({
    type: ELIMINATION_GET_LIST_ERROR,
    payload
  });


// GET ONE
export const getOneElimination = (payload) => ({
    type: ELIMINATION_GET_ONE,
    payload
  });
export const getOneEliminationSuccess = (payload) => ({
    type: ELIMINATION_GET_ONE_SUCCESS,
    payload
  });
export const getOneEliminationError = (payload) => ({
    type: ELIMINATION_GET_ONE_ERROR,
    payload
  });

// GET  BY FILTER

export const getEliminationsByFilter = (payload) => ({
    type: ELIMINATION_GET_BY_FILTER,
    payload
  });
  export const getEliminationsByFilterSuccess = (payload) => ({
    type: ELIMINATION_GET_BY_FILTER_SUCCESS,
    payload
  });
  export const getEliminationsByFilterError = (payload) => ({
    type: ELIMINATION_GET_BY_FILTER_ERROR,
    payload
  });

// ADD ONE
export const addElimination = (payload) => ({
    type: ELIMINATION_ADD_ONE,
    payload
  });
export const addEliminationSuccess = (payload) => ({
    type: ELIMINATION_ADD_ONE_SUCCESS,
    payload
  });
export const addEliminationError = (payload) => ({
    type: ELIMINATION_ADD_ONE_ERROR,
    payload
  });
  
// EDIT ONE
export const editOneElimination = (payload) => ({
    type: ELIMINATION_EDIT_ONE,
    payload
  });
export const editOneEliminationSuccess = (payload) => ({
    type: ELIMINATION_EDIT_ONE_SUCCESS,
    payload
  });
export const editOneEliminationError = (payload) => ({
    type: ELIMINATION_EDIT_ONE_ERROR,
    payload
  });

  // DELETE ONE
export const deleteOneElimination = (payload) => ({
    type: ELIMINATION_DELETE_ONE,
    payload
  });
export const deleteOneEliminationSuccess = (payload) => ({
    type: ELIMINATION_DELETE_ONE_SUCCESS,
    payload
  });
export const deleteOneEliminationError = (payload) => ({
    type: ELIMINATION_DELETE_ONE_ERROR,
    payload
  });
// DELETE MANY
export const deleteManyEliminations = (payload) => ({
  type: ELIMINATIONS_DELETE_MANY,
  payload
});
export const deleteManyEliminationsSuccess = (payload) => ({
  type: ELIMINATIONS_DELETE_MANY_SUCCESS,
  payload
});
export const deleteManyEliminationsError = (payload) => ({
  type: ELIMINATIONS_DELETE_MANY_ERROR,
  payload
});


// Print Eliminsation
export const printEliminations=(payload)=>({
  type:ELIMINATION_PRINT,
  payload
})

export const printEliminationsSuccess=(payload)=>({
  type:ELIMINATION_PRINT_SUCCESS,
  payload
})

export const printEliminationsError=(payload)=>({
  type:ELIMINATION_PRINT_ERROR,
  payload
})