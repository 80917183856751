import {
    EXAMS_GET_LIST,
    EXAMS_GET_LIST_SUCCESS,
    EXAMS_GET_LIST_ERROR,

    EXAM_GET_ONE,
    EXAM_GET_ONE_SUCCESS,
    EXAM_GET_ONE_ERROR,

    EXAMS_GET_BY_FILTER,
    EXAMS_GET_BY_FILTER_SUCCESS,
    EXAMS_GET_BY_FILTER_ERROR,

    EXAM_ADD_ONE,
    EXAM_ADD_ONE_SUCCESS,
    EXAM_ADD_ONE_ERROR,

    EXAM_EDIT_ONE,
    EXAM_EDIT_ONE_SUCCESS,
    EXAM_EDIT_ONE_ERROR,

    EXAM_DELETE_ONE,
    EXAM_DELETE_ONE_SUCCESS,
    EXAM_DELETE_ONE_ERROR,

    EXAMS_DELETE_MANY,
    EXAMS_DELETE_MANY_SUCCESS,
    EXAMS_DELETE_MANY_ERROR,
  } from '../actions';

import { NotificationManager } from '../../components/common/react-notifications';
import  notificationMessages  from '../../lang/locales/notificationMsg';
const INIT_STATE = {
    exams: [],
    count: 0,
    exam: null,
    error: '',
    loading: false,
  };
  export default (state = INIT_STATE, action) => {
    const messages = notificationMessages()
      switch (action.type) {
            case EXAMS_GET_LIST:
            return { ...state, loading: true, error: '', 
            count: 0
            };
            case EXAMS_GET_LIST_SUCCESS:
           
            return { ...state, loading: false, 
              exams: action.payload.exams ,
              count: action.payload.count || 0
              
            };
           
              case EXAMS_GET_LIST_ERROR:
            return { ...state, loading: false, error: action.payload.message };

 

              // GET ONE
            case EXAM_GET_ONE:
            return { ...state, loading: true, error: ''};
            case EXAM_GET_ONE_SUCCESS:
            return { ...state, loading: false, error: '',
            exam: action.payload.exam
            };
            case EXAM_GET_ONE_ERROR:
            return { ...state, loading: false, error: action.payload.message };
    


           // GET BY FILTER
        case EXAMS_GET_BY_FILTER:
        
            return { ...state, loading: true, error: ''};
        case EXAMS_GET_BY_FILTER_SUCCESS:
            
            return { ...state, loading: false, error: '',
            exams: action.payload.exams || [],
            };
        case EXAMS_GET_BY_FILTER_ERROR:
            
            return { ...state, loading: false, error: action.payload.message };

          // ADD ONE
       case EXAM_ADD_ONE:
        return { ...state, loading: true, error: ''};
        case EXAM_ADD_ONE_SUCCESS:    
        NotificationManager.success(messages["Added"], messages["Success"],3000, null, null, '');
        return { ...state, loading: false, 
            exams: [action.payload.exam, ...state.exams]
           };
        case EXAM_ADD_ONE_ERROR:
          NotificationManager.warning(action.payload.message ||  messages["Unkown Error"], messages["Error"], 3000, null, null, '');
        return { ...state, loading: false, error: action.payload.message };
     


        
       // EDIT ONE
        case EXAM_EDIT_ONE:
            return { ...state, loading: true,edit_sucess:false, error: ''};
        case EXAM_EDIT_ONE_SUCCESS:    
        NotificationManager.success(messages["Edited"], messages["Success"], 3000, null, null, '');
            return { ...state, loading: false,edit_sucess:true, error: '',
            exam: action.payload.exam
            };
        case EXAM_EDIT_ONE_ERROR:
        NotificationManager.warning(action.payload.message ||  messages["Unkown Error"], messages["Error"], 3000, null, null, '');
            return { ...state, loading: false,edit_sucess:false, error: action.payload.message };
    
   

    
    // DELETE ONE
    case EXAM_DELETE_ONE:
    return { ...state, loading: true, error: ''};
    case EXAM_DELETE_ONE_SUCCESS:  
    const exams = state.exams 
    if(action.payload.index!=null && action.payload.index!=undefined)
       exams.splice(action.payload.index, 1)
 
        return { ...state, loading: false, error: '',
        exams
        };
    case EXAM_DELETE_ONE_ERROR:
      NotificationManager.warning(action.payload.message ||  messages["Unkown Error"], messages["Error"], 3000, null, null, '');
    return { ...state, loading: false, error: action.payload.message };

    // DELETE MANY
    case EXAMS_DELETE_MANY:
      return { ...state, loading: true, error: '' };
    case EXAMS_DELETE_MANY_SUCCESS:
      const exams_list = state.exams
     

      if (action.payload.data.ids.length > 0) {
   
        for (let i = 0; i < action.payload.data.ids.length; i++) {

          let index_exam = -1
          for (let j = 0; j < exams_list.length; j++) {
            if (exams_list[j]._id === action.payload.data.ids[i]) {
              index_exam = j; break;
            }
          }
          if (index_exam > -1) exams_list.splice(index_exam, 1)
        }
      }
      return {
        ...state, loading: false, error: '',
        exams: exams_list
      };
    case EXAMS_DELETE_MANY_ERROR:
      NotificationManager.warning(action.payload.message || messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };







    default: return { ...state };

      }
    }