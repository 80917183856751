import {
    SANCTION_TYPES_GET_LIST,
    SANCTION_TYPES_GET_LIST_SUCCESS,
    SANCTION_TYPES_GET_LIST_ERROR,
    
    SANCTION_TYPES_GET_ONE,
    SANCTION_TYPES_GET_ONE_SUCCESS,
    SANCTION_TYPES_GET_ONE_ERROR,

    SANCTION_TYPES_ADD_ONE,
    SANCTION_TYPES_ADD_ONE_SUCCESS,
    SANCTION_TYPES_ADD_ONE_ERROR,

    SANCTION_TYPES_EDIT_ONE,
    SANCTION_TYPES_EDIT_ONE_SUCCESS,
    SANCTION_TYPES_EDIT_ONE_ERROR,

    SANCTION_TYPES_DELETE_ONE,
    SANCTION_TYPES_DELETE_ONE_SUCCESS,
    SANCTION_TYPES_DELETE_ONE_ERROR,
} from '../actions';
import { NotificationManager } from '../../components/common/react-notifications';
import  notificationMessages  from '../../lang/locales/notificationMsg';

const INIT_STATE = {
  sanction_types: [],
  count: 0,
  sanction_type: null,
  error: '',
};
export default (state = INIT_STATE, action) => {
  const messages = notificationMessages()
	switch (action.type) {

        case SANCTION_TYPES_GET_LIST:
        return { ...state, loading: true, error: '', get_sanction_type_success:false,
        count: 0
        };
        case SANCTION_TYPES_GET_LIST_SUCCESS:
        return { ...state, loading: false, get_sanction_type_success:true,
            sanction_types: action.payload.sanction_types || [],
            count: action.payload.count || 0
        };
        case SANCTION_TYPES_GET_LIST_ERROR:
        return { ...state, loading: false,get_sanction_type_success:false, error: action.payload.message };



           // GET ONE
        case SANCTION_TYPES_GET_ONE:
        return { ...state, loading: true, error: ''};
        case SANCTION_TYPES_GET_ONE_SUCCESS:
        
        return { ...state, loading: false, error: '',
        sanction_type: action.payload.sanction_type
        
        };
        case SANCTION_TYPES_GET_ONE_ERROR:
        return { ...state, loading: false, error: action.payload.message };



           // ADD ONE
        case SANCTION_TYPES_ADD_ONE:
        return { ...state, loading: true, error: ''};
        case SANCTION_TYPES_ADD_ONE_SUCCESS:    
        NotificationManager.success(messages["Added"], messages["Success"],3000, null, null, '');
        return { ...state, loading: false, 
            sanction_types: [action.payload.sanction_type, ...state.sanction_types]
        };
        case SANCTION_TYPES_ADD_ONE_ERROR:
          NotificationManager.warning(action.payload.message ||  messages["Unkown Error"], messages["Error"], 3000, null, null, '');
        return { ...state, loading: false, error: action.payload.message };

        
          // EDIT ONE
        case SANCTION_TYPES_EDIT_ONE:
        return { ...state, loading: true,edit_sucess:false, error: ''};
        case SANCTION_TYPES_EDIT_ONE_SUCCESS:    
        NotificationManager.success(messages["Edited"], messages["Success"], 3000, null, null, '');
        return { ...state, loading: false, edit_sucess:true,error: '',
        sanction_type: action.payload.sanction_type
        };
        case SANCTION_TYPES_EDIT_ONE_ERROR:
          NotificationManager.warning(action.payload.message ||  messages["Unkown Error"], messages["Error"], 3000, null, null, '');
        return { ...state, loading: false,edit_sucess:false, error: action.payload.message };

  

          // DELETE ONE
        case SANCTION_TYPES_DELETE_ONE:
        return { ...state, loading: true, error: ''};
        case SANCTION_TYPES_DELETE_ONE_SUCCESS:  
        const sanction_types = state.sanction_types 
        if(action.payload.index!=null && action.payload.index!=undefined)
        sanction_types.splice(action.payload.index, 1)

        return { ...state, loading: false, error: '',
        sanction_types
        };
        case SANCTION_TYPES_DELETE_ONE_ERROR:
          NotificationManager.warning(action.payload.message ||  messages["Unkown Error"], messages["Error"], 3000, null, null, '');
        return { ...state, loading: false, error: action.payload.message };

  
    default: return { ...state };










    }}
