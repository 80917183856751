import { all, call, fork, put, takeEvery } from "redux-saga/effects";
// import { getDateWithFormat } from "../../helpers/Utils";
import { api } from "../../constants/defaultValues";
import axios from "axios";

import { DIPLOMA_GET_LIST, DIPLOMA_ADD_ONE, DIPLOMA_GET_ONE, DIPLOMA_EDIT_ONE, DIPLOMA_DELETE_ONE,DIPLOMAS_DELETE_MANY
} from "../actions";

import {
  getDiplomasListSuccess,
  getDiplomasListError,

  getOneDiplomaSuccess,
  getOneDiplomaError,

  addDiplomaSuccess,
  addDiplomaError,

  editDiplomaSuccess,
  editDiplomaError,
  deleteOneDiplomaSuccess,
  deleteOneDiplomaError,

  deleteManyDiplomasSuccess,
  deleteManyDiplomasError,
} from "./actions";


  //GET LIST
const getDiplomasListRequest = async (payload) => {
  return await axios.get(api.diplomas+`/list/${payload.inst_id}/${payload.offset}/${payload.limit}`, { headers: {Authorization: payload.token} })
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* getDiplomasList({payload}) {
  try {
    const ret = yield call(getDiplomasListRequest, payload);
    
    if (ret && ret.status==200)
    yield put(getDiplomasListSuccess({diplomas: ret.data.diplomas || [], count: ret.data.count || 0}));
    
    else yield put(getDiplomasListError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
    yield put(getDiplomasListError({error, message: (error) ? error.message : ''}));
  }
}



//GET ONE
const getOneDiplomasRequest = async (payload) => {
  return await axios.get(api.diplomas+`/get-one/${payload._id}`, { headers: {Authorization: payload.token} })
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* getOneDiploma({payload}) {  
  try {
    const ret = yield call(getOneDiplomasRequest, payload);

    if (ret && ret.status==200)
    yield put(getOneDiplomaSuccess({diploma: ret.data.diploma}));
    
    else yield put(getOneDiplomaError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
    yield put(getOneDiplomaError({error, message: (error) ? error.message : ''}));
  }
}




  //ADD ONE
const addDiplomaRequest = async (payload) => {
  const frm = new FormData();
  frm.append('name', payload.data.name)
  frm.append('desc', payload.data.desc);
  if(payload.data.model) frm.append('model', payload.data.model)
  frm.append('inst_id', payload.data.inst_id)
  
  return await axios.post(api.diplomas, frm, { headers: {
    Authorization: payload.token, //'content-type': 'multipart/form-data'
  } })
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* addDiploma({payload}) {  
  try {
    const ret = yield call(addDiplomaRequest, payload);
    
    if (ret && ret.status==201){
      const { history } = payload;

      if(history) history.push('/app/my-institutions/diplomas')
      yield put(addDiplomaSuccess({diploma: ret.data.diploma}));
    }
    else yield put(addDiplomaError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
    console.log('saga err', error);
    yield put(addDiplomaError({error, message: (error) ? error.message : ''}));
  }
}





  //EDIT ONE
const editOneDiplomaRequest = async (payload) => {
  const frm = new FormData();
  frm.append('name', payload.data.name)
  frm.append('desc', payload.data.desc);
  if(payload.data.model) frm.append('model', payload.data.model)
  frm.append('inst_id', payload.data.inst_id)

  return await axios.patch(`${api.diplomas}/edit-one/${payload._id}`, frm, { headers: {Authorization: payload.token} })
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* editOneDiploma({payload}) {  
  try {
    const ret = yield call(editOneDiplomaRequest, payload);
    
    if (ret && ret.status==200){
      const { history } = payload;      

      if(history) history.push('/app/my-institutions/diplomas')
      yield put(editDiplomaSuccess({diploma: ret.data.diploma}));
    }
    else yield put(editDiplomaError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
    console.log('saga err', error);
    yield put(editDiplomaError({error, message: (error) ? error.message : ''}));
  }
}


  // DELETE ONE
const deleteOneDiplomaRequest = async (payload) => {
  return await axios.delete(`${api.diplomas}/delete-one/${payload._id}`, { headers: {Authorization: payload.token} })
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* deleteOneDiploma({payload}) {  
  try {
    const ret = yield call(deleteOneDiplomaRequest, payload);
    
    if (ret && ret.status==202){
      const { history, index } = payload;

      if(history) history.push('/app/my-institutions/diplomas')
      yield put(deleteOneDiplomaSuccess({index}));
    }
    else yield put(deleteOneDiplomaError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
    console.log('saga err', error);
    yield put(deleteOneDiplomaError({error, message: (error) ? error.message : ''}));
  }
}

//DELETE MANY

const deleteManyDiplomasRequest = async (payload) => {
  return await axios.delete(`${api.diplomas}/delete-many`, { headers: { Authorization: payload.token }, data: payload.data })
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* deleteManyDiplomas({ payload }) {
  try {
    const ret = yield call(deleteManyDiplomasRequest, payload);

    if (ret && ret.status == 202) {
      const { history, data } = payload;

      if (history) history.push('/app/my-institutions/diplomas')
      yield put(deleteManyDiplomasSuccess({ data }));
    }
    else yield put(deleteManyDiplomasError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    console.log('saga err', error);
    yield put(deleteManyDiplomasError({ error, message: (error) ? error.message : '' }));
  }
}
//////// WATCHERS
export function* watchGetList() {
  yield takeEvery(DIPLOMA_GET_LIST, getDiplomasList);
}

export function* watchGetOne() {
  yield takeEvery(DIPLOMA_GET_ONE, getOneDiploma);
}

export function* watchEditOne() {
  yield takeEvery(DIPLOMA_EDIT_ONE, editOneDiploma);
}

export function* watchAddOne() {
  yield takeEvery(DIPLOMA_ADD_ONE, addDiploma);
}

export function* watchDeleteOne() {
  yield takeEvery(DIPLOMA_DELETE_ONE, deleteOneDiploma);
}
export function* watchDeleteMany() {
  yield takeEvery(DIPLOMAS_DELETE_MANY,deleteManyDiplomas);
}
export default function* rootSaga() {
  yield all([
    fork(watchGetList),
    fork(watchGetOne),
    fork(watchEditOne),
    fork(watchAddOne),
    fork(watchDeleteOne),
    fork(watchDeleteMany)

  ]);
}
