import { combineReducers } from 'redux';
import settings from './settings/reducer';
import menu from './menu/reducer';
import authUser from './auth/reducer';
import institution from "./institution/reducer";
import schoolYears from "./school-years/reducer";
import periodTypes from "./period-types/reducer";
import educationLevels from "./education-levels/reducer";
import schoolLevels from "./school-levels/reducer";
import Diplomas from "./diplomas/reducer";
import Specialities from "./specialities/reducer";
import Subjects from "./subjects/reducer";
import Partners from "./partners/reducer";
import Contacts from "./contacts/reducer";
import ContactMessage from "./contact-msg/reducer"

import Teachers from "./teachers/reducer";
import Students from "./students/reducer";
import Parents from "./parents/reducer";
import Skills from "./skills/reducer";
import Classes from "./classes/reducer";
import Modules from "./modules/reducer";
import Rooms from "./rooms/reducer";
import Timetables from "./timetables/reducer";
import Homeworks from "./homeworks/reducer";
import Sanctions from "./sanctions/reducer";
import SanctionTypes from "./sanction-types/reducer";
import Exemptions from "./exemptions/reducer";
import Absences from "./absences/reducer";
import Eliminations from "./eliminations/reducer";

import Sessions from "./sessions/reducer";
import ExamTypes from "./exam-types/reducer";
import ExamPeriods from "./exam-periods/reducer";
import ExamSessions from "./exam-sessions/reducer";
import ExamNotes from "./exam-notes/reducer";
import ExamModules from "./exam-modules/reducer";
import ExamResults from "./exam-results/reducer";
import Exams from "./exams/reducer";
/*billing*/
import Taxes from "./billing.redux/taxes/reducer";
import Fees from "./billing.redux/fees/reducer";
import FeeCompositions from "./billing.redux/fee-compositions/reducer";
import Payments from "./billing.redux/payments/reducer";
import Bills from "./billing.redux/bills/reducer";
/*community*/
import Appointements from "./appointements/reducer";
import Sms from "./sms/reducer";
import ParentsSchool from "./parents-school/reducer"
/*cantine*/
import MealTypes from "./meal-types/reducer";
import Meals from "./meals/reducer";
import CanteenMenus from "./canteen-menus/reducer";
import ReservationTypes from "./reservation-types/reducer";
import CanteenReservations from "./canteen-reservations/reducer";
import Posts from "./posts/reducer";
import Events from "./events/reducer";
import Invitations from "./invitations/reducer";
import Jobs from "./jobs/reducer";
import Trainings from "./trainings/reducer";
import Courses from "./courses/reducer";
import ConfigSubjects from "./configs/subjects/reducer" ;
import ConfigLevels from "./configs/education-levels/reducer";
import Tutorings from "./tutorings/reducer";
import Groups from "./groups/reducer";

const reducers = combineReducers({
  menu,
  settings,
  authUser,
  institution,
  schoolYears,
  periodTypes,
  educationLevels,
  schoolLevels,
  Diplomas,
  Specialities,
  Subjects,
  Partners,
  Contacts,
  Teachers,
  Students,
  Parents,
  Skills,
  Classes,
  Modules,
  Rooms,
  Timetables,
  Homeworks,
  Sanctions,
  SanctionTypes,
  Exemptions,
  Absences,
  Eliminations,
  Sessions,
  ExamTypes,
  ExamPeriods,
  ExamSessions,
  ExamNotes,
  ExamModules,
  ExamResults,
  Exams,
  /*billing*/
  Taxes,
  Fees,
  FeeCompositions,
  Payments,
  Bills,
  /*community*/
  Appointements,
  Sms,
  ParentsSchool,
  /*cantine*/
  MealTypes,
  Meals,
  CanteenMenus,
  ReservationTypes,
  Posts,
  CanteenReservations,
  Events,
  Invitations,
  Jobs,
  Trainings,
  Courses,
  ConfigSubjects,
  ConfigLevels,
  Tutorings,
  Groups,
  ContactMessage,
});

export default reducers;