import { all, call, fork, put, takeEvery } from "redux-saga/effects";
// import { getDateWithFormat } from "../../helpers/Utils";
import { api } from "../../constants/defaultValues";
import axios from "axios";

import { TIMETABLE_GET_LIST, TIMETABLE_ADD_ONE, TIMETABLE_GET_ONE,TIMETABLE_GET_BY_FILTER, TIMETABLE_EDIT_ONE, TIMETABLE_DELETE_ONE,TIMETABLES_DELETE_MANY
} from "../actions";

import {
  getTimetablesListSuccess,
  getTimetablesListError,

  getOneTimetableSuccess,
  getOneTimetableError,

  getTimetableByFilterSuccess,
  getTimetableByFilterError,

  addTimetableSuccess,
  addTimetableError,

  editTimetableSuccess,
  editTimetableError,

  deleteOneTimetableSuccess,
  deleteOneTimetableError,

  deleteManyTimetablesSuccess,
  deleteManyTimetablesError,
} from "./actions";


  //GET LIST
const getTimetablesListRequest = async (payload) => {
  return await axios.get(api.timetables+`/list/${payload.inst_id}/${payload.offset}/${payload.limit}`, { headers: {Authorization: payload.token} })
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* getTimetablesList({payload}) {
  try {
    const ret = yield call(getTimetablesListRequest, payload);
    if (ret && ret.status==200)
    yield put(getTimetablesListSuccess({timetables: ret.data.timetables || [], count: ret.data.count || 0}));
    
    else yield put(getTimetablesListError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
    yield put(getTimetablesListError({error, message: (error) ? error.message : ''}));
  }
}



//GET ONE
const getOneTimetablesRequest = async (payload) => {
  return await axios.get(api.timetables+`/get-one/${payload._id}`, { headers: {Authorization: payload.token} })
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* getOneTimetable({payload}) {  
  try {
    const ret = yield call(getOneTimetablesRequest, payload);
    //console.log("getOneTimetable : ",ret);
    
    if (ret && ret.status==200)
    yield put(getOneTimetableSuccess({timetable: ret.data.timetable}));
    
    else yield put(getOneTimetableError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
    yield put(getOneTimetableError({error, message: (error) ? error.message : ''}));
  }
}

//GET TIMETABLE BY FILTER
const getTimetableByFilterRequest = async (payload) => {
  console.log('payload',payload)
  return await axios.post(api.timetables+`/filters/${payload.offset}/${payload.limit}`,payload.filter, { headers: {Authorization: payload.token} })
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* getTimetableByFilter({payload}) {
  try {
    const ret = yield call(getTimetableByFilterRequest, payload);
    

    if (ret && ret.status==200)
    yield put(getTimetableByFilterSuccess({timetables: ret.data.timetables}));
    
    else yield put(getTimetableByFilterError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
    yield put(getTimetableByFilterError({error, message: (error) ? error.message : ''}));
  }
}




  //ADD ONE
const addTimetableRequest = async (payload) => {
  return await axios.post(api.timetables, payload.data, { headers: {Authorization: payload.token} })
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* addTimetable({payload}) {  
  try {
    const ret = yield call(addTimetableRequest, payload);
    
    if (ret && ret.status==201){
      const { history } = payload;

      if(history) history.push('/app/timetable/by-class')
      yield put(addTimetableSuccess({timetable: ret.data.timeTable}));
    }
    else yield put(addTimetableError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
    console.log('saga err', error);
    yield put(addTimetableError({error, message: (error) ? error.message : ''}));
  }
}





  //EDIT ONE
const editOneTimetableRequest = async (payload) => {
  return await axios.patch(`${api.timetables}/edit-one/${payload._id}`, payload.data, { headers: {Authorization: payload.token} })
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* editOneTimetable({payload}) {  
  try {
    const ret = yield call(editOneTimetableRequest, payload);
    
    if (ret && ret.status==200){
      const { history } = payload;

      if(history) history.push('/app/my-institutions/timetables')
      yield put(editTimetableSuccess({timetable: ret.data.timetable}));
    }
    else yield put(editTimetableError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
    console.log('saga err', error);
    yield put(editTimetableError({error, message: (error) ? error.message : ''}));
  }
}


  // DELETE ONE
const deleteOneTimetableRequest = async (payload) => {
  return await axios.delete(`${api.timetables}/delete-one/${payload._id}`, { headers: {Authorization: payload.token} })
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* deleteOneTimetable({payload}) {  
  try {
    const ret = yield call(deleteOneTimetableRequest, payload);
    
    if (ret && ret.status==202){
      const { history, index } = payload;

      if(history) history.push('/app/my-institutions/timetables')
      yield put(deleteOneTimetableSuccess({index}));
    }
    else yield put(deleteOneTimetableError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
    console.log('saga err', error);
    yield put(deleteOneTimetableError({error, message: (error) ? error.message : ''}));
  }
}

const deleteManyTimetablesRequest = async (payload) => {
  return await axios.delete(`${api.timetables}/delete-many`, { headers: { Authorization: payload.token }, data: payload.data })
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* deleteManyTimetables({ payload }) {
  try {
    const ret = yield call(deleteManyTimetablesRequest, payload);

    if (ret && ret.status == 202) {
      const { history, data } = payload;

      if (history) history.push('/app/my-institutions/timetables')
      yield put(deleteManyTimetablesSuccess({ data }));
    }
    else yield put(deleteManyTimetablesError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    console.log('saga err', error);
    yield put(deleteManyTimetablesError({ error, message: (error) ? error.message : '' }));
  }
}

//////// WATCHERS
export function* watchGetList() {
  yield takeEvery(TIMETABLE_GET_LIST, getTimetablesList);
}

export function* watchGetOne() {
  yield takeEvery(TIMETABLE_GET_ONE, getOneTimetable);
}
export function* watchGetFilter() {
  yield takeEvery(TIMETABLE_GET_BY_FILTER, getTimetableByFilter);
}
export function* watchEditOne() {
  yield takeEvery(TIMETABLE_EDIT_ONE, editOneTimetable);
}

export function* watchAddOne() {
  yield takeEvery(TIMETABLE_ADD_ONE, addTimetable);
}

export function* watchDeleteOne() {
  yield takeEvery(TIMETABLE_DELETE_ONE, deleteOneTimetable);
}
export function* watchDeleteMany() {
  yield takeEvery(TIMETABLES_DELETE_MANY,deleteManyTimetables);
}
export default function* rootSaga() {
  yield all([
    fork(watchGetList),
    fork(watchGetOne),
    fork(watchGetFilter),
    fork(watchEditOne),
    fork(watchAddOne),
    fork(watchDeleteOne),
    fork(watchDeleteMany)

  ]);
}
