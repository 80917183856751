import { all, call, fork, put, takeEvery } from "redux-saga/effects";
// import { getDateWithFormat } from "../../helpers/Utils";
import { api } from "../../constants/defaultValues";
import axios from "axios";

import {MEALS_GET_LIST,MEAL_GET_ONE,MEAL_ADD_ONE,MEAL_EDIT_ONE,MEAL_DELETE_ONE
} from "../actions";
import {
    getMealsListSuccess,
    getMealsListError,

    getOneMealSuccess,
    getOneMealError,

    addMealSuccess,
    addMealError,

    editMealSuccess,
    editMealError,

    deleteOneMealSuccess,
    deleteOneMealError,
    
} from "./actions";

//GET LIST
const getMealsListRequest = async (payload) => {
    return await axios.get(api.meals+`/list/${payload.inst_id}/${payload.offset}/${payload.limit}`, { headers: {Authorization: payload.token} })
    .then(resp=>resp)
    .catch(error=>{return (error && error.response) ? error.response : error})
  };
function* getMealsList({payload}) {
    try {
      const ret = yield call(getMealsListRequest, payload);
      
      if (ret && ret.status==200)
      yield put(getMealsListSuccess({meals: ret.data.meals || [], count: ret.data.count || 0}));
      
      else yield put(getMealsListError({message: (ret.data) ? ret.data.msg : "Network Error"}))
  
    } catch (error) {
      yield put(getMealsListError({error, message: (error) ? error.message : ''}));
    }
  }

//GET ONE
const getOneMealRequest = async (payload) => {
    return await axios.get(api.meals+`/get-one/${payload._id}`, { headers: {Authorization: payload.token} })
    .then(resp=>resp)
    .catch(error=>{return (error && error.response) ? error.response : error})
  };
function* getOneMeal({payload}) {  
    try {
      const ret = yield call(getOneMealRequest, payload);
  
      if (ret && ret.status==200)
      yield put(getOneMealSuccess({meal: ret.data.meal}));
      
      else yield put(getOneMealError({message: (ret.data) ? ret.data.msg : "Network Error"}))
  
    } catch (error) {
      yield put(getOneMealError({error, message: (error) ? error.message : ''}));
    }
  }

  //ADD ONE
const addMealRequest = async (payload) => {
    return await axios.post(api.meals, payload.data, { headers: {Authorization: payload.token} })
    .then(resp=>resp)
    .catch(error=>{return (error && error.response) ? error.response : error})
  };
function* addMeal({payload}) {  
    try {
      const ret = yield call(addMealRequest, payload);
      
      if (ret && ret.status==201){
        const { history } = payload;
  
        if(history) history.push('/app/canteen/meals')
        yield put(addMealSuccess({meal: ret.data.meal}));
      }
      else yield put(addMealError({message: (ret.data) ? ret.data.msg : "Network Error"}))
  
    } catch (error) {
      console.log('saga err', error);
      yield put(addMealError({error, message: (error) ? error.message : ''}));
    }
  }

 //EDIT ONE
const editMealRequest = async (payload) => {
    return await axios.patch(`${api.meals}/edit-one/${payload._id}`, payload.data, { headers: {Authorization: payload.token} })
    .then(resp=>resp)
    .catch(error=>{return (error && error.response) ? error.response : error})
  };
function* editMeal({payload}) {  
    try {
      const ret = yield call(editMealRequest, payload);
      
      if (ret && ret.status==200){
        const { history } = payload;
  
        if(history) history.push('/app/canteen/meals')
        yield put(editMealSuccess({meal: ret.data.meal}));
      }
      else yield put(editMealError({message: (ret.data) ? ret.data.msg : "Network Error"}))
  
    } catch (error) {
      console.log('saga err', error);
      yield put(editMealError({error, message: (error) ? error.message : ''}));
    }
  }

  // DELETE ONE
const deleteOneMealRequest = async (payload) => {
    return await axios.delete(`${api.meals}/delete-one/${payload._id}`, { headers: {Authorization: payload.token} })
    .then(resp=>resp)
    .catch(error=>{return (error && error.response) ? error.response : error})
  };
function* deleteOneMeal({payload}) {  
    try {
      const ret = yield call(deleteOneMealRequest, payload);
      
      if (ret && ret.status==202){
        const { history, index } = payload;
  
        if(history) history.push('/app/canteen/meals')
        yield put(deleteOneMealSuccess({index}));
      }
      else yield put(deleteOneMealError({message: (ret.data) ? ret.data.msg : "Network Error"}))
  
    } catch (error) {
      console.log('saga err', error);
      yield put(deleteOneMealError({error, message: (error) ? error.message : ''}));
    }
  }


//////// WATCHERS
export function* watchGetList() {
    yield takeEvery(MEALS_GET_LIST, getMealsList);
  }
  
export function* watchGetOne() {
    yield takeEvery(MEAL_GET_ONE, getOneMeal);
  }
  
export function* watchEditOne() {
    yield takeEvery(MEAL_EDIT_ONE, editMeal);
  }
  
export function* watchAddOne() {
    yield takeEvery(MEAL_ADD_ONE, addMeal);
  }
  
export function* watchDeleteOne() {
    yield takeEvery(MEAL_DELETE_ONE, deleteOneMeal);
  }
  
export default function* rootSaga() {
    yield all([
      fork(watchGetList),
      fork(watchGetOne),
      fork(watchEditOne),
      fork(watchAddOne),
      fork(watchDeleteOne)
    ]);
  }