import {
	SCOOLYEAR_GET_LIST,
	SCOOLYEAR_GET_LIST_SUCCESS,
  SCOOLYEAR_GET_LIST_ERROR,

  SCOOLYEAR_GET_ONE,
	SCOOLYEAR_GET_ONE_SUCCESS,
  SCOOLYEAR_GET_ONE_ERROR,

  SCOOLYEAR_ADD_ONE,
  SCOOLYEAR_ADD_ONE_SUCCESS,
  SCOOLYEAR_ADD_ONE_ERROR,

  SCOOLYEAR_EDIT_ONE,
  SCOOLYEAR_EDIT_ONE_SUCCESS,
  SCOOLYEAR_EDIT_ONE_ERROR,

  SCHOOLYEAR_DELETE_ONE,
  SCHOOLYEAR_DELETE_ONE_SUCCESS,
  SCHOOLYEAR_DELETE_ONE_ERROR,

  SCHOOLYEARS_DELETE_MANY,
  SCHOOLYEARS_DELETE_MANY_SUCCESS,
  SCHOOLYEARS_DELETE_MANY_ERROR
} from '../actions';

import { NotificationManager } from '../../components/common/react-notifications';
import  notificationMessages  from '../../lang/locales/notificationMsg'

const INIT_STATE = {
	school_years: [],
  count: 0,
  school_year: null,
  error: '',
};

export default (state = INIT_STATE, action) => {
  const messages = notificationMessages()
	switch (action.type) {
		case SCOOLYEAR_GET_LIST:
      return { ...state, loading: true, error: '', 
      count: 0
    };
    case SCOOLYEAR_GET_LIST_SUCCESS:
      return { ...state, loading: false, 
        school_years: action.payload.school_years || [],
        count: action.payload.count || 0
      };
		case SCOOLYEAR_GET_LIST_ERROR:
      return { ...state, loading: false, error: action.payload.message };






      // GET ONE
    case SCOOLYEAR_GET_ONE:
      return { ...state, loading: true, error: ''};
    case SCOOLYEAR_GET_ONE_SUCCESS:
      return { ...state, loading: false, error: '',
        school_year: action.payload.school_year
      };
		case SCOOLYEAR_GET_ONE_ERROR:
      return { ...state, loading: false, error: action.payload.message };









      // ADD ONE
    case SCOOLYEAR_ADD_ONE:
      return { ...state, loading: true, error: ''};
    case SCOOLYEAR_ADD_ONE_SUCCESS:    
      NotificationManager.success(messages["Added"], messages["Success"],3000, null, null, '');
      return { ...state, loading: false, 
        school_years: [action.payload.school_year, ...state.school_years]
      };
    case SCOOLYEAR_ADD_ONE_ERROR:
      NotificationManager.warning(action.payload.message ||  messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };





    
      // EDIT ONE
    case SCOOLYEAR_EDIT_ONE:
      return { ...state, loading: true, error: ''};
    case SCOOLYEAR_EDIT_ONE_SUCCESS:    
      NotificationManager.success(messages["Edited"], messages["Success"], 3000, null, null, '');
      return { ...state, loading: false, error: '',
        school_year: action.payload.school_year
      };
    case SCOOLYEAR_EDIT_ONE_ERROR:
      NotificationManager.warning(action.payload.message ||  messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };



      // DELETE ONE
    case SCHOOLYEAR_DELETE_ONE:
      return { ...state, loading: true, error: ''};
    case SCHOOLYEAR_DELETE_ONE_SUCCESS:  
      const school_years = state.school_years 
      if(action.payload.index!=null && action.payload.index!=undefined)
      school_years.splice(action.payload.index, 1)

      return { ...state, loading: false, error: '',
        school_years
      };
    case SCHOOLYEAR_DELETE_ONE_ERROR:
      NotificationManager.warning(action.payload.message ||  messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };



      // DELETE MANY
        case SCHOOLYEARS_DELETE_MANY:
          return { ...state, loading: true, error: '' };
        case SCHOOLYEARS_DELETE_MANY_SUCCESS:
          const school_years_list = state.school_years
         
    
          if (action.payload.data.ids.length > 0) {
       
            for (let i = 0; i < action.payload.data.ids.length; i++) {
    
              let index_school_year = -1
              for (let j = 0; j < school_years_list.length; j++) {
                if (school_years_list[j]._id === action.payload.data.ids[i]) {
                  index_school_year = j; break;
                }
              }
              if (index_school_year > -1) school_years_list.splice(index_school_year, 1)
            }
          }
          return {
            ...state, loading: false, error: '',
            school_years: school_years_list
          };
        case SCHOOLYEARS_DELETE_MANY_ERROR:
          NotificationManager.warning(action.payload.message || messages["Unkown Error"], messages["Error"], 3000, null, null, '');
          return { ...state, loading: false, error: action.payload.message };




















		default: return { ...state };
	}
}
