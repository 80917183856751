import { all, call, fork, put, takeEvery } from "redux-saga/effects";
// import { getDateWithFormat } from "../../helpers/Utils";
import { api } from "../../constants/defaultValues";
import axios from "axios";

import { SCHOOLLEVEL_GET_LIST, SCHOOLLEVEL_ADD_ONE, SCHOOLLEVEL_GET_ONE,SCHOOLLEVEL_GET_BY_FILTER, SCHOOLLEVEL_EDIT_ONE, SCHOOLLEVEL_DELETE_ONE,SCHOOLLEVELS_DELETE_MANY
} from "../actions";

import {
  getSchoolLevelsListSuccess,
  getSchoolLevelsListError,

  getOneSchoolLevelSuccess,
  getOneSchoolLevelError,


  getSchoolLevelByFilterSuccess,
  getSchoolLevelByFilterError,

  addSchoolLevelSuccess,
  addSchoolLevelError,
  editSchoolLevelSuccess,
  editSchoolLevelError,
  deleteOneSchoolLevelSuccess,
  deleteOneSchoolLevelError,

  deleteManySchoolLevelsSuccess,
  deleteManySchoolLevelsError,
} from "./actions";


  //GET LIST
const getSchoolLevelsListRequest = async (payload) => {
  return await axios.get(api.school_levels+`/list/${payload.inst_id}/${payload.offset}/${payload.limit}`, { headers: {Authorization: payload.token} })
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* getSchoolLevelsList({payload}) {
  try {
    const ret = yield call(getSchoolLevelsListRequest, payload);
    
    if (ret && ret.status==200)
    yield put(getSchoolLevelsListSuccess({school_levels: ret.data.school_levels || [], count: ret.data.count || 0}));
    
    else yield put(getSchoolLevelsListError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
    yield put(getSchoolLevelsListError({error, message: (error) ? error.message : ''}));
  }
}



//GET ONE
const getOneSchoolLevelsRequest = async (payload) => {
  return await axios.get(api.school_levels+`/get-one/${payload._id}`, { headers: {Authorization: payload.token} })
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* getOneSchoolLevel({payload}) {  
  try {
    const ret = yield call(getOneSchoolLevelsRequest, payload);

    if (ret && ret.status==200)
    yield put(getOneSchoolLevelSuccess({school_level: ret.data.school_level}));
    
    else yield put(getOneSchoolLevelError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
    yield put(getOneSchoolLevelError({error, message: (error) ? error.message : ''}));
  }
}

//GET SCHOOL LEVEL  BY FILTER
const getSchoolLevelByFilterRequest = async (payload) => {
  return await axios.post(api.school_levels+`/filter/${payload.offset}/${payload.limit}`,payload.filter, { headers: {Authorization: payload.token} })
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* getSchoolLevelByFilter({payload}) {
  try {
    const ret = yield call(getSchoolLevelByFilterRequest, payload);
    

    if (ret && ret.status==200)
    yield put(getSchoolLevelByFilterSuccess({school_levels: ret.data.school_levels,count: ret.data.count || 0}));
    
    else yield put(getSchoolLevelByFilterError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
    yield put(getSchoolLevelByFilterError({error, message: (error) ? error.message : ''}));
  }
}



  //ADD ONE
const addSchoolLevelRequest = async (payload) => {
  return await axios.post(api.school_levels, payload.data, { headers: {Authorization: payload.token} })
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* addSchoolLevel({payload}) {  
  try {
    const ret = yield call(addSchoolLevelRequest, payload);
    
    if (ret && ret.status==201){
      const { history } = payload;

      if(history) history.push('/app/my-institutions/school-levels')
      yield put(addSchoolLevelSuccess({school_level: ret.data.school_level}));
    }
    else yield put(addSchoolLevelError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
    console.log('saga err', error);
    yield put(addSchoolLevelError({error, message: (error) ? error.message : ''}));
  }
}





  //EDIT ONE
const editOneSchoolLevelRequest = async (payload) => {
  return await axios.patch(`${api.school_levels}/edit-one/${payload._id}`, payload.data, { headers: {Authorization: payload.token} })
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* editOneSchoolLevel({payload}) {  
  try {
    const ret = yield call(editOneSchoolLevelRequest, payload);
    
    if (ret && ret.status==200){
      const { history } = payload;

      if(history) history.push('/app/my-institutions/school-levels')
      yield put(editSchoolLevelSuccess({school_level: ret.data.school_level}));
    }
    else yield put(editSchoolLevelError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
    console.log('saga err', error);
    yield put(editSchoolLevelError({error, message: (error) ? error.message : ''}));
  }
}


  // DELETE ONE
const deleteOneSchoolLevelRequest = async (payload) => {
  return await axios.delete(`${api.school_levels}/delete-one/${payload._id}`, { headers: {Authorization: payload.token} })
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* deleteOneSchoolLevel({payload}) {  
  try {
    const ret = yield call(deleteOneSchoolLevelRequest, payload);
    
    if (ret && ret.status==202){
      const { history, index } = payload;

      if(history) history.push('/app/my-institutions/school-levels')
      yield put(deleteOneSchoolLevelSuccess({index}));
    }
    else yield put(deleteOneSchoolLevelError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
    console.log('saga err', error);
    yield put(deleteOneSchoolLevelError({error, message: (error) ? error.message : ''}));
  }
}

//DELETE MANY

const deleteManySchoolLevelsRequest = async (payload) => {
  return await axios.delete(`${api.school_levels}/delete-many`, { headers: { Authorization: payload.token }, data: payload.data })
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* deleteManySchoolLevels({ payload }) {
  try {
    const ret = yield call(deleteManySchoolLevelsRequest, payload);

    if (ret && ret.status == 202) {
      const { history, data } = payload;

      if (history) history.push('/app/my-institutions/school-levels')
      yield put(deleteManySchoolLevelsSuccess({ data }));
    }
    else yield put(deleteManySchoolLevelsError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    console.log('saga err', error);
    yield put(deleteManySchoolLevelsError({ error, message: (error) ? error.message : '' }));
  }
}
//////// WATCHERS
export function* watchGetList() {
  yield takeEvery(SCHOOLLEVEL_GET_LIST, getSchoolLevelsList);
}

export function* watchGetOne() {
  yield takeEvery(SCHOOLLEVEL_GET_ONE, getOneSchoolLevel);
}
export function* watchGetFilter() {
  yield takeEvery(SCHOOLLEVEL_GET_BY_FILTER, getSchoolLevelByFilter);
}
export function* watchEditOne() {
  yield takeEvery(SCHOOLLEVEL_EDIT_ONE, editOneSchoolLevel);
}

export function* watchAddOne() {
  yield takeEvery(SCHOOLLEVEL_ADD_ONE, addSchoolLevel);
}

export function* watchDeleteOne() {
  yield takeEvery(SCHOOLLEVEL_DELETE_ONE, deleteOneSchoolLevel);
}
export function* watchDeleteMany() {
  yield takeEvery(SCHOOLLEVELS_DELETE_MANY,deleteManySchoolLevels);
}
export default function* rootSaga() {
  yield all([
    fork(watchGetList),
    fork(watchGetOne),
    fork(watchGetFilter),
    fork(watchEditOne),
    fork(watchAddOne),
    fork(watchDeleteOne),
    fork(watchDeleteMany)

  ]);
}
