import {
    EXAMTYPES_GET_LIST,
    EXAMTYPES_GET_LIST_SUCCESS,
    EXAMTYPES_GET_LIST_ERROR,

    EXAMTYPES_GET_ONE,
    EXAMTYPES_GET_ONE_SUCCESS,
    EXAMTYPES_GET_ONE_ERROR,
  
    EXAMTYPES_GET_BY_FILTER,
    EXAMTYPES_GET_BY_FILTER_SUCCESS,
    EXAMTYPES_GET_BY_FILTER_ERROR,
    
    EXAMTYPES_ADD_ONE,
    EXAMTYPES_ADD_ONE_SUCCESS,
    EXAMTYPES_ADD_ONE_ERROR,
    
    EXAMTYPES_EDIT_ONE,
    EXAMTYPES_EDIT_ONE_SUCCESS,
    EXAMTYPES_EDIT_ONE_ERROR,

    EXAMTYPES_DELETE_ONE,
    EXAMTYPES_DELETE_ONE_SUCCESS,
    EXAMTYPES_DELETE_ONE_ERROR,
    
    EXAMTYPES_DELETE_MANY,
    EXAMTYPES_DELETE_MANY_SUCCESS,
    EXAMTYPES_DELETE_MANY_ERROR
  } from '../actions';
  
// GET LIST
export const getExamTypesList = (payload) => ({
    type: EXAMTYPES_GET_LIST,
    payload
  });
export const getExamTypesListSuccess = (payload) => ({
    type: EXAMTYPES_GET_LIST_SUCCESS,
    payload
  });
export const getExamTypesListError = (payload) => ({
    type: EXAMTYPES_GET_LIST_ERROR,
    payload
  });
  

  // GET One
export const getOneExamTypes = (payload) => ({
    type: EXAMTYPES_GET_ONE,
    payload
  });
export const getOneExamTypesSuccess = (payload) => ({
    type: EXAMTYPES_GET_ONE_SUCCESS,
    payload
  });
export const getOneExamTypesError = (payload) => ({
    type: EXAMTYPES_GET_ONE_ERROR,
    payload
  });
 
  


// GET EXAMTYPES BY FILTER

export const getExamTypesByFilter = (payload) => ({
  type: EXAMTYPES_GET_BY_FILTER,
  payload
});
export const getExamTypesByFilterSuccess = (payload) => ({
  type: EXAMTYPES_GET_BY_FILTER_SUCCESS,
  payload
});
export const getExamTypesByFilterError = (payload) => ({
  type: EXAMTYPES_GET_BY_FILTER_ERROR,
  payload
});

  // ADD ONE
export const addExamTypes = (payload) => ({
    type: EXAMTYPES_ADD_ONE,
    payload
  });
export const addExamTypesSuccess = (payload) => ({
    type: EXAMTYPES_ADD_ONE_SUCCESS,
    payload
  });
export const addExamTypesError = (payload) => ({
    type: EXAMTYPES_ADD_ONE_ERROR,
    payload
  });
  
  // EDIT ONE
export const editOneExamTypes = (payload) => ({
    type: EXAMTYPES_EDIT_ONE,
    payload
  });
export const editOneExamTypesSuccess = (payload) => ({
    type: EXAMTYPES_EDIT_ONE_SUCCESS,
    payload
  });
export const editOneExamTypesError = (payload) => ({
    type: EXAMTYPES_EDIT_ONE_ERROR,
    payload
  });
  

  // DELETE ONE
export const deleteOneExamTypes = (payload) => ({
    type: EXAMTYPES_DELETE_ONE,
    payload
  });
export const deleteOneExamTypesSuccess = (payload) => ({
    type: EXAMTYPES_DELETE_ONE_SUCCESS,
    payload
  });
export const deleteOneExamTypesError = (payload) => ({
    type: EXAMTYPES_DELETE_ONE_ERROR,
    payload
  });


// DELETE MANY
export const deleteManyExamTypes = (payload) => ({
  type: EXAMTYPES_DELETE_MANY,
  payload
});
export const deleteManyExamTypesSuccess = (payload) => ({
  type:EXAMTYPES_DELETE_MANY_SUCCESS,
  payload
});
export const deleteManyExamTypesError = (payload) => ({
  type: EXAMTYPES_DELETE_MANY_ERROR,
  payload
});