import {
  USER_GET_LIST,
  USER_GET_LIST_SUCCESS,
  USER_GET_LIST_ERROR,
  USER_CHANGE_STATE,
  USER_CHANGE_STATE_SUCCESS,
  USER_CHANGE_STATE_ERROR,

  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGOUT_USER,

  CHAT_LOGIN_USER,
  CHAT_LOGIN_USER_SUCCESS,
  CHAT_LOGIN_USER_ERROR,
  CHAT_LOGOUT_USER,

  GET_CHATROOM_ID,
  GET_CHATROOM_ID_SUCCESS,
  GET_CHATROOM_ID_ERROR,

  EDIT_USER,
  EDIT_USER_SUCCESS,
  EDIT_USER_ERROR,

  REGISTER_USER,
  REGISTER_USER_SUCCESS,

  LOGIN_USER_ERROR,
  REGISTER_USER_ERROR,

  FORGOT_PASSWORD,
  FORGOT_PASSWORD_SUCCESS,

  FORGOT_PASSWORD_ERROR,
  RESET_PASSWORD,

  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,

  USER_CHANGE_BOX_VIEW,

  USER_DELETE_ONE,
  USER_DELETE_ONE_SUCCESS,
  USER_DELETE_ONE_ERROR,



  USER_GET_BY_FILTER,
  USER_GET_BY_FILTER_SUCCESS,
  USER_GET_BY_FILTER_ERROR,
} from '../actions';

// GET LIST
export const getUsersList = (payload) => ({
  type: USER_GET_LIST,
  payload
});
export const getUsersListSuccess = (payload) => ({
  type: USER_GET_LIST_SUCCESS,
  payload
});
export const getUsersListError = (payload) => ({
  type: USER_GET_LIST_ERROR,
  payload
});

export const changeUserState = (payload) => ({
  type: USER_CHANGE_STATE,
  payload
});
export const changeUserStateSuccess = (payload) => ({
  type: USER_CHANGE_STATE_SUCCESS,
  payload
});
export const changeUserStateError = (payload) => ({
  type: USER_CHANGE_STATE_ERROR,
  payload
});


export const loginUser = (user, history) => ({
  type: LOGIN_USER,
  payload: { user, history }
});
export const loginUserSuccess = (user) => ({
  type: LOGIN_USER_SUCCESS,
  payload: user
});
export const loginUserError = (message) => ({
  type: LOGIN_USER_ERROR,
  payload: { message }
});

export const editUser = (payload) => ({
  type: EDIT_USER,
  payload
});
export const editUserSuccess = (user) => ({
  type: EDIT_USER_SUCCESS,
  payload: user
});
export const editUserError = (message) => ({
  type: EDIT_USER_ERROR,
  payload: { message }
});


export const forgotPassword = (forgotUserMail, history) => ({
  type: FORGOT_PASSWORD,
  payload: { forgotUserMail, history }
});
export const forgotPasswordSuccess = (forgotUserMail) => ({
  type: FORGOT_PASSWORD_SUCCESS,
  payload: forgotUserMail
});
export const forgotPasswordError = (message) => ({
  type: FORGOT_PASSWORD_ERROR,
  payload: { message }
});

export const resetPassword = ({ resetPasswordCode, newPassword, history }) => ({
  type: RESET_PASSWORD,
  payload: { resetPasswordCode, newPassword, history }
});
export const resetPasswordSuccess = (newPassword) => ({
  type: RESET_PASSWORD_SUCCESS,
  payload: newPassword
});
export const resetPasswordError = (message) => ({
  type: RESET_PASSWORD_ERROR,
  payload: { message }
});



export const registerUser = (payload) => ({
  type: REGISTER_USER,
  payload
})
export const registerUserSuccess = (user) => ({
  type: REGISTER_USER_SUCCESS,
  payload: user
})
export const registerUserError = (message) => ({
  type: REGISTER_USER_ERROR,
  payload: { message }
})

export const logoutUser = (history, token, rocketToken, rocketId) => ({
  type: LOGOUT_USER,
  payload: { history, token, rocketToken, rocketId }
});

export const chatLogin = (user, history) => ({
  type: CHAT_LOGIN_USER,
  payload: { user, history }
});
export const chatLoginUserSuccess = (user) => ({
  type: CHAT_LOGIN_USER_SUCCESS,
  payload: user
});
export const chatLoginUserError = (message) => ({
  type: CHAT_LOGIN_USER_ERROR,
  payload: { message }
});


export const getRoomId = (data, history) => ({
  type: GET_CHATROOM_ID,
  payload: { data, history }
});
export const getRoomIdSuccess = (roomId) => ({
  type: GET_CHATROOM_ID_SUCCESS,
  payload: roomId
});
export const getRoomIdError = (message) => ({
  type: GET_CHATROOM_ID_ERROR,
  payload: { message }
});


export const changeBoxView = (payload) => ({
  type: USER_CHANGE_BOX_VIEW,
  payload: payload
});


  // DELETE ONE
  export const deleteOneUser = (payload) => ({
    type: USER_DELETE_ONE,
    payload
  });
  export const deleteOneUserSuccess = (payload) => ({
    type: USER_DELETE_ONE_SUCCESS,
    payload
  });
  export const deleteOneUserError = (payload) => ({
    type: USER_DELETE_ONE_ERROR,
    payload
  });




  //GET BY FILTER

export const getUserByFilter = (payload) => ({
  type: USER_GET_BY_FILTER,
  payload
});
export const getUserByFilterSuccess = (payload) => ({
  type: USER_GET_BY_FILTER_SUCCESS,
  payload
});
export const getUserByFilterError = (payload) => ({
  type: USER_GET_BY_FILTER_ERROR,
  payload
});
