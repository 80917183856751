import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { api } from "../../constants/defaultValues";
import axios from "axios";

import {
    RESERVATION_TYPES_GET_LIST,RESERVATION_TYPE_GET_ONE,RESERVATION_TYPE_ADD_ONE,RESERVATION_TYPE_EDIT_ONE,RESERVATION_TYPE_DELETE_ONE
} from "../actions";

import {
    getReservationTypesListSuccess,
    getReservationTypesListListError,

    getOneReservationTypeSuccess,
    getOneReservationTypeError,

    addReservationTypeSuccess,
    addReservationTypeError,

    editReservationTypeSuccess,
    editReservationTypeError,

    deleteOneReservationTypeSuccess,
    deleteOneReservationTypeError,
    
  } from "./actions";

   //GET LIST
const getReservationTypesListRequest = async (payload) => {
    return await axios.get(api.canteen_reservation_types+`/list/${payload.inst_id}/${payload.offset}/${payload.limit}`, { headers: {Authorization: payload.token} })
    .then(resp=>resp)
    .catch(error=>{return (error && error.response) ? error.response : error})
  };
function* getReservationTypesList({payload}) {
    try {
      const ret = yield call(getReservationTypesListRequest, payload);
      
      if (ret && ret.status==200)
      yield put(getReservationTypesListSuccess({reservation_types: ret.data.canteen_rsrvn_types || [], count: ret.data.count || 0}));
      
      else yield put(getReservationTypesListListError({message: (ret.data) ? ret.data.msg : "Network Error"}))
  
    } catch (error) {
      yield put(getReservationTypesListListError({error, message: (error) ? error.message : ''}));
    }
  }


//GET ONE
const getOneReservationTypeRequest = async (payload) => {
    return await axios.get(api.canteen_reservation_types+`/get-one/${payload._id}`, { headers: {Authorization: payload.token} })
    .then(resp=>resp)
    .catch(error=>{return (error && error.response) ? error.response : error})
  };
function* getOneReservationType({payload}) {  
    try {
      const ret = yield call(getOneReservationTypeRequest, payload);
  
      if (ret && ret.status==200)
      yield put(getOneReservationTypeSuccess({reservation_type: ret.data.canteen_rsrvn_type}));
      
      else yield put(getOneReservationTypeError({message: (ret.data) ? ret.data.msg : "Network Error"}))
  
    } catch (error) {
      yield put(getOneReservationTypeError({error, message: (error) ? error.message : ''}));
    }
  }
  

  //ADD ONE
const addReservationTypeRequest = async (payload) => {
    return await axios.post(api.canteen_reservation_types, payload.data, { 
      headers: {Authorization: payload.token},
   
    })
    .then(resp=>resp)
    .catch(error=>{return (error && error.response) ? error.response : error})
  };
function* addReservationType({payload}) {  
    try {
      const ret = yield call(addReservationTypeRequest, payload);
      
      if (ret && ret.status==201){
        const { history } = payload;
  
        if(history) history.push('/app/canteen/reservation-types')
        yield put(addReservationTypeSuccess({reservation_type: ret.data.canteen_rsrvn_type}));
      }
      else yield put(addReservationTypeError({message: (ret.data) ? ret.data.msg : "Network Error"}))
  
    } catch (error) {
      console.log('saga err', error);
      yield put(addReservationTypeError({error, message: (error) ? error.message : ''}));
    }
  }

  //EDIT ONE
const editReservationTypeRequest = async (payload) => {
    return await axios.patch(`${api.canteen_reservation_types}/edit-one/${payload._id}`, payload.data, { headers: {Authorization: payload.token} })
    .then(resp=>resp)
    .catch(error=>{return (error && error.response) ? error.response : error})
  };
function* editReservationType({payload}) {  
    try {
      const ret = yield call(editReservationTypeRequest, payload);
      
      if (ret && ret.status==200){
        const { history } = payload;
  
        if(history) history.push('/app/canteen/reservation-types')
        yield put(editReservationTypeSuccess({reservation_type: ret.data.canteen_rsrvn_type}));
      }
      else yield put(editReservationTypeError({message: (ret.data) ? ret.data.msg : "Network Error"}))
  
    } catch (error) {
      console.log('saga err', error);
      yield put(editReservationTypeError({error, message: (error) ? error.message : ''}));
    }
  }

 // DELETE ONE
const deleteOneReservationTypeRequest = async (payload) => {
    return await axios.delete(`${api.canteen_reservation_types}/delete-one/${payload._id}`, { headers: {Authorization: payload.token} })
    .then(resp=>resp)
    .catch(error=>{return (error && error.response) ? error.response : error})
  };
function* deleteOneReservationType({payload}) {  
    try {
      const ret = yield call(deleteOneReservationTypeRequest, payload);
      
      if (ret && ret.status==202){
        const { history, index } = payload;
  
        if(history) history.push('/app/canteen/reservation-types')
        yield put(deleteOneReservationTypeSuccess({index}));
      }
      else yield put(deleteOneReservationTypeError({message: (ret.data) ? ret.data.msg : "Network Error"}))
  
    } catch (error) {
      console.log('saga err', error);
      yield put(deleteOneReservationTypeError({error, message: (error) ? error.message : ''}));
    }
  }

//////// WATCHERS
export function* watchGetList() {
    yield takeEvery(RESERVATION_TYPES_GET_LIST, getReservationTypesList);
  }
  
export function* watchGetOne() {
    yield takeEvery(RESERVATION_TYPE_GET_ONE, getOneReservationType);
  }
  
export function* watchEditOne() {
    yield takeEvery(RESERVATION_TYPE_EDIT_ONE,editReservationType);
  }
  
export function* watchAddOne() {
    yield takeEvery(RESERVATION_TYPE_ADD_ONE,addReservationType);
  }
  
export function* watchDeleteOne() {
    yield takeEvery(RESERVATION_TYPE_DELETE_ONE,deleteOneReservationType);
  }
  
export default function* rootSaga() {
    yield all([
      fork(watchGetList),
      fork(watchGetOne),
      fork(watchEditOne),
      fork(watchAddOne),
      fork(watchDeleteOne)
    ]);
  }