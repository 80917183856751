import {
    CANTEENMENUS_GET_LIST,
    CANTEENMENUS_GET_LIST_SUCCESS,
    CANTEENMENUS_GET_LIST_ERROR,

    CANTEENMENU_GET_ONE,
    CANTEENMENU_GET_ONE_SUCCESS,
    CANTEENMENU_GET_ONE_ERROR,

    CANTEENMENU_ADD_ONE,
    CANTEENMENU_ADD_ONE_SUCCESS,
    CANTEENMENU_ADD_ONE_ERROR,

    CANTEENMENU_EDIT_ONE,
    CANTEENMENU_EDIT_ONE_SUCCESS,
    CANTEENMENU_EDIT_ONE_ERROR,

    CANTEENMENU_DELETE_ONE,
    CANTEENMENU_DELETE_ONE_SUCCESS,
    CANTEENMENU_DELETE_ONE_ERROR,
  } from '../actions';
import { NotificationManager } from '../../components/common/react-notifications';
import  notificationMessages  from '../../lang/locales/notificationMsg'

const INIT_STATE = {
    canteen_menus: [],
    count: 0,
    canteen_menu: null,
    error: '',
  };

export default (state = INIT_STATE, action) => {
  const messages = notificationMessages()
	switch (action.type) {

    ///GET LIST
	case CANTEENMENUS_GET_LIST:
      return { ...state, loading: true, error: '', 
      count: 0
    };
    case CANTEENMENUS_GET_LIST_SUCCESS:
      return { ...state, loading: false, 
        canteen_menus: action.payload.canteen_menus || [],
        count: action.payload.count || 0
      };
		case CANTEENMENUS_GET_LIST_ERROR:
      return { ...state, loading: false, error: action.payload.message };



    // GET ONE
    case CANTEENMENU_GET_ONE:
       return { ...state, loading: true, error: ''};
    case CANTEENMENU_GET_ONE_SUCCESS:
      
       return { ...state, loading: false, error: '',
       canteen_menu: action.payload.canteen_menu
       };
    case CANTEENMENU_GET_ONE_ERROR:
       return { ...state, loading: false, error: action.payload.message };
    
    
    
    
    
    
      // ADD ONE
    case CANTEENMENU_ADD_ONE:
      return { ...state, loading: true, error: ''};
    case CANTEENMENU_ADD_ONE_SUCCESS:    
      NotificationManager.success(messages["Added"], messages["Success"],3000, null, null, '');
      return { ...state, loading: false, 
        canteen_menus: [action.payload.canteen_menu, ...state.canteen_menus]
      };
    case CANTEENMENU_ADD_ONE_ERROR:
      NotificationManager.warning(action.payload.message ||  messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };
    
    
        // EDIT ONE
    case CANTEENMENU_EDIT_ONE:
      return { ...state, loading: true, error: ''};
    case CANTEENMENU_EDIT_ONE_SUCCESS:    
    NotificationManager.success(messages["Edited"], messages["Success"], 3000, null, null, '');
      return { ...state, loading: false, error: '',
      canteen_menu: action.payload.canteen_menu
      };
    case CANTEENMENU_EDIT_ONE_ERROR:
      NotificationManager.warning(action.payload.message ||  messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };
    
    

       // DELETE ONE
    case CANTEENMENU_DELETE_ONE:
       return { ...state, loading: true, error: ''};
    case CANTEENMENU_DELETE_ONE_SUCCESS:  
       const canteen_menus = state.canteen_menus 
       if(action.payload.index!=null && action.payload.index!=undefined)
       canteen_menus.splice(action.payload.index, 1)
 
       return { ...state, loading: false, error: '',
       canteen_menus
       };
    case CANTEENMENU_DELETE_ONE_ERROR:
      NotificationManager.warning(action.payload.message ||  messages["Unkown Error"], messages["Error"], 3000, null, null, '');
       return { ...state, loading: false, error: action.payload.message };
 
     
    default: return { ...state };
    }}