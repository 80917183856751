import { all, call, fork, put, takeEvery } from "redux-saga/effects";
// import { getDateWithFormat } from "../../helpers/Utils";
import { api } from "../../constants/defaultValues";
import axios from "axios";

import { ELIMINATION_GET_LIST,ELIMINATION_GET_ONE,ELIMINATION_GET_BY_FILTER,ELIMINATION_ADD_ONE,ELIMINATION_EDIT_ONE,ELIMINATION_DELETE_ONE,
  ELIMINATIONS_DELETE_MANY,ELIMINATION_PRINT,
} from "../actions";
import {
    getEliminationsListSuccess,
    getEliminationsListError,
    
    getOneEliminationSuccess,
    getOneEliminationError,

    getEliminationsByFilterSuccess,
    getEliminationsByFilterError,


    addEliminationSuccess,
    addEliminationError,

    editOneEliminationSuccess,
    editOneEliminationError,

    deleteOneEliminationSuccess,
    deleteOneEliminationError,


    deleteManyEliminationsSuccess,
    deleteManyEliminationsError,

    printEliminationsSuccess,
    printEliminationsError
  } from "./actions";

 //GET LIST
 const getEliminationsListRequest = async (payload) => {
    return await axios.get(api.eliminations+`/list/${payload.inst_id}/${payload.offset}/${payload.limit}`, { headers: {Authorization: payload.token} })
    .then(resp=>resp)
    .catch(error=>{return (error && error.response) ? error.response : error})
  };
function* getEliminationsList({payload}) {
    try {
      const ret = yield call(getEliminationsListRequest, payload);
      
      if (ret && ret.status==200)
      yield put(getEliminationsListSuccess({eliminations: ret.data.eliminations || [], count: ret.data.count || 0}));
      
      else yield put(getEliminationsListError({message: (ret.data) ? ret.data.msg : "Network Error"}))
  
    } catch (error) {
      yield put(getEliminationsListError({error, message: (error) ? error.message : ''}));
    }
  }


 //GET ONE
 const getOneEliminationRequest = async (payload) => {
    return await axios.get(api.eliminations+`/get-one/${payload._id}`, { headers: {Authorization: payload.token} })
    .then(resp=>resp)
    .catch(error=>{return (error && error.response) ? error.response : error})
  };
function* getOneElimination({payload}) {  
    try {
      const ret = yield call(getOneEliminationRequest, payload);
  
      if (ret && ret.status==200)
      yield put(getOneEliminationSuccess({elimination: ret.data.elimination}));
      
      else yield put(getOneEliminationError({message: (ret.data) ? ret.data.msg : "Network Error"}))
  
    } catch (error) {
      yield put(getOneEliminationError({error, message: (error) ? error.message : ''}));
    }
  }


//GET  BY FILTER
const getEliminationsByFilterRequest = async (payload) => {
    return await axios.post(api.eliminations+`/filters/${payload.offset}/${payload.limit}`,payload.filter, { headers: {Authorization: payload.token} })
    .then(resp=>resp)
    .catch(error=>{return (error && error.response) ? error.response : error})
  };
  function* getEliminationsByFilter({payload}) {
    try {
      const ret = yield call(getEliminationsByFilterRequest, payload);
      
  
      if (ret && ret.status==200)
      yield put(getEliminationsByFilterSuccess({eliminations: ret.data.eliminations , count:ret.data.count}));
      
      else yield put(getEliminationsByFilterError({message: (ret.data) ? ret.data.msg : "Network Error"}))
  
    } catch (error) {
      yield put(getEliminationsByFilterError({error, message: (error) ? error.message : ''}));
    }
  }



//ADD ONE
const addEliminationRequest = async (payload) => {

    return await axios.post(api.eliminations , payload.data,{headers: {Authorization: payload.token}})
    .then(resp=>resp)
    .catch(error=>{return (error && error.response) ? error.response : error})
  };
  function* addElimination({payload}) {  
    try {
      const ret = yield call(addEliminationRequest, payload);
      
      if (ret && ret.status==201){
        const { history } = payload;
  
        if(history) history.push('/app/schooling/eliminations/list')
        yield put(addEliminationSuccess({elimination: ret.data.elimination}));
      }
      else yield put(addEliminationError({message: (ret.data) ? ret.data.msg : "Network Error"}))
  
    } catch (error) {
      console.log('saga err', error);
      yield put(addEliminationError({error, message: (error) ? error.message : ''}));
    }
  }
  

  //EDIT ONE
const editOneEliminationRequest = async (payload) => {
  
    return await axios.patch(`${api.eliminations}/edit-one/${payload._id}`, payload.data, { headers: {Authorization: payload.token}

   })
    .then(resp=>resp)
    .catch(error=>{return (error && error.response) ? error.response : error})
  };
function* editOneElimination({payload}) {  
    try {
      const ret = yield call(editOneEliminationRequest, payload);
      
      if (ret && ret.status==200){
        const { history } = payload;
  
        if(history) history.push('/app/schooling/eliminations/list')
        yield put(editOneEliminationSuccess({elimination: ret.data.elimination}));
      }
      else yield put(editOneEliminationError({message: (ret.data) ? ret.data.msg : "Network Error"}))
  
    } catch (error) {
      console.log('saga err', error);
      yield put(editOneEliminationError({error, message: (error) ? error.message : ''}));
    }
  }
  
 // DELETE ONE
 const deleteOneEliminationRequest = async (payload) => {
    return await axios.delete(`${api.eliminations}/delete-one/${payload._id}`, { headers: {Authorization: payload.token} })
    .then(resp=>resp)
    .catch(error=>{return (error && error.response) ? error.response : error})
  };
function* deleteOneElimination({payload}) {  
    try {
      const ret = yield call(deleteOneEliminationRequest, payload);
      
      if (ret && ret.status==202){
        const { history, index } = payload;
  
        if(history) history.push('/app/schooling/eliminations')
        yield put(deleteOneEliminationSuccess({index}));
      }
      else yield put(deleteOneEliminationError({message: (ret.data) ? ret.data.msg : "Network Error"}))
  
    } catch (error) {
      console.log('saga err', error);
      yield put(deleteOneEliminationError({error, message: (error) ? error.message : ''}));
    }
  }



  const deleteManyEliminationsRequest = async (payload) => {
    return await axios.delete(`${api.eliminations}/delete-many`, { headers: { Authorization: payload.token }, data: payload.data })
      .then(resp => resp)
      .catch(error => { return (error && error.response) ? error.response : error })
  };
  function* deleteManyEliminations({ payload }) {
    try {
      const ret = yield call(deleteManyEliminationsRequest, payload);
  
      if (ret && ret.status == 202) {
        const { history, data } = payload;
  
        if (history) history.push('/app/schooling/eliminations')
        yield put(deleteManyEliminationsSuccess({ data }));
      }
      else yield put(deleteManyEliminationsError({ message: (ret.data) ? ret.data.msg : "Network Error" }))
  
    } catch (error) {
      console.log('saga err', error);
      yield put(deleteManyEliminationsError({ error, message: (error) ? error.message : '' }));
    }
  }
  


//PRINT PAYMENT
const printEliminationsRequest = async (payload) => {
  return await axios.post(`${api.eliminations}/print`, payload.data, { headers: { Authorization: payload.token } })
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* printEliminations({ payload }) {
  try {
    const ret = yield call(printEliminationsRequest, payload);
  
    

    if (ret && ret.status == 200) {
      const { history } = payload;

  
        if(history&&payload.data?.listClass)history.push('/app/schooling/eliminations/list')
        
        if (history&&!payload.data?.listClass) history.push('/schooling/eliminations/list')
      
      yield put(printEliminationsSuccess({ path: ret.data.path }));
    }
    else yield put(printEliminationsError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
   
    yield put(printEliminationsError({ error, message: (error) ? error.message : '' }));
  }
}



//////// WATCHERS
export function* watchGetList() {
    yield takeEvery(ELIMINATION_GET_LIST, getEliminationsList);
  }
  
export function* watchGetOne() {
    yield takeEvery(ELIMINATION_GET_ONE,getOneElimination );
  }

export function* watchGetFilter() {
    yield takeEvery(ELIMINATION_GET_BY_FILTER, getEliminationsByFilter);
  }
  
  
export function* watchEditOne() {
    yield takeEvery(ELIMINATION_EDIT_ONE,editOneElimination );
  }
  
export function* watchAddOne() {
    yield takeEvery(ELIMINATION_ADD_ONE, addElimination);
  }
  
export function* watchDeleteOne() {
    yield takeEvery(ELIMINATION_DELETE_ONE, deleteOneElimination);
  }
export function* watchDeleteMany() {
    yield takeEvery(ELIMINATIONS_DELETE_MANY,deleteManyEliminations);
  }

export function* watchprintEliminationss(){
  yield takeEvery(ELIMINATION_PRINT,printEliminations)
}


export default function* rootSaga() {
    yield all([
      fork(watchGetList),
      fork(watchGetOne),
      fork(watchGetFilter),
      fork(watchEditOne),
      fork(watchAddOne),
      fork(watchDeleteOne),
      fork(watchDeleteMany),
      fork(watchprintEliminationss),

    ]);
  }
  