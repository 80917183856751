import {
    MEALS_GET_LIST,
    MEALS_GET_LIST_SUCCESS,
    MEALS_GET_LIST_ERROR,

    MEAL_GET_ONE,
    MEAL_GET_ONE_SUCCESS,
    MEAL_GET_ONE_ERROR,
    
    MEAL_ADD_ONE,
    MEAL_ADD_ONE_SUCCESS,
    MEAL_ADD_ONE_ERROR,

    MEAL_EDIT_ONE,
    MEAL_EDIT_ONE_SUCCESS,
    MEAL_EDIT_ONE_ERROR,

    MEAL_DELETE_ONE,
    MEAL_DELETE_ONE_SUCCESS,
    MEAL_DELETE_ONE_ERROR,

  } from '../actions';
  
    // GET LIST
  export const getMealsList = (payload) => ({
    type: MEALS_GET_LIST,
    payload
  });
  export const getMealsListSuccess = (payload) => ({
    type: MEALS_GET_LIST_SUCCESS,
    payload
  });
  export const getMealsListError = (payload) => ({
    type: MEALS_GET_LIST_ERROR,
    payload
  });

// GET ONE
export const getOneMeal = (payload) => ({
    type: MEAL_GET_ONE,
    payload
  });
export const getOneMealSuccess = (payload) => ({
    type: MEAL_GET_ONE_SUCCESS,
    payload
  });
export const getOneMealError = (payload) => ({
    type: MEAL_GET_ONE_ERROR,
    payload
  });


  
 // ADD ONE
export const addMeal = (payload) => ({
    type: MEAL_ADD_ONE,
    payload
  });
export const addMealSuccess = (payload) => ({
    type: MEAL_ADD_ONE_SUCCESS,
    payload
  });
export const addMealError = (payload) => ({
    type: MEAL_ADD_ONE_ERROR,
    payload
  });
  

// EDIT ONE
export const editMeal= (payload) => ({
    type: MEAL_EDIT_ONE,
    payload
  });
export const editMealSuccess = (payload) => ({
    type: MEAL_EDIT_ONE_SUCCESS,
    payload
  });
export const editMealError = (payload) => ({
    type: MEAL_EDIT_ONE_ERROR,
    payload
  });


  // DELETE ONE
export const deleteOneMeal = (payload) => ({
    type: MEAL_DELETE_ONE,
    payload
  });
export const deleteOneMealSuccess = (payload) => ({
    type: MEAL_DELETE_ONE_SUCCESS,
    payload
  });
export const deleteOneMealError = (payload) => ({
    type: MEAL_DELETE_ONE_ERROR,
    payload
  });