import {
  SCHOOLLEVEL_GET_LIST,
	SCHOOLLEVEL_GET_LIST_SUCCESS,
  SCHOOLLEVEL_GET_LIST_ERROR,

  SCHOOLLEVEL_GET_ONE,
	SCHOOLLEVEL_GET_ONE_SUCCESS,
  SCHOOLLEVEL_GET_ONE_ERROR,
  
  SCHOOLLEVEL_GET_BY_FILTER,
  SCHOOLLEVEL_GET_BY_FILTER_SUCCESS,
  SCHOOLLEVEL_GET_BY_FILTER_ERROR,

  SCHOOLLEVEL_ADD_ONE,
  SCHOOLLEVEL_ADD_ONE_SUCCESS,
  SCHOOLLEVEL_ADD_ONE_ERROR,

  SCHOOLLEVEL_EDIT_ONE,
  SCHOOLLEVEL_EDIT_ONE_SUCCESS,
  SCHOOLLEVEL_EDIT_ONE_ERROR,

  SCHOOLLEVEL_DELETE_ONE,
  SCHOOLLEVEL_DELETE_ONE_SUCCESS,
  SCHOOLLEVEL_DELETE_ONE_ERROR,

  SCHOOLLEVELS_DELETE_MANY,
  SCHOOLLEVELS_DELETE_MANY_SUCCESS,
  SCHOOLLEVELS_DELETE_MANY_ERROR,
} from '../actions';

  // GET LIST
export const getSchoolLevelsList = (payload) => ({
  type: SCHOOLLEVEL_GET_LIST,
  payload
});
export const getSchoolLevelsListSuccess = (payload) => ({
  type: SCHOOLLEVEL_GET_LIST_SUCCESS,
  payload
});
export const getSchoolLevelsListError = (payload) => ({
  type: SCHOOLLEVEL_GET_LIST_ERROR,
  payload
});







  // GET LIST
export const getOneSchoolLevel = (payload) => ({
  type: SCHOOLLEVEL_GET_ONE,
  payload
});
export const getOneSchoolLevelSuccess = (payload) => ({
  type: SCHOOLLEVEL_GET_ONE_SUCCESS,
  payload
});
export const getOneSchoolLevelError = (payload) => ({
  type: SCHOOLLEVEL_GET_ONE_ERROR,
  payload
});





// GET SCHOOL LEVEL  BY FILTER

export const getSchoolLevelByFilter = (payload) => ({
  type: SCHOOLLEVEL_GET_BY_FILTER,
  payload
});
export const getSchoolLevelByFilterSuccess = (payload) => ({
  type: SCHOOLLEVEL_GET_BY_FILTER_SUCCESS,
  payload
});
export const getSchoolLevelByFilterError = (payload) => ({
  type: SCHOOLLEVEL_GET_BY_FILTER_ERROR,
  payload
});




  // ADD ONE
export const addSchoolLevel = (payload) => ({
  type: SCHOOLLEVEL_ADD_ONE,
  payload
});
export const addSchoolLevelSuccess = (payload) => ({
  type: SCHOOLLEVEL_ADD_ONE_SUCCESS,
  payload
});
export const addSchoolLevelError = (payload) => ({
  type: SCHOOLLEVEL_ADD_ONE_ERROR,
  payload
});




  // EDIT ONE
export const editSchoolLevel = (payload) => ({
  type: SCHOOLLEVEL_EDIT_ONE,
  payload
});
export const editSchoolLevelSuccess = (payload) => ({
  type: SCHOOLLEVEL_EDIT_ONE_SUCCESS,
  payload
});
export const editSchoolLevelError = (payload) => ({
  type: SCHOOLLEVEL_EDIT_ONE_ERROR,
  payload
});




  // DELETE ONE
export const deleteOneSchoolLevel = (payload) => ({
  type: SCHOOLLEVEL_DELETE_ONE,
  payload
});
export const deleteOneSchoolLevelSuccess = (payload) => ({
  type: SCHOOLLEVEL_DELETE_ONE_SUCCESS,
  payload
});
export const deleteOneSchoolLevelError = (payload) => ({
  type: SCHOOLLEVEL_DELETE_ONE_ERROR,
  payload
});

// DELETE MANY
export const deleteManySchoolLevels = (payload) => ({
  type:   SCHOOLLEVELS_DELETE_MANY,
  payload
});
export const deleteManySchoolLevelsSuccess = (payload) => ({
  type: SCHOOLLEVELS_DELETE_MANY_SUCCESS,
  payload
});
export const deleteManySchoolLevelsError = (payload) => ({
  type: SCHOOLLEVELS_DELETE_MANY_ERROR,
  payload
});