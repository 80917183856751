import {
  PERIODTYPE_GET_LIST,
	PERIODTYPE_GET_LIST_SUCCESS,
  PERIODTYPE_GET_LIST_ERROR,

  PERIODTYPE_GET_ONE,
	PERIODTYPE_GET_ONE_SUCCESS,
  PERIODTYPE_GET_ONE_ERROR,
  
  PERIODTYPE_GET_ACTUEL,
  PERIODTYPE_GET_ACTUEL_SUCCESS,
  PERIODTYPE_GET_ACTUEL_ERROR,
  

  PERIODTYPE_SELECT_PERIOD,

  PERIODTYPE_ADD_ONE,
  PERIODTYPE_ADD_ONE_SUCCESS,
  PERIODTYPE_ADD_ONE_ERROR,

  PERIODTYPE_EDIT_ONE,
  PERIODTYPE_EDIT_ONE_SUCCESS,
  PERIODTYPE_EDIT_ONE_ERROR,

  PERIODTYPE_DELETE_ONE,
  PERIODTYPE_DELETE_ONE_SUCCESS,
  PERIODTYPE_DELETE_ONE_ERROR,

  PERIODTYPES_DELETE_MANY,
  PERIODTYPES_DELETE_MANY_SUCCESS,
  PERIODTYPES_DELETE_MANY_ERROR
} from '../actions';

  // GET LIST
export const getPeriodTypesList = (payload) => ({
  type: PERIODTYPE_GET_LIST,
  payload
});
export const getPeriodTypesListSuccess = (payload) => ({
  type: PERIODTYPE_GET_LIST_SUCCESS,
  payload
});
export const getPeriodTypesListError = (payload) => ({
  type: PERIODTYPE_GET_LIST_ERROR,
  payload
});



  // GET SELECTED PERIOD 
  export const getSelectedPeriod = (payload) => ({
    type: PERIODTYPE_SELECT_PERIOD,
    payload
  });




  // GET ONE
export const getOnePeriodType = (payload) => ({
  type: PERIODTYPE_GET_ONE,
  payload
});
export const getOnePeriodTypeSuccess = (payload) => ({
  type: PERIODTYPE_GET_ONE_SUCCESS,
  payload
});
export const getOnePeriodTypeError = (payload) => ({
  type: PERIODTYPE_GET_ONE_ERROR,
  payload
});
  
//GET ACTUAL
export const getPeriodTypeActuel = (payload) => ({
  type: PERIODTYPE_GET_ACTUEL,
  payload
});
export const getPeriodTypeActuelSuccess = (payload) => ({
  type: PERIODTYPE_GET_ACTUEL_SUCCESS,
  payload
});
export const getPeriodTypeActuelError = (payload) => ({
  type: PERIODTYPE_GET_ACTUEL_ERROR,
  payload
});








  // ADD ONE
export const addPeriodType = (payload) => ({
  type: PERIODTYPE_ADD_ONE,
  payload
});
export const addPeriodTypeSuccess = (payload) => ({
  type: PERIODTYPE_ADD_ONE_SUCCESS,
  payload
});
export const addPeriodTypeError = (payload) => ({
  type: PERIODTYPE_ADD_ONE_ERROR,
  payload
});




  // EDIT ONE
export const editPeriodType = (payload) => ({
  type: PERIODTYPE_EDIT_ONE,
  payload
});
export const editPeriodTypeSuccess = (payload) => ({
  type: PERIODTYPE_EDIT_ONE_SUCCESS,
  payload
});
export const editPeriodTypeError = (payload) => ({
  type: PERIODTYPE_EDIT_ONE_ERROR,
  payload
});




  // EDIT ONE
export const deleteOnePeriodType = (payload) => ({
  type: PERIODTYPE_DELETE_ONE,
  payload
});
export const deleteOnePeriodTypeSuccess = (payload) => ({
  type: PERIODTYPE_DELETE_ONE_SUCCESS,
  payload
});
export const deleteOnePeriodTypeError = (payload) => ({
  type: PERIODTYPE_DELETE_ONE_ERROR,
  payload
});


// DELETE MANY
export const deleteManyPeriodTypes = (payload) => ({
  type:   PERIODTYPES_DELETE_MANY,
  payload
});
export const deleteManyPeriodTypesSuccess = (payload) => ({
  type: PERIODTYPES_DELETE_MANY_SUCCESS,
  payload
});
export const deleteManyPeriodTypesError = (payload) => ({
  type: PERIODTYPES_DELETE_MANY_ERROR,
  payload
});