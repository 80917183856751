import {
  TEACHER_GET_LIST,
  TEACHER_GET_LIST_SUCCESS,
  TEACHER_GET_LIST_ERROR,

  SCHOOL_TEACHER_GET_LIST,
  SCHOOL_TEACHER_GET_LIST_SUCCESS,
  SCHOOL_TEACHER_GET_LIST_ERROR,

  TEACHER_GET_LIST_BY_FILTER,
  TEACHER_GET_LIST_BY_FILTER_SUCCESS,
  TEACHER_GET_LIST_BY_FILTER_ERROR,

  TEACHER_GET_LIST_PRIVATE,
  TEACHER_GET_LIST_PRIVATE_SUCCESS,
  TEACHER_GET_LIST_PRIVATE_ERROR,

  TEACHER_GET_ONE,
  TEACHER_GET_ONE_SUCCESS,
  TEACHER_GET_ONE_ERROR,

  TEACHER_ADD_ONE,
  TEACHER_ADD_ONE_SUCCESS,
  TEACHER_ADD_ONE_ERROR,

  TEACHER_ADD_ONE_PRIVATE,
  TEACHER_ADD_ONE_PRIVATE_SUCCESS,
  TEACHER_ADD_ONE_PRIVATE_ERROR,

  TEACHER_EDIT_ONE,
  TEACHER_EDIT_ONE_SUCCESS,
  TEACHER_EDIT_ONE_ERROR,

  TEACHER_DELETE_ONE,
  TEACHER_DELETE_ONE_SUCCESS,
  TEACHER_DELETE_ONE_ERROR,

  TEACHERS_DELETE_MANY,
  TEACHERS_DELETE_MANY_SUCCESS,
  TEACHERS_DELETE_MANY_ERROR
} from '../actions';

// GET LIST
export const getTeachersList = (payload) => ({
  type: TEACHER_GET_LIST,
  payload
});
export const getTeachersListSuccess = (payload) => ({
  type: TEACHER_GET_LIST_SUCCESS,
  payload
});
export const getTeachersListError = (payload) => ({
  type: TEACHER_GET_LIST_ERROR,
  payload
});

// GET SCHOOL TEACHERS
export const getSchoolTeachersList = (payload) => ({
  type: SCHOOL_TEACHER_GET_LIST,
  payload
});
export const getSchoolTeachersListSuccess = (payload) => ({
  type: SCHOOL_TEACHER_GET_LIST_SUCCESS,
  payload
});
export const getSchoolTeachersListError = (payload) => ({
  type: SCHOOL_TEACHER_GET_LIST_ERROR,
  payload
});


// GET LIST BY FILTER
export const getTeachersListByFilter = (payload) => ({
  type: TEACHER_GET_LIST_BY_FILTER,
  payload
});
export const getTeachersListByFilterSuccess = (payload) => ({
  type: TEACHER_GET_LIST_BY_FILTER_SUCCESS,
  payload
});
export const getTeachersListByFilterError = (payload) => ({
  type: TEACHER_GET_LIST_BY_FILTER_ERROR,
  payload
});

// GET LIST PRIVATE
export const getTeachersListPrivate = (payload) => ({
  type: TEACHER_GET_LIST_PRIVATE,
  payload
});
export const getTeachersListPrivateSuccess = (payload) => ({
  type: TEACHER_GET_LIST_PRIVATE_SUCCESS,
  payload
});
export const getTeachersListPrivateError = (payload) => ({
  type: TEACHER_GET_LIST_PRIVATE_ERROR,
  payload
});






// GET LIST
export const getOneTeacher = (payload) => ({
  type: TEACHER_GET_ONE,
  payload
});
export const getOneTeacherSuccess = (payload) => ({
  type: TEACHER_GET_ONE_SUCCESS,
  payload
});
export const getOneTeacherError = (payload) => ({
  type: TEACHER_GET_ONE_ERROR,
  payload
});








// ADD ONE
export const addTeacher = (payload) => ({
  type: TEACHER_ADD_ONE,
  payload
});
export const addTeacherSuccess = (payload) => ({
  type: TEACHER_ADD_ONE_SUCCESS,
  payload
});
export const addTeacherError = (payload) => ({
  type: TEACHER_ADD_ONE_ERROR,
  payload
});


// ADD ONE PRIVATE
export const addTeacherPrivate = (payload) => ({
  type: TEACHER_ADD_ONE_PRIVATE,
  payload
});
export const addTeacherPrivateSuccess = (payload) => ({
  type: TEACHER_ADD_ONE_PRIVATE_SUCCESS,
  payload
});
export const addTeacherPrivateError = (payload) => ({
  type: TEACHER_ADD_ONE_PRIVATE_ERROR,
  payload
});




// EDIT ONE
export const editTeacher = (payload) => ({
  type: TEACHER_EDIT_ONE,
  payload
});
export const editTeacherSuccess = (payload) => ({
  type: TEACHER_EDIT_ONE_SUCCESS,
  payload
});
export const editTeacherError = (payload) => ({
  type: TEACHER_EDIT_ONE_ERROR,
  payload
});




// DELETE ONE
export const deleteOneTeacher = (payload) => ({
  type: TEACHER_DELETE_ONE,
  payload
});
export const deleteOneTeacherSuccess = (payload) => ({
  type: TEACHER_DELETE_ONE_SUCCESS,
  payload
});
export const deleteOneTeacherError = (payload) => ({
  type: TEACHER_DELETE_ONE_ERROR,
  payload
});

// DELETE MANY
export const deleteManyTeachers = (payload) => ({
  type: TEACHERS_DELETE_MANY,
  payload
});
export const deleteManyTeachersSuccess = (payload) => ({
  type: TEACHERS_DELETE_MANY_SUCCESS,
  payload
});
export const deleteManyTeachersError = (payload) => ({
  type: TEACHERS_DELETE_MANY_ERROR,
  payload
});