import {
    EXAMPERIODS_GET_LIST,
    EXAMPERIODS_GET_LIST_SUCCESS,
    EXAMPERIODS_GET_LIST_ERROR,

    EXAMPERIODS_GET_ONE,
    EXAMPERIODS_GET_ONE_SUCCESS,
    EXAMPERIODS_GET_ONE_ERROR,

    EXAMPERIODS_GET_BY_FILTER,
    EXAMPERIODS_GET_BY_FILTER_SUCCESS,
    EXAMPERIODS_GET_BY_FILTER_ERROR,
    
    EXAMPERIODS_ADD_ONE,
    EXAMPERIODS_ADD_ONE_SUCCESS,
    EXAMPERIODS_ADD_ONE_ERROR,
    
    EXAMPERIODS_EDIT_ONE,
    EXAMPERIODS_EDIT_ONE_SUCCESS,
    EXAMPERIODS_EDIT_ONE_ERROR,

    EXAMPERIODS_DELETE_ONE,
    EXAMPERIODS_DELETE_ONE_SUCCESS,
    EXAMPERIODS_DELETE_ONE_ERROR,

  } from '../actions';

// GET LIST
export const getExamPeriodsList = (payload) => ({
    type: EXAMPERIODS_GET_LIST,
    payload
  });
export const getExamPeriodsListSuccess = (payload) => ({
    type: EXAMPERIODS_GET_LIST_SUCCESS,
    payload
  });
export const getExamPeriodsListError = (payload) => ({
    type: EXAMPERIODS_GET_LIST_ERROR,
    payload
  });

 // GET One
export const getOneExamPeriods = (payload) => ({
    type: EXAMPERIODS_GET_ONE,
    payload
  });
export const getOneExamPeriodsSuccess = (payload) => ({
    type: EXAMPERIODS_GET_ONE_SUCCESS,
    payload
  });
export const getOneExamPeriodsError = (payload) => ({
    type: EXAMPERIODS_GET_ONE_ERROR,
    payload
  });

  // GET EXAM PERIODS BY FILTER

export const getExamPeriodsByFilter = (payload) => ({
  type: EXAMPERIODS_GET_BY_FILTER,
  payload
});
export const getExamPeriodsByFilterSuccess = (payload) => ({
  type: EXAMPERIODS_GET_BY_FILTER_SUCCESS,
  payload
});
export const getExamPeriodsByFilterError = (payload) => ({
  type: EXAMPERIODS_GET_BY_FILTER_ERROR,
  payload
});
  // ADD ONE
export const addExamPeriods = (payload) => ({
    type: EXAMPERIODS_ADD_ONE,
    payload
  });
export const addExamPeriodsSuccess = (payload) => ({
    type: EXAMPERIODS_ADD_ONE_SUCCESS,
    payload
  });
export const addExamPeriodsError = (payload) => ({
    type: EXAMPERIODS_ADD_ONE_ERROR,
    payload
  });


  // EDIT ONE
export const editOneExamPeriods = (payload) => ({
    type: EXAMPERIODS_EDIT_ONE,
    payload
  });
export const editOneExamPeriodsSuccess = (payload) => ({
    type: EXAMPERIODS_EDIT_ONE_SUCCESS,
    payload
  });
export const editOneExamPeriodsError = (payload) => ({
    type: EXAMPERIODS_EDIT_ONE_ERROR,
    payload
  });

  // DELETE ONE
export const deleteOneExamPeriods = (payload) => ({
    type: EXAMPERIODS_DELETE_ONE,
    payload
  });
export const deleteOneExamPeriodsSuccess = (payload) => ({
    type: EXAMPERIODS_DELETE_ONE_SUCCESS,
    payload
  });
export const deleteOneExamPeriodsError = (payload) => ({
    type: EXAMPERIODS_DELETE_ONE_ERROR,
    payload
  });