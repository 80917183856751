import {
	MODULE_GET_LIST,
	MODULE_GET_LIST_SUCCESS,
  MODULE_GET_LIST_ERROR,

  MODULE_GET_ONE,
	MODULE_GET_ONE_SUCCESS,
  MODULE_GET_ONE_ERROR,

  
  MODULE_GET_BY_FILTER,
  MODULE_GET_BY_FILTER_SUCCESS,
  MODULE_GET_BY_FILTER_ERROR,

  MODULE_ADD_ONE,
  MODULE_ADD_ONE_SUCCESS,
  MODULE_ADD_ONE_ERROR,

  MODULE_EDIT_ONE,
  MODULE_EDIT_ONE_SUCCESS,
  MODULE_EDIT_ONE_ERROR,

  MODULE_DELETE_ONE,
  MODULE_DELETE_ONE_SUCCESS,
  MODULE_DELETE_ONE_ERROR,

  
  MODULES_DELETE_MANY,
  MODULES_DELETE_MANY_SUCCESS,
  MODULES_DELETE_MANY_ERROR
} from '../actions';
import { NotificationManager } from '../../components/common/react-notifications';
import  notificationMessages  from '../../lang/locales/notificationMsg'

const INIT_STATE = {
	modules: [],
  count: 0,
  module: null,
  error: '',
};

export default (state = INIT_STATE, action) => {
  const messages = notificationMessages()
	switch (action.type) {
		case MODULE_GET_LIST:
      return { ...state, loading: true, error: '', 
      count: 0
    };
    case MODULE_GET_LIST_SUCCESS:
      return { ...state, loading: false, 
        modules: action.payload.modules || [],
        count: action.payload.count || 0
      };
		case MODULE_GET_LIST_ERROR:
      return { ...state, loading: false, error: action.payload.message };






      // GET ONE
    case MODULE_GET_ONE:
      return { ...state, loading: true, error: ''};
    case MODULE_GET_ONE_SUCCESS:
      return { ...state, loading: false, error: '',
        module: action.payload.module
      };
    case MODULE_GET_ONE_ERROR:
      return { ...state, loading: false, error: action.payload.message };





    //GET BY FILTER
    case MODULE_GET_BY_FILTER:
        return { ...state, loading: true, error: ''};
    case   MODULE_GET_BY_FILTER_SUCCESS:
     // console.log("SUBJECT_GET_BY_FILTER_SUCCESS  : ",action.payload.subjects);
        return { ...state, loading: false, error: '',
        modules: action.payload.modules, count: action.payload.count };
    case MODULE_GET_BY_FILTER_ERROR:
        return { ...state, loading: false, error: action.payload.message };




      // ADD ONE
    case MODULE_ADD_ONE:
      return { ...state, loading: true, error: ''};
    case MODULE_ADD_ONE_SUCCESS:    
      NotificationManager.success(messages["Added"], messages["Success"],3000, null, null, '');
      return { ...state, loading: false, 
        modules: [action.payload.module, ...state.modules]
      };
    case MODULE_ADD_ONE_ERROR:
      NotificationManager.warning(action.payload.message ||  messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };





    
      // EDIT ONE
    case MODULE_EDIT_ONE:
      return { ...state, loading: true, error: ''};
    case MODULE_EDIT_ONE_SUCCESS:    
      NotificationManager.success(messages["Edited"], messages["Success"], 3000, null, null, '');
      return { ...state, loading: false, error: '',
      module: action.payload.module
      };
    case MODULE_EDIT_ONE_ERROR:
      NotificationManager.warning(action.payload.message ||  messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };

    




      // DELETE ONE
    case MODULE_DELETE_ONE:
      return { ...state, loading: true, error: ''};
    case MODULE_DELETE_ONE_SUCCESS:  
      const modules = state.modules 
      if(action.payload.index!=null && action.payload.index!=undefined)
      modules.splice(action.payload.index, 1)

      return { ...state, loading: false, error: '',
        modules
      };
    case MODULE_DELETE_ONE_ERROR:
      NotificationManager.warning(action.payload.message ||  messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };




        // DELETE MANY
        case MODULES_DELETE_MANY:
          return { ...state, loading: true, error: '' };
      case MODULES_DELETE_MANY_SUCCESS:
          const modules_list = state.modules
         
    
          if (action.payload.data.ids.length > 0) {
       
            for (let i = 0; i < action.payload.data.ids.length; i++) {
    
              let index_module= -1
              for (let j = 0; j < modules_list.length; j++) {
                if (modules_list[j]._id === action.payload.data.ids[i]) {
                  index_module = j; break;
                }
              }
              if (index_module > -1) modules_list.splice(index_module, 1)
            }
          }
          return {
            ...state, loading: false, error: '',
            modules: modules_list
          };
      case MODULES_DELETE_MANY_ERROR:
          NotificationManager.warning(action.payload.message || messages["Unkown Error"], messages["Error"], 3000, null, null, '');
          return { ...state, loading: false, error: action.payload.message };

    
		default: return { ...state };
	}
}
