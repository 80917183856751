import {
  ELIMINATION_GET_LIST,
  ELIMINATION_GET_LIST_SUCCESS,
  ELIMINATION_GET_LIST_ERROR,


  ELIMINATION_GET_ONE,
  ELIMINATION_GET_ONE_SUCCESS,
  ELIMINATION_GET_ONE_ERROR,

  ELIMINATION_GET_BY_FILTER,
  ELIMINATION_GET_BY_FILTER_SUCCESS,
  ELIMINATION_GET_BY_FILTER_ERROR,

  ELIMINATION_ADD_ONE,
  ELIMINATION_ADD_ONE_SUCCESS,
  ELIMINATION_ADD_ONE_ERROR,

  ELIMINATION_EDIT_ONE,
  ELIMINATION_EDIT_ONE_SUCCESS,
  ELIMINATION_EDIT_ONE_ERROR,

  ELIMINATION_DELETE_ONE,
  ELIMINATION_DELETE_ONE_SUCCESS,
  ELIMINATION_DELETE_ONE_ERROR,

  ELIMINATIONS_DELETE_MANY,
  ELIMINATIONS_DELETE_MANY_SUCCESS,
  ELIMINATIONS_DELETE_MANY_ERROR,

  ELIMINATION_PRINT,
  ELIMINATION_PRINT_SUCCESS,
  ELIMINATION_PRINT_ERROR
} from '../actions';


import { NotificationManager } from '../../components/common/react-notifications';
import notificationMessages from '../../lang/locales/notificationMsg'
const INIT_STATE = {
  eliminations: [],
  count: 0,
  elimination: null,
  error: '',
  loading: false,
  print_success: null
};
export default (state = INIT_STATE, action) => {
  const messages = notificationMessages()
  switch (action.type) {

    //GET LIST 
    case ELIMINATION_GET_LIST:
      return {
        ...state, loading: true, error: '',
        count: 0
      };
    case ELIMINATION_GET_LIST_SUCCESS:
      return {
        ...state, loading: false,
        eliminations: action.payload.eliminations || [],
        count: action.payload.count || 0
      };
    case ELIMINATION_GET_LIST_ERROR:
      return { ...state, loading: false, error: action.payload.message };



    // GET ONE
    case ELIMINATION_GET_ONE:
      return {
        ...state, loading: true, error: ''
      };

    case ELIMINATION_GET_ONE_SUCCESS:
      return {
        ...state, loading: false, error: '',
        elimination: action.payload.elimination

      };
    case ELIMINATION_GET_ONE_ERROR:
      return { ...state, loading: false, error: action.payload.message };


    // GET BY FILTER
    case ELIMINATION_GET_BY_FILTER:

      return { ...state, loading: true, error: '' };
    case ELIMINATION_GET_BY_FILTER_SUCCESS:

      return {
        ...state, loading: false, error: '',
        eliminations: action.payload.eliminations || [],
        count: action.payload.count || 0
      };
    case ELIMINATION_GET_BY_FILTER_ERROR:

      return { ...state, loading: false, error: action.payload.message };



    // ADD ONE
    case ELIMINATION_ADD_ONE:
      return { ...state, loading: true, error: '' };
    case ELIMINATION_ADD_ONE_SUCCESS:
      NotificationManager.success(messages["Added"], messages["Success"], 3000, null, null, '');
      return {
        ...state, loading: false,
        eliminations: [action.payload.eliminations, ...state.eliminations]
      };
    case ELIMINATION_ADD_ONE_ERROR:
      NotificationManager.warning(action.payload.message || messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };




    // EDIT ONE
    case ELIMINATION_EDIT_ONE:
      return { ...state, loading: true, edit_sucess: false, error: '' };
    case ELIMINATION_EDIT_ONE_SUCCESS:
      NotificationManager.success(messages["Edited"], messages["Success"], 3000, null, null, '');
      return {
        ...state, loading: false, edit_sucess: true, error: '',
        elimination: action.payload.elimination
      };
    case ELIMINATION_EDIT_ONE_ERROR:
      NotificationManager.warning(action.payload.message || messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, edit_sucess: false, error: action.payload.message };



    // DELETE ONE
    case ELIMINATION_DELETE_ONE:
      return { ...state, loading: true, error: '' };
    case ELIMINATION_DELETE_ONE_SUCCESS:
      const eliminations = state.eliminations
      if (action.payload.index != null && action.payload.index != undefined)
        eliminations.splice(action.payload.index, 1)

      return {
        ...state, loading: false, error: '',
        eliminations
      };
    case ELIMINATION_DELETE_ONE_ERROR:
      NotificationManager.warning(action.payload.message || messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };



    // DELETE MANY
    case ELIMINATIONS_DELETE_MANY:
      return { ...state, loading: true, error: '' };
    case ELIMINATIONS_DELETE_MANY_SUCCESS:
      const eliminations_list = state.eliminations


      if (action.payload.data.ids.length > 0) {

        for (let i = 0; i < action.payload.data.ids.length; i++) {

          let index_elimination = -1
          for (let j = 0; j < eliminations_list.length; j++) {
            if (eliminations_list[j]._id === action.payload.data.ids[i]) {
              index_elimination = j; break;
            }
          }
          if (index_elimination > -1) eliminations_list.splice(index_elimination, 1)
        }
      }
      return {
        ...state, loading: false, error: '',
        eliminations: eliminations_list
      };
    case ELIMINATIONS_DELETE_MANY_ERROR:
      NotificationManager.warning(action.payload.message || messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };



    //PRINT ELIMINATION


    case ELIMINATION_PRINT:
      return { ...state, loading: true, print_success: false, error: '' };
      
    case ELIMINATION_PRINT_SUCCESS:
      NotificationManager.success(messages["Print"], messages["Success"], 3000, null, null, '');
      return {
        ...state, loading: false, error: '', print_success: true,
        path: action.payload.path
      };

    case ELIMINATION_PRINT_ERROR:
      NotificationManager.warning(action.payload.message || messages["Error Class"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message, print_success: false, };


    default: return { ...state };
  }
}