import {
	EDUCATIONLEVEL_GET_LIST,
	EDUCATIONLEVEL_GET_LIST_SUCCESS,
  EDUCATIONLEVEL_GET_LIST_ERROR,

  EDUCATIONLEVEL_GET_ONE,
	EDUCATIONLEVEL_GET_ONE_SUCCESS,
  EDUCATIONLEVEL_GET_ONE_ERROR,

  EDUCATIONLEVEL_ADD_ONE,
  EDUCATIONLEVEL_ADD_ONE_SUCCESS,
  EDUCATIONLEVEL_ADD_ONE_ERROR,

  EDUCATIONLEVEL_EDIT_ONE,
  EDUCATIONLEVEL_EDIT_ONE_SUCCESS,
  EDUCATIONLEVEL_EDIT_ONE_ERROR,

  EDUCATIONLEVEL_DELETE_ONE,
  EDUCATIONLEVEL_DELETE_ONE_SUCCESS,
  EDUCATIONLEVEL_DELETE_ONE_ERROR,

  EDUCATIONLEVELS_DELETE_MANY,
  EDUCATIONLEVELS_DELETE_MANY_SUCCESS,
  EDUCATIONLEVELS_DELETE_MANY_ERROR
} from '../actions';
import { NotificationManager } from '../../components/common/react-notifications';
import  notificationMessages  from '../../lang/locales/notificationMsg'

const INIT_STATE = {
	education_levels: [],
  count: 0,
  education_level: null,
  error: '',
};

export default (state = INIT_STATE, action) => {
  const messages = notificationMessages()
	switch (action.type) {
		case EDUCATIONLEVEL_GET_LIST:
      return { ...state, loading: true, error: '', 
      count: 0
    };
    case EDUCATIONLEVEL_GET_LIST_SUCCESS:
      return { ...state, loading: false, 
        education_levels: action.payload.education_levels || [],
        count: action.payload.count || 0
      };
		case EDUCATIONLEVEL_GET_LIST_ERROR:
      return { ...state, loading: false, error: action.payload.message };






      // GET ONE
    case EDUCATIONLEVEL_GET_ONE:
      return { ...state, loading: true, error: ''};
    case EDUCATIONLEVEL_GET_ONE_SUCCESS:
      return { ...state, loading: false, error: '',
        education_level: action.payload.education_level
      };
    case EDUCATIONLEVEL_GET_ONE_ERROR:
      return { ...state, loading: false, error: action.payload.message };









      // ADD ONE
    case EDUCATIONLEVEL_ADD_ONE:
      return { ...state, loading: true, error: ''};
    case EDUCATIONLEVEL_ADD_ONE_SUCCESS:    
      NotificationManager.success(messages["Added"], messages["Success"],3000, null, null, '');
      return { ...state, loading: false, 
        education_levels: [action.payload.education_level, ...state.education_levels]
      };
    case EDUCATIONLEVEL_ADD_ONE_ERROR:
      NotificationManager.warning(action.payload.message ||  messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };





    
      // EDIT ONE
    case EDUCATIONLEVEL_EDIT_ONE:
      return { ...state, loading: true, error: ''};
    case EDUCATIONLEVEL_EDIT_ONE_SUCCESS:    
      NotificationManager.success(messages["Edited"], messages["Success"], 3000, null, null, '');
      return { ...state, loading: false, error: '',
      education_level: action.payload.education_level
      };
    case EDUCATIONLEVEL_EDIT_ONE_ERROR:
      NotificationManager.warning(action.payload.message ||  messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };

    




      // DELETE ONE
    case EDUCATIONLEVEL_DELETE_ONE:
      return { ...state, loading: true, error: ''};
    case EDUCATIONLEVEL_DELETE_ONE_SUCCESS:  
      const education_levels = state.education_levels 
      if(action.payload.index!=null && action.payload.index!=undefined)
      education_levels.splice(action.payload.index, 1)

      return { ...state, loading: false, error: '',
        education_levels
      };
    case EDUCATIONLEVEL_DELETE_ONE_ERROR:
      NotificationManager.warning(action.payload.message ||  messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };




      // DELETE MANY
        case EDUCATIONLEVELS_DELETE_MANY:
          return { ...state, loading: true, error: '' };
        case EDUCATIONLEVELS_DELETE_MANY_SUCCESS:
          const education_levels_list = state.education_levels
         
    
          if (action.payload.data.ids.length > 0) {
       
            for (let i = 0; i < action.payload.data.ids.length; i++) {
    
              let index_education_level = -1
              for (let j = 0; j < education_levels_list.length; j++) {
                if (education_levels_list[j]._id === action.payload.data.ids[i]) {
                  index_education_level = j; break;
                }
              }
              if (index_education_level > -1) education_levels_list.splice(index_education_level, 1)
            }
          }
          return {
            ...state, loading: false, error: '',
            education_levels: education_levels_list
          };
        case EDUCATIONLEVELS_DELETE_MANY_ERROR:
          NotificationManager.warning(action.payload.message || messages["Unkown Error"], messages["Error"], 3000, null, null, '');
          return { ...state, loading: false, error: action.payload.message };
    
		default: return { ...state };
	}
}
