import {
    CONTACT_MSG_GET_LIST,
    CONTACT_MSG_GET_LIST_SUCCESS,
    CONTACT_MSG_GET_LIST_ERROR,

    CONTACT_MSG_GET_LIST_BY_FILTER,
    CONTACT_MSG_GET_LIST_BY_FILTER_SUCCESS,
    CONTACT_MSG_GET_LIST_BY_FILTER_ERROR,
  
    CONTACT_MSG_GET_ONE,
    CONTACT_MSG_GET_ONE_SUCCESS,
    CONTACT_MSG_GET_ONE_ERROR,
    
    CONTACT_MSG_ADD_ONE,
    CONTACT_MSG_ADD_ONE_SUCCESS,
    CONTACT_MSG_ADD_ONE_ERROR,
  
    CONTACT_MSG_EDIT_ONE,
    CONTACT_MSG_EDIT_ONE_SUCCESS,
    CONTACT_MSG_EDIT_ONE_ERROR,
  
    CONTACT_MSG_DELETE_ONE,
    CONTACT_MSG_DELETE_ONE_SUCCESS,
    CONTACT_MSG_DELETE_ONE_ERROR,

    CONTACT_MSGS_DELETE_MANY,
    CONTACT_MSGS_DELETE_MANY_SUCCESS,
    CONTACT_MSGS_DELETE_MANY_ERROR
  } from '../actions';
  
  //GET LIST
  export const getContactMsgList = (payload) => ({
    type: CONTACT_MSG_GET_LIST,
    payload
  });
  export const getContactMsgListSuccess = (payload) => ({
    type: CONTACT_MSG_GET_LIST_SUCCESS,
    payload
  });
  export const getContactMsgListError = (payload) => ({
    type: CONTACT_MSG_GET_LIST_ERROR,
    payload
  });
  

   //GET LIST BY FILTER
   export const getContactMsgByFilter = (payload) => ({
    type: CONTACT_MSG_GET_LIST_BY_FILTER,
    payload
  });
  export const getContactMsgByFilterSuccess = (payload) => ({
    type: CONTACT_MSG_GET_LIST_BY_FILTER_SUCCESS,
    payload
  });
  export const getContactMsgByFilterError = (payload) => ({
    type: CONTACT_MSG_GET_LIST_BY_FILTER_ERROR,
    payload
  });


  // GET One
  export const getOneContactMsg = (payload) => ({
    type: CONTACT_MSG_GET_ONE,
    payload
  });
  export const getOneContactMsgSuccess = (payload) => ({
    type: CONTACT_MSG_GET_ONE_SUCCESS,
    payload
  });
  export const getOneContactMsgError = (payload) => ({
    type: CONTACT_MSG_GET_ONE_ERROR,
    payload
  });
  

  // ADD ONE
  export const addContactMsg = (payload) => ({
    type: CONTACT_MSG_ADD_ONE,
    payload
  });
  export const addContactMsgSuccess = (payload) => ({
    type: CONTACT_MSG_ADD_ONE_SUCCESS,
    payload
  });
  export const addContactMsgError = (payload) => ({
    type: CONTACT_MSG_ADD_ONE_ERROR,
    payload
  });
  
  // EDIT ONE
  export const editOneContactMsg = (payload) => ({
    type: CONTACT_MSG_EDIT_ONE,
    payload
  });
  export const editOneContactMsgSuccess = (payload) => ({
    type: CONTACT_MSG_EDIT_ONE_SUCCESS,
    payload
  });
  export const editOneContactMsgError = (payload) => ({
    type: CONTACT_MSG_EDIT_ONE_ERROR,
    payload
  });
  
  // DELETE ONE
  export const deleteOneContactMsg= (payload) => ({
    type: CONTACT_MSG_DELETE_ONE,
    payload
  });
  export const deleteOneContactMsgSuccess = (payload) => ({
    type: CONTACT_MSG_DELETE_ONE_SUCCESS,
    payload
  });
  export const deleteOneContactMsgError = (payload) => ({
    type: CONTACT_MSG_DELETE_ONE_ERROR,
    payload
  });

// DELETE MANY
export const deleteManyContactMsgs = (payload) => ({
  type:   CONTACT_MSGS_DELETE_MANY,
  payload
});
export const deleteManyContactMsgsSuccess = (payload) => ({
  type: CONTACT_MSGS_DELETE_MANY_SUCCESS,
  payload
});
export const deleteManyContactMsgsError = (payload) => ({
  type: CONTACT_MSGS_DELETE_MANY_ERROR,
  payload
});