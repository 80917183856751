import { all, call, fork, put, takeEvery } from "redux-saga/effects";
// import { getDateWithFormat } from "../../helpers/Utils";
import { api } from "../../constants/defaultValues";
import axios from "axios";

import { PERIODTYPE_GET_LIST, PERIODTYPE_ADD_ONE, PERIODTYPE_GET_ONE,PERIODTYPE_GET_ACTUEL, PERIODTYPE_EDIT_ONE, PERIODTYPE_DELETE_ONE,PERIODTYPES_DELETE_MANY
} from "../actions";

import {
  getPeriodTypesListSuccess,
  getPeriodTypesListError,

  getOnePeriodTypeSuccess,
  getOnePeriodTypeError,
  
  getPeriodTypeActuelSuccess,
  getPeriodTypeActuelError,

  addPeriodTypeSuccess,
  addPeriodTypeError,

  editPeriodTypeSuccess,
  editPeriodTypeError,
  deleteOnePeriodTypeSuccess,
  deleteOnePeriodTypeError,

  deleteManyPeriodTypesSuccess,
  deleteManyPeriodTypesError
} from "./actions";


  //GET LIST
const getPeriodTypesListRequest = async (payload) => {
  return await axios.get(api.period_types+`/list/${payload.inst_id}/${payload.offset}/${payload.limit}`, { headers: {Authorization: payload.token} })
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* getPeriodTypesList({payload}) {
  try {
    const ret = yield call(getPeriodTypesListRequest, payload);
    
    if (ret && ret.status==200)
    yield put(getPeriodTypesListSuccess({period_types: ret.data.period_types || [], count: ret.data.count || 0}));
    
    else yield put(getPeriodTypesListError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
    yield put(getPeriodTypesListError({error, message: (error) ? error.message : ''}));
  }
}



//GET ONE
const getOnePeriodTypesRequest = async (payload) => {
  return await axios.get(api.period_types+`/get-one/${payload._id}`, { headers: {Authorization: payload.token} })
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* getOnePeriodType({payload}) {  
  try {
    const ret = yield call(getOnePeriodTypesRequest, payload);

    if (ret && ret.status==200)
    yield put(getOnePeriodTypeSuccess({period_type: ret.data.period_type}));
    
    else yield put(getOnePeriodTypeError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
    yield put(getOnePeriodTypeError({error, message: (error) ? error.message : ''}));
  }
}

//GET ACTUEL
const getPeriodTypeActuelRequest = async (payload) => {
  
  return await axios.get(api.period_types+`/${payload.inst_id}/actual`, { headers: {Authorization: payload.token} })
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* getPeriodTypeActuel({payload}) {  
  try {
    const ret = yield call(getPeriodTypeActuelRequest, payload);
  
    if (ret && ret.status==200)

    {yield put(getPeriodTypeActuelSuccess({period_type_actuel: ret.data.period_type ,actual_period:ret.data.actual_period }));
 
  }
    else yield put(getPeriodTypeActuelError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
 
    yield put(getPeriodTypeActuelError({error, message: (error) ? error.message : ''}));
  }
}




  //ADD ONE
const addPeriodTypeRequest = async (payload) => {
  return await axios.post(api.period_types, payload.data, { headers: {Authorization: payload.token} })
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* addPeriodType({payload}) {  
  try {
    const ret = yield call(addPeriodTypeRequest, payload);
    
    if (ret && ret.status==201){
      const { history } = payload;

      if(history) history.push('/app/my-institutions/period-types')
      yield put(addPeriodTypeSuccess({period_type: ret.data.period_type}));
    }
    else yield put(addPeriodTypeError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
    console.log('saga err', error);
    yield put(addPeriodTypeError({error, message: (error) ? error.message : ''}));
  }
}





  //EDIT ONE
const editOnePeriodTypeRequest = async (payload) => {
  return await axios.patch(`${api.period_types}/edit-one/${payload._id}`, payload.data, { headers: {Authorization: payload.token} })
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* editOnePeriodType({payload}) {  
  try {
    const ret = yield call(editOnePeriodTypeRequest, payload);
    
    if (ret && ret.status==200){
      const { history } = payload;

      if(history) history.push('/app/my-institutions/period-types')
      yield put(editPeriodTypeSuccess({period_type: ret.data.period_type}));
    }
    else yield put(editPeriodTypeError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
    console.log('saga err', error);
    yield put(editPeriodTypeError({error, message: (error) ? error.message : ''}));
  }
}


  // DELETE ONE
const deleteOnePeriodTypeRequest = async (payload) => {
  return await axios.delete(`${api.period_types}/delete-one/${payload._id}`, { headers: {Authorization: payload.token} })
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* deleteOnePeriodType({payload}) {  
  try {
    const ret = yield call(deleteOnePeriodTypeRequest, payload);
    
    if (ret && ret.status==202){
      const { history, index } = payload;

      if(history) history.push('/app/my-institutions/period-types')
      yield put(deleteOnePeriodTypeSuccess({index}));
    }
    else yield put(deleteOnePeriodTypeError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
    console.log('saga err', error);
    yield put(deleteOnePeriodTypeError({error, message: (error) ? error.message : ''}));
  }
}

//DELETE MANY

const deleteManyPeriodTypesRequest = async (payload) => {
  return await axios.delete(`${api.period_types}/delete-many`, { headers: { Authorization: payload.token }, data: payload.data })
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* deleteManyPeriodTypes({ payload }) {
  try {
    const ret = yield call(deleteManyPeriodTypesRequest, payload);

    if (ret && ret.status == 202) {
      const { history, data } = payload;

      if (history) history.push('/app/my-institutions/period-types')
      yield put(deleteManyPeriodTypesSuccess({ data }));
    }
    else yield put(deleteManyPeriodTypesError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    console.log('saga err', error);
    yield put(deleteManyPeriodTypesError({ error, message: (error) ? error.message : '' }));
  }
}
//////// WATCHERS
export function* watchGetList() {
  yield takeEvery(PERIODTYPE_GET_LIST, getPeriodTypesList);
}

export function* watchGetOne() {
  yield takeEvery(PERIODTYPE_GET_ONE, getOnePeriodType);
}

export function* watchGetActuel() {
  yield takeEvery(PERIODTYPE_GET_ACTUEL, getPeriodTypeActuel);
}

export function* watchEditOne() {
  yield takeEvery(PERIODTYPE_EDIT_ONE, editOnePeriodType);
}

export function* watchAddOne() {
  yield takeEvery(PERIODTYPE_ADD_ONE, addPeriodType);
}

export function* watchDeleteOne() {
  yield takeEvery(PERIODTYPE_DELETE_ONE, deleteOnePeriodType);
}
export function* watchDeleteMany() {
  yield takeEvery(PERIODTYPES_DELETE_MANY,deleteManyPeriodTypes);
}
export default function* rootSaga() {
  yield all([
    fork(watchGetList),
    fork(watchGetOne),
    fork(watchGetActuel),
    fork(watchEditOne),
    fork(watchAddOne),
    fork(watchDeleteOne),
    fork(watchDeleteMany)

  ]);
}
