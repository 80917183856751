import {
    SANCTION_GET_LIST,
    SANCTION_GET_LIST_SUCCESS,
    SANCTION_GET_LIST_ERROR,
    
    SANCTION_GET_ONE,
    SANCTION_GET_ONE_SUCCESS,
    SANCTION_GET_ONE_ERROR,
    
    SANCTIONS_GET_BY_FILTER,
    SANCTIONS_GET_BY_FILTER_SUCCESS,
    SANCTIONS_GET_BY_FILTER_ERROR,
    
    SANCTION_ADD_ONE,
    SANCTION_ADD_ONE_SUCCESS,
    SANCTION_ADD_ONE_ERROR,

    SANCTION_EDIT_ONE,
    SANCTION_EDIT_ONE_SUCCESS,
    SANCTION_EDIT_ONE_ERROR,
 
    SANCTION_DELETE_ONE,
    SANCTION_DELETE_ONE_SUCCESS,
    SANCTION_DELETE_ONE_ERROR,



} from '../actions';

// GET LIST
export const getSanctionsList = (payload) =>({
    type:SANCTION_GET_LIST,
    payload 
});

export const getSanctionsListSuccess = (payload) =>({
    type:SANCTION_GET_LIST_SUCCESS,
    payload 
});

export const getSanctionsListError = (payload) =>({
    type:SANCTION_GET_LIST_ERROR,
    payload 
});


//GET ONE

export const getOneSanction = (payload) =>({
    type: SANCTION_GET_ONE,
    payload
});
export const getOneSanctionSuccess = (payload) =>({
    type: SANCTION_GET_ONE_SUCCESS,
    payload
});
export const getOneSanctionError = (payload) =>({
    type: SANCTION_GET_ONE_ERROR,
    payload
});


// GET SANCTIONS BY FILTER

export const getSanctionsByFilter = (payload) => ({
    type: SANCTIONS_GET_BY_FILTER,
    payload
  });
export const getSanctionsByFilterSuccess = (payload) => ({
    type: SANCTIONS_GET_BY_FILTER_SUCCESS,
    payload
  });
export const getSanctionsByFilterError = (payload) => ({
    type: SANCTIONS_GET_BY_FILTER_ERROR,
    payload
  });
  
  

//ADD ONE
export const addSanction = (payload) =>({
    type: SANCTION_ADD_ONE,
    payload
});

export const addSanctionSuccess = (payload) =>({
    type: SANCTION_ADD_ONE_SUCCESS,
    payload
});
export const addSanctionError = (payload) =>({
    type: SANCTION_ADD_ONE_ERROR,
    payload
});


//EDIT ONE
export const editOneSanction = (payload) =>({
    type: SANCTION_EDIT_ONE,
    payload
});

export const editOneSanctionSuccess = (payload) =>({
    type: SANCTION_EDIT_ONE_SUCCESS,
    payload
});
export const editOneSanctionError = (payload) =>({
    type: SANCTION_EDIT_ONE_ERROR,
    payload
});


  // DELETE ONE
export const deleteOneSanction = (payload) => ({
    type: SANCTION_DELETE_ONE,
    payload
  });
export const deleteOneSanctionSuccess = (payload) => ({
    type: SANCTION_DELETE_ONE_SUCCESS,
    payload
  });
export const deleteOneSanctionError = (payload) => ({
    type: SANCTION_DELETE_ONE_ERROR,
    payload
  });